import api from '../lib/api';

export default {

    animal_category: () => {
        return new Promise((resolve, reject) => {
            api()
                .get('category/animal-category')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`get text code failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    countCategory: () => {
        return new Promise((resolve, reject) => {
            api()
                .get('category/count-animals')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Count Animal failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetAnimalBreedCategory: (params) => {
        return new Promise((resolve, reject) => {
            api()
                .get('category/animal-breed-category', { params })
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Request failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    RequestNewBreed: () => {
        return new Promise((resolve, reject) => {
            api()
                .get('category/new-breed-request')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Request failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    ApprovedBreed: (Id, value) => {
        return new Promise((resolve, reject) => {
            api()
                .put(`category/new-breed-request/${Id}`, value)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Request failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    AddNewBreedRequest: (data) => {
        return new Promise((resolve, reject) => {
            api()
                .post(`category/new-breed-request`, data)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Request failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    AddAnimalCategory: function(data = {}) {
        return new Promise((resolve, reject) => {
            api()
                .post('category/animal-category', data)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Live Stock Fail with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    UpdateAnimalCategory: function(value, Id) {
        return new Promise((resolve, reject) => {
            api()
                .put(`category/animal-category/edit/${Id}`, value)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Live Stock Fail with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    AddBreedCategory: function(data = {}) {
        return new Promise((resolve, reject) => {
            api()
                .post('category/animal-breed-category', data)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Live Stock Fail with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    UpdateBreedCategory: function(value, Id) {
        return new Promise((resolve, reject) => {
            api()
                .put(`category/animal-breed-category/edit/${Id}`, value)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Live Stock Fail with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },
    
}