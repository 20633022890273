import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Input, notification, Spin } from 'antd';
import { connect } from 'react-redux';

// Farm Project Import Services
import GroupService from '../../services/Group-Service';
// Farm Project Import Services

class GroupModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            saving: false,
            Model: false,
            isEdit: false,
            ModelTitle: null,
            Role: null,
            selectedAnimals: null
        };
    }

    componentDidMount() {
    }

    OpenModel = (data) => {
        this.setState({ModelTitle: 'Create New Group', Model: true, selectedAnimals: data});            
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    ResponseSuccessfull(message){
		notification.success({ message: 'Farm', description: message});
        this.setState({ loading: false }, this.ClosedModel);
        this.props.form.resetFields();
        if (this.props.onSuccess) this.props.onSuccess();
	}

    ErrorAlert(error) {
        notification.error({ message: 'Animal Group', description: 'Service Unavailable'});
    }


    onSubmit = (e) => {
        if (e) e.preventDefault()
        const {validateFields} = this.props.form; 

        validateFields((err, values) => {
            if (err) return;

            if(this.state.selectedAnimals.length === 0) {
                return notification.error({message: 'Animal Group', description: 'Animal selection is required'});
            }

            let body = {
                name: values.group_name,
                description: values.description,
                animal: this.state.selectedAnimals
            };
            this.setState({ loading: true });
            GroupService.AddGroup(body)
            .then(resp => {
                if(resp.success) {
                   this.ResponseSuccessfull(resp.message);
                }
                else {
                    this.ErrorAlert();
                }
            })
            .catch( error => {
                this.ErrorAlert();
            });
            this.props.form.resetFields();
        });
    }

    render() {

        const { loading, Model } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '600px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>{this.state.ModelTitle}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label='Group Name'>
                                    {getFieldDecorator('group_name', {
                                        rules: [{ required: true, message: 'Group Name required' }]
                                    })(
                                        <Input />
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='Description'>
                                    {getFieldDecorator('description', {
                                        rules: [{ required: true, message: 'Description is required' }]
                                    })(
                                        <Input />
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>    
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Save</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _GroupModal = Form.create()(GroupModal);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_GroupModal);
