import React, { Component } from 'react';
import { Row, Col, Card, Form, Descriptions, Timeline, Empty } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';
import {applyViewAs } from '../../utils';

// Farm Project Import Services
import liveStock from '../../services/liveStock';
import moment from 'moment-timezone';
// Farm Project Import Services

import { Header } from '../../components';

class LiveStockHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            LiveStockHistoryList: []
        };
    }

    

    componentDidMount() {
        let Id = this.props.match.params.Id;
        this.GetLiveStockHistoryById(Id);
    }



    GetLiveStockHistoryById(Id) {
        liveStock.GetLiveStockHistoryById(Id).then(resp => {
            this.setState({LiveStockHistoryList: resp.docs});
        })
        .catch(error => { console.log(error); })
        .finally(() => {
            this.setState({ loading: false })
        });
    }

    CheckObject = (object) => {
        if(object === undefined ) return false;
        return Object.values(object).every(x => x === null || x === '');
    }

    DateFormat(date){
        if(date && typeof date !== undefined && date !== "" ){
            return moment(date).format('ll');
        }
    }

     checkNested(obj) {
        
      }


    render() {

        const {loading, LiveStockHistoryList} = this.state;

        return (
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Header icon="edit" title="Live Stock History"/>
                </Col>
                <Col span={24}>
                    <Card bordered={false} loading={loading} className='full-height'>
                        {LiveStockHistoryList.length < 1 ? <Empty description="No History Found" imageStyle={ {height: 300} }/> : ''}
                        <Timeline>
                            {
                                LiveStockHistoryList.map((data, key) => {
                                    return (
                                        <Timeline.Item>
                                <div className='timeline-box'>
                                    <Descriptions title="Animal Info">
                                        {data.created && <Descriptions.Item label="History Date">{this.DateFormat(data.created)}</Descriptions.Item>}
                                        {data.categoryName && <Descriptions.Item label="Category Name">{data.categoryName}</Descriptions.Item>}
                                        {data.breedName && <Descriptions.Item label="Breed Name">{data.breedName}</Descriptions.Item>}
                                        {data.dob && <Descriptions.Item label="Date of Birth">{this.DateFormat(data.dob)}</Descriptions.Item>}
                                        {data.sex && <Descriptions.Item label="Sex">{data.sex}</Descriptions.Item>}
                                        {data.dateOfWeaning && <Descriptions.Item label="Date Of Weaning">{data.dateOfWeaning}</Descriptions.Item>}
                                        {data.currentLocation && <Descriptions.Item label="Current Location">{data.currentLocation}</Descriptions.Item>}
                                        {data.origin && <Descriptions.Item label="Origin">{data.origin}</Descriptions.Item>}
                                        {data.status && <Descriptions.Item label="Status">{data.status}</Descriptions.Item>}
                                        {data.color && <Descriptions.Item label="Color">{data.color}</Descriptions.Item>}
                                        {data.breedingStock && <Descriptions.Item label="Breeding Stock">{data.breedingStock}</Descriptions.Item>}
                                        {data.lenght && <Descriptions.Item label="Lenght">{data.lenght}</Descriptions.Item>}
                                        {data.height && <Descriptions.Item label="Height">{data.height}</Descriptions.Item>}
                                        {data.temprament && <Descriptions.Item label="Temprament">{data.temprament}</Descriptions.Item>}
                                        {data.bloodLine && <Descriptions.Item label="Blood Line">{data.bloodLine}</Descriptions.Item>}
                                        {data.fertilityStatus && <Descriptions.Item label="Fertility Status">{data.fertilityStatus}</Descriptions.Item>}
                                    </Descriptions>

                                    { data.weight &&                                 
                                    <Descriptions title="Weight" className='timeline-box'>
                                        { data.weight.initialWeightkg &&  <Descriptions.Item label="Initial Weight KG">{data.weight.initialWeightkg}</Descriptions.Item> }
                                        { data.weight.initialWeightDate &&  <Descriptions.Item label="Initial Weight Date">{this.DateFormat(data.weight.initialWeightDate)}</Descriptions.Item> }
                                        { data.weight.currentWeightkg &&  <Descriptions.Item label="Current Weight KG">{data.weight.currentWeightkg}</Descriptions.Item> }
                                        { data.weight.currentWeightDate &&  <Descriptions.Item label="Current Weight Date">{ this.DateFormat(data.weight.currentWeightDate)}</Descriptions.Item> }
                                        { data.weight.conditionScore &&  <Descriptions.Item label="Condition Score">{data.weight.conditionScore}</Descriptions.Item> }
                                        { data.weight.conditionScoreDate &&  <Descriptions.Item label="Condition Score Date">{this.DateFormat(data.conditionScoreDate)}</Descriptions.Item> }
                                    </Descriptions>}
        
                                    { data.identification &&  
                                    <Descriptions title="identification" className='timeline-box'>
                                        { data.identification.rfID && <Descriptions.Item label="Reference ID">{data.identification.rfID}</Descriptions.Item> }
                                        { data.identification.sireID &&  <Descriptions.Item label="sireID">{data.identification.sireID}</Descriptions.Item> }
                                        { data.identification.damID &&  <Descriptions.Item label="Dam ID">{data.identification.damID}</Descriptions.Item> }
                                        { data.identification.name &&  <Descriptions.Item label="Name">{ data.identification.name}</Descriptions.Item> }
                                        { data.identification.eID &&  <Descriptions.Item label="EID">{data.identification.eID}</Descriptions.Item> }
                                        { data.identification.oldID &&  <Descriptions.Item label="Old ID">{data.identification.oldID}</Descriptions.Item> }
                                        { data.identification.visualID &&  <Descriptions.Item label="Visual ID">{data.identification.visualID}</Descriptions.Item> }
                                    </Descriptions>}

                                    { data.production ?  
                                    <Descriptions title="Production" className='timeline-box'>
                                        { data.production.lactationNumber &&  <Descriptions.Item label="lactation Number">{data.production.lactationNumber}</Descriptions.Item> }
                                        { data.production.currentLactationStage &&  <Descriptions.Item label="Current Lactation Stage">{data.production.currentLactationStage}</Descriptions.Item> }
                                        { data.production.lactationCycle &&  <Descriptions.Item label="Animal Lactation cycle">{data.production.lactationCycle}</Descriptions.Item> }
                                        { data.production.totalMilk &&  <Descriptions.Item label="Total Milk">{data.production.totalMilk}</Descriptions.Item> }
                                        { data.production.milkingDate &&  <Descriptions.Item label="Milking Date">{this.DateFormat(data.production.milkingDate)}</Descriptions.Item> }
                                        { data.production.milkPM &&  <Descriptions.Item label="Milking PM">{data.production.milkPM}</Descriptions.Item> }
                                        { data.production.milkAM &&  <Descriptions.Item label="Milking AM">{data.production.milkAM}</Descriptions.Item> }
                                        { data.production.fat &&  <Descriptions.Item label="FAT">{data.production.fat}</Descriptions.Item> }
                                        { data.production.protien &&  <Descriptions.Item label="Protien">{data.production.protien}</Descriptions.Item> }
                                        { data.production.milkSolids &&  <Descriptions.Item label="Milk Solids">{data.production.milkSolids}</Descriptions.Item> }
                                        { data.production.carcassWeight &&  <Descriptions.Item label="Carcass Weight">{data.production.carcassWeight}</Descriptions.Item> }
                                        { data.production.teatScore &&  <Descriptions.Item label="Teat Score">{data.production.teatScore}</Descriptions.Item> }
                                    </Descriptions>
                                    :
                                    ''
                                    }

                                    {data.reproduction &&
                                    <Descriptions title="Reproduction" className='timeline-box'>
                                        { data.reproduction.breto &&  <Descriptions.Item label="Bred to">{data.reproduction.breto}</Descriptions.Item> }
                                        { data.reproduction.matingAbility &&  <Descriptions.Item label="Mating ability">{data.reproduction.matingAbility}</Descriptions.Item> }
                                        { data.reproduction.Fertility &&  <Descriptions.Item label="Fertility">{data.reproduction.Fertility}</Descriptions.Item> }
                                        { data.reproduction.pregnancyNumber &&  <Descriptions.Item label="Pregnancy Number">{data.reproduction.pregnancyNumber}</Descriptions.Item> }
                                        { data.reproduction.lastKidingOnDate &&  <Descriptions.Item label="Last Kiding Date">{this.DateFormat(data.reproduction.lastKidingOnDate)}</Descriptions.Item> }
                                        { data.reproduction.currentConceptionDate &&  <Descriptions.Item label="Current Conception Date">{this.DateFormat(data.reproduction.currentConceptionDate)}</Descriptions.Item> }
                                        { data.reproduction.kiddingDate &&  <Descriptions.Item label="Kidding Date">{this.DateFormat(data.reproduction.kiddingDate)}</Descriptions.Item> }
                                        { data.reproduction.ageOfJoining &&  <Descriptions.Item label="Age of Joining">{this.DateFormat(data.reproduction.ageOfJoining)}</Descriptions.Item> }
                                        { data.reproduction.productionRecord &&  <Descriptions.Item label="Production record">{data.reproduction.productionRecord}</Descriptions.Item> }
                                        { data.reproduction.totalKidsScanned &&  <Descriptions.Item label="Total kids Scanned">{data.reproduction.totalKidsScanned}</Descriptions.Item> }
                                        { data.reproduction.scanDry &&  <Descriptions.Item label="Scan dry">{data.reproduction.scanDry}</Descriptions.Item> }
                                        { data.reproduction.lastStillBirth &&  <Descriptions.Item label="Last still birth/abortion">{data.reproduction.lastStillBirth}</Descriptions.Item> }
                                        { data.reproduction.numberOfKidsBornAlive &&  <Descriptions.Item label="Number of kids Born Alive">{data.reproduction.numberOfKidsBornAlive}</Descriptions.Item> }
                                        { data.reproduction.numberOfKidsWeaned &&  <Descriptions.Item label="Number of Kids weaned">{data.reproduction.numberOfKidsWeaned}</Descriptions.Item> }
                                        { data.reproduction.fosteringKid &&  <Descriptions.Item label="Fostering kid(s)">{data.reproduction.fosteringKid}</Descriptions.Item> }
                                        { data.reproduction.maternalAbility &&  <Descriptions.Item label="Maternal Ability">{data.reproduction.maternalAbility}</Descriptions.Item> }
                                        { data.reproduction.breeding &&  <Descriptions.Item label="Breeding">{data.reproduction.breeding}</Descriptions.Item> }
                                    </Descriptions>}

                                    { data.notes &&
                                    <Descriptions title="Notes" className='timeline-box'>
                                        { data.notes.date && <Descriptions.Item label="Date">{this.DateFormat(data.notes.date)}</Descriptions.Item> }
                                        { data.notes.details &&  <Descriptions.Item label="Details">{data.notes.details}</Descriptions.Item> }
                                    </Descriptions>}

                                    { data.treatment &&
                                    <Descriptions title="Treatment" className='timeline-box'>
                                        { data.treatment.treatmentDate &&  <Descriptions.Item label="Treatment Date">{this.DateFormat(data.treatment.treatmentDate)}</Descriptions.Item> }
                                        { data.treatment.treatmentStatus &&  <Descriptions.Item label="Treatment Status">{data.treatment.treatmentStatus}</Descriptions.Item> }
                                        { data.treatment.condition &&  <Descriptions.Item label="Condition">{data.treatment.condition}</Descriptions.Item> }
                                        { data.treatment.affectingArea &&  <Descriptions.Item label="Affecting Area">{data.treatment.affectingArea}</Descriptions.Item> }
                                        { data.treatment.VaccineTreatment &&  <Descriptions.Item label="Vaccine Treatment">{data.treatment.VaccineTreatment}</Descriptions.Item> }
                                        { data.treatment.doesgiven &&  <Descriptions.Item label="Does Given">{data.treatment.doesgiven}</Descriptions.Item> }
                                        { data.treatment.treatmentDays &&  <Descriptions.Item label="Treatment Days">{data.treatment.treatmentDays}</Descriptions.Item> }
                                        { data.treatment.milkWithholding &&  <Descriptions.Item label="Milk Withholding">{data.treatment.milkWithholding}</Descriptions.Item> }
                                        { data.treatment.meatWithHolding &&  <Descriptions.Item label="Meat Withholding">{data.treatment.meatWithHolding}</Descriptions.Item> }
                                        { data.treatment.dateSeperated &&  <Descriptions.Item label="Date Seperated">{this.DateFormat(data.treatment.dateSeperated)}</Descriptions.Item> }
                                        { data.treatment.returnVetDate &&  <Descriptions.Item label="Return Vet Date">{this.DateFormat(data.treatment.returnVetDate)}</Descriptions.Item> }
                                       
                                       
                                    </Descriptions>}

                                </div>
                                </Timeline.Item>
                                    );
                                })
                                
                            }
                        </Timeline>
                    </Card>
                </Col>
            </Row>
        );
    }

}


const _LiveStockHistory = Form.create()(LiveStockHistory);

export default connect(
    (state) => {
        return {
            auth: state.auth,
            viewingAs: applyViewAs(state.auth)
        }
    },
    actions
)(_LiveStockHistory);
