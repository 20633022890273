import React, { Component } from 'react';
import { Row, Col, Card, Table, Button } from 'antd';
import { Header, AnimalCategoryModal } from '../../components';
import moment from 'moment-timezone';
import categories from '../../services/categories';

class AnimalCategory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: false,
            SearchQuery: '',
            pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
            },
        };

        this.columns = [
            {
                title: 'Animal Category Name',
                key: 'animalCategoryName',
                dataIndex: 'animalCategoryName',
                width: 400,
            },
            {
                title: 'Created At',
                key: 'created_at',
                dataIndex: 'created_at',
                width: 150,
                render: (value) => moment(value).format('ll'),
            },
            {
                title: 'Edit',
                key: 'id',
                dataIndex: 'id',
                width: 100,
                render: (text, record) => <a href="" onClick={() => this.categoryModel.OpenModel(record)}>Edit</a>
            }
        ];
    }

    SearchHandler = (event) => {
        let query = event.target.value;
        this.setState({SearchQuery: query});
        if(event.key === 'Enter') {
            this.asyncBoot(1, {keyword: query});
        }

        if(query.length === 0) {
            this.asyncBoot();
        }
    }


    componentDidMount() {
        this.asyncBoot();
    }


    asyncBoot = async (page = 1, filters = {}) => {
        const params = {
            page: page,
            ...filters
        }
        this.setState({ loading: true });
        const response = await categories.animal_category(params);
        this.setState({
            data: response,
            pagination: {
                ...this.state.pagination,
                total: response.totalDocs,
                currentPage: response.page,
                perPage: 10
            },
            loading: false,
            lastFilters: filters,
        });
    }


    onPaginationChange = (page) => {
        this.asyncBoot(page, {keyword: this.state.SearchQuery});
    }

    onSuccess = () => {
        this.asyncBoot();
    }

    render() {

        const { data, loading, saving } = this.state;

        const tableProps = {
            size: 'middle',
            columns: this.columns,
            rowKey: 'id',
            dataSource: data,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: this.state.loading,
            // scroll: { x: 2000 },
        };

        return (
            <Row gutter={[24, 24]}>
                <AnimalCategoryModal wrappedComponentRef={c => this.categoryModel = c} onSuccess={this.onSuccess} />
                <Col span={24}>
                    <Header
                        icon="people"
                        title="Animal Category"
                        right={(
                            <Button disabled={loading || saving} type='primary' onClick={() => this.categoryModel.OpenModel(null)}>Add New</Button>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Card bordered={false}>
                        <Table {...tableProps} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default AnimalCategory