import React, { Component } from 'react';
import { Row, Col, Card, Button, Form, Input,notification, Select, Icon, Avatar } from 'antd';

import { connect } from 'react-redux';
import * as actions from '../../actions/auth';

import users from '../../services/users';
import { Header } from '../../components';

import icUser from '../../assets/images/ic-user.svg';

import { getBase64, getCountries, applyViewAs } from '../../utils';


const COUNTRIES = getCountries();
const { Option } = Select;


class Profile extends Component {

    state = {
        loading: true,
        saving: false,
        imageBase64: icUser,
        password_field: true,
    };

    componentDidMount() {
        this.getProfile();
    }

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm_password'], { force: true });
        }
        callback();
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    getProfile = () => {

        const { setFieldsValue } = this.props.form;

        users.GetProfile()
            .then(response => {
                setFieldsValue({
                    id: response.id,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    email: response.email,
                    title: response.title,
                    phone: response.phone,
                    country: response.country,
                    state: response.state,
                    postalCode: response.postalCode,
                    town: response.town,
                    isVerified: response.isVerified,
                });  
                
                if (response.picture !== null)
                    this.setState({ imageBase64: response.picture });

            })
            .catch(error => { console.log(error); })
            .finally(() => {
                this.setState({ loading: false })
            });

    }


    userFileChange = (input) => {
        if (input.files && input.files[0]) {
            const { setFieldsValue } = this.props.form;
            setFieldsValue({ picture: input.files[0] });
            getBase64(input.files[0]).then(imageBase64 => {
                this.setState({ imageBase64 });
            }).catch(() => { });
        }
    }


    save = () => {

        const { validateFields, getFieldDecorator, getFieldValue } = this.props.form;
        const password = getFieldValue('password');
     
        if(password === undefined) {
            getFieldDecorator('password', { initialValue: '' });
            getFieldDecorator('confirmPassword', { initialValue: '' });
        }        
        
        validateFields((err, values) => {
            if (err) return true;

            // Append to form data
            const formData = new FormData();
            for (let key in values) {
                formData.append(key, values[key]);
            }

            this.setState({ saving: true });

            users.UpdateProfile(formData)
                .then(response => {
                    notification.success({
                        message: 'Profile',
                        description: 'Profile updated successfully'
                    });
                
                    // update redux store
                    this.props.authUpdateProfile(response.data.data);
                })
                .catch(error => {
                    if(error.response.data.errors.email[0] === 'The email has already been taken.') {
                        return notification.error({
                            message: 'Email',
                            description: 'The email has already been taken.'
                        });
                    }
                    notification.error({
                        message: 'Service Unavailable',
                        description: 'Service Unavailable please try later'
                    });

                })
                .finally(() => {
                    this.setState({ saving: false });
                });

        });
    }

    EnablePassword() {
        this.setState({password_field: false});
    }


    render() {

        const {
            loading,
            saving,
            imageBase64,
        } = this.state;

        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { viewingAs } = this.props;
        //let roleName = auth.user.role.name;

        getFieldDecorator('id', { initialValue: null });
        getFieldDecorator('picture', { initialValue: null });

        const password = getFieldValue('password');

        const sizes = {xs: 24, sm: 12, md: 12, lg:12};

        return (
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Header
                        icon="edit"
                        title="Profile Information"
                        right={(
                            <Button disabled={loading || saving} type='primary' onClick={this.save}>SAVE</Button>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Card bordered={false} loading={loading}>
                            <Row>
                                <Col span={24} className='mb-20'>
                                    <div style={{ position: 'relative', width: '122px' }}>
                                        <input
                                            type='file'
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            ref={c => this.userFile = c}
                                            onChange={() => this.userFileChange(this.userFile)}
                                        />
                                        <Avatar size={122} src={imageBase64} icon='user' />
                                        <Button size='default' shape='circle' icon='camera' className='btnAvatar' onClick={() => this.userFile.click()} />
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                            <Col {...sizes}>
                                <Form.Item label='First Name'>
                                    {getFieldDecorator('firstName', {
                                        rules: [
                                            { required: true, message: 'Please input your first name!' }
                                        ]
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col {...sizes}>
                                <Form.Item label='Last Name'>
                                    {getFieldDecorator('lastName', {
                                        rules: [
                                            { required: true, message: 'Please input your last name!' }
                                        ]
                                    })(
                                        <Input/>
                                    )}
                                </Form.Item>
                            </Col>
                            </Row>
                           
                            <Row gutter={24}>
                            <Col {...sizes}>
                                <Form.Item label='Email'>
                                    {getFieldDecorator('email', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col {...sizes}>
                                <Form.Item label='Phone'>
                                    {getFieldDecorator('phone', {
                                        rules: [
                                            { required: true, message: 'Please input your phone!' }
                                        ]
                                    })(
                                        <Input/>
                                    )}
                                </Form.Item>
                            </Col>
                            </Row>

                            <Row gutter={24}>
                            <Col {...sizes}>
                                <Form.Item label='Postal Code'>
                                    {getFieldDecorator('postalCode', {
                                        rules: [
                                            { required: true, message: 'Please input your postal code!' }
                                        ]
                                    })(
                                        <Input/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col {...sizes}>
                                <Form.Item label='Country'>
                                    {getFieldDecorator('country', {
                                        rules: [
                                            { required: true, message: 'Please select your country!' }
                                        ]
                                    })(
                                        <Select showSearch className='width-100'>
                                            {COUNTRIES.map((country, key) => {
                                                return (<Option key={key} value={country}>{country}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            </Row>
                            <Row gutter={24}>
                            <Col {...sizes}>
                                <Form.Item label='State'>
                                    {getFieldDecorator('state', {
                                        rules: [
                                            { required: true, message: 'Please input your state!' }
                                        ]
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col {...sizes}>
                                <Form.Item label='Town'>
                                    {getFieldDecorator('town', {
                                        rules: [
                                            { required: true, message: 'Please input your town!' }
                                        ]
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            </Row>

                        <Row className={viewingAs === true ? 'display_none' : ''}  gutter={24}>
                            <Col {...sizes}>
                                <Form.Item label='Password'>
                                    {getFieldDecorator('password', {
                                        rules: [
                                            { required: false, message: 'Please input your Password' },
                                            {
                                                pattern: new RegExp("(?=.*[a-z])"),
                                                message: '1 lowercase letter, '
                                            },
                                            {
                                                pattern: new RegExp("(?=.*[A-Z])"),
                                                message: '1 uppercase letter, '
                                            },
                                            {
                                                pattern: new RegExp("(?=.*[0-9])"),
                                                message: '1 number, '
                                            },
                                            {
                                                pattern: new RegExp("(?=.{8,})"),
                                                message: '8 characters or longer'
                                            },
                                            { validator: this.validateToNextPassword },
                                        ],
                                    })(
                                        <Input.Password disabled={this.state.password_field}
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        />,
                                    )}
                                </Form.Item>
                                <p>If you want to change your password click <a onClick={()=> this.EnablePassword()}>here</a></p>     
                            </Col>
                            <Col {...sizes}>
                                <Form.Item label='Confirm Password'>
                                    {getFieldDecorator('confirmPassword', {
                                        rules: [
                                            { required: (password && password.length > 0), message: 'Please confirm your Password!' },
                                            { validator: this.compareToFirstPassword },
                                        ],
                                    })(
                                        <Input.Password disabled={this.state.password_field}
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            onBlur={this.handleConfirmBlur}
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    }

}


const FormProfile = Form.create()(Profile);

export default connect(
    (state) => {
        return {
            auth: state.auth,
            viewingAs: applyViewAs(state.auth)
        }
    },
    actions
)(FormProfile);
