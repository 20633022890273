import React, { Component } from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';
import { Layout } from 'antd';

import Header from './header';
import Sider from './sider';

import { getRoutes } from './routes';
import users from '../../services/users';
import Profile from '../profile';
import AnimalForm from '../animal-form';
import realEstate from '../dashboard/realEstate';
import liveStocksHistory from '../live-stocks-history';
import ChangePassword from '../change-password';
import AnimalMilking from '../animal-milking';
import SessionAnimals from '../session-animals';

class App extends Component {

	state = {
		routes: []
	};

	componentDidMount() {
		this.checkUserProfile();
		setInterval(() => {
			this.checkUserProfile();
		}, 9000);
	}

	checkUserProfile = async () => {
		users.GetProfile()
            .then(response => {
				this.props.authUpdateProfile(response);
				let routes = getRoutes(response.role, response.subscription);
				this.setState({route: []});
				this.setState({routes: routes});
            })
            .catch(error => { console.log(error); })
            .finally(() => {
                this.setState({ loading: false })
            });

	}

	render() {

		const { match: { path } } = this.props;

		const { auth } = this.props;
		if (!auth.authenticated) return (<Redirect to='/' />);

		const {routes} = this.state;
		let ROUTES = Array.from(routes)
		let firstPath = '';
		if (routes && routes[0]) {
			firstPath = routes[0].path;
		}

		return (
			<Layout style={{ minHeight: '100vh' }}>

				<Header />

				<Layout>

					<Sider />

					<Layout style={{ padding: '24px', backgroundColor: '#f3f6fe' }}>

						<Switch>

							{
								ROUTES.map((route, key) => {
									return (
										<Route key={key.toString()} {...route} path={`${path}${route.path}`} />
									)
								})
							}

							<Route exact={true} path={`${path}/profile`} component={Profile} />
							<Route exact={true} path={`${path}/change-password`} component={ChangePassword} />
							<Route exact={true} path={`${path}/animalform/:Id`} component={AnimalForm} />
							<Route exact={true} path={`${path}/live-stocks-history/:Id`} component={liveStocksHistory} />
							<Route exact={true} path={`${path}/user-dashboard`} component={realEstate} />
							<Route exact={true} path={`${path}/animal-milking/:Id`} component={AnimalMilking} />
							<Route exact={true} path={`${path}/session/animals/:Id`} component={SessionAnimals} />
							{/* No Match 404 */}
							<Route path="*">
								<Redirect to={`${path}${firstPath}`} />
							</Route>

						</Switch>

					</Layout>



				</Layout>

				<div style={{
					marginTop: "-21px",
					zIndex: "1",
					textAlign: "right",
					padding: "0 24px",
					fontWeight: "bold",
					color: "#aaa",
				}}>
				</div>

			</Layout>
		)


	}


}

export default connect(
	(state) => {
		return {
			auth: state.auth
		}
	},
	actions
)(App);
