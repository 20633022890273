import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Form, Icon, Input, Button, notification, Row, Col } from 'antd';
import './index.css';

import { forgotPassword } from '../../services/auth';
import logo from '../../assets/images/logo.png';

class SendPasswordReset extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fetching: false,
		};
	}

	handleSubmit = (e) => {

		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {

				this.setState({ fetching: true });

				forgotPassword(values)
					.then(response => {

						if (response.status && response.status !== 200) {
							notification.warning({
								message: 'Password Reset',
								description: response.message
							});
							return;
						}

						notification.success({
							message: 'Password Reset',
							description: 'We have mailed your password reset link'
						});

						this.props.form.resetFields();

					})
					.catch(error => {

						notification.error({
							message: 'Service Error',
							description: 'Service Unavailable - please try later'
						});



					})
					.finally(() => {
						this.setState({ fetching: false });
					});

			}
		});

	}

	render() {

		const { fetching } = this.state;

		const { getFieldDecorator } = this.props.form;

		const { auth, history } = this.props;
		if (auth.authenticated)
			return (<Redirect to='app/dashboard' />);

		return (
			<div
				className="loginContainer"
			>
				<div className="y">

					<Form onSubmit={this.handleSubmit} className="login-form z">

						<div className='text-center'>
							<img src={logo} alt="farm logo" height={120} />
						</div>

						<div className="intro text-center">
							<h1>Forgot Password</h1>
						</div>

						<Form.Item>
							{getFieldDecorator('email', {
								rules: [{ required: true, message: 'Please input your email address!' }],
							})(
								<Input
									prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
									placeholder="Email Address"
								/>,
							)}
						</Form.Item>

						<Form.Item style={{ marginBottom: 0 }}>
							<Button loading={fetching} type="primary" htmlType="submit" className="login-form-button">
								Send Password Reset Link
          					</Button>
						</Form.Item>
						<Row gutter={24}>
							<Col span={12}>
								<a onClick={() => { history.push(`/login`)}} className='link'>Login</a>
							</Col>
						</Row>
					</Form>



				</div>
			</div>
		);

	}

}

const _SendPasswordReset = Form.create()(SendPasswordReset);

export default connect(
	(state) => {
		return {
			auth: state.auth
		}
	}
)(_SendPasswordReset);
