import React, { Component } from 'react';
import { Row, Col, Card, Table, Button, Popconfirm, Input  } from 'antd';
import { Header, GroupModal } from '../../components';
import moment from 'moment-timezone';
import GroupService from '../../services/Group-Service';
import dashboard from '../../services/dashboard';
//const { Option } = Select;

class ManagementGroups extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            groupData: [],
            categoriesList: [],
            selectedRowKeys: [],
            selectedAnimals: [],
            selectedCategoriesID: null,
            loading: false,
            filterTable: null,
            SearchQuery: '',
            baseColumns: [],
            pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
            },
        };

        this.groupColumns = [
            {
                title: 'Group Name',
                key: 'name',
                dataIndex: 'name',
                width: 100,
            },
            {
                title: 'Description',
                key: 'description',
                dataIndex: 'description',
                width: 150,
            },
            {
                title: 'Number Of Animals',
                key: 'animal',
                dataIndex: 'animal',
                width: 100,
                render: (data) => data.length
            },
            {
                title: 'Create Date',
                key: 'created',
                dataIndex: 'created',
                width: 100,
                render: (value) => moment(value).format('ll'),
            },
            {
                title: 'Action',
                key: 'id',
                width: 100,
                render: (data) => {
                    return (
                        <>
                        <Popconfirm
                        title="Confirm Group Deleted ?"
                        onConfirm={ () => this.DeleteGroupConfirm(data)}
                        onCancel={this.DeleteGroupCancel}
                        okText="Confirm" cancelText="Cancel">
                        <a>Delete</a>
                        </Popconfirm>
                        </>
                    );
                }
            },
        ];
    }

    

    keywordSearchHandler = (event) => {
        let serchValue = event.target.value;
        const { data } = this.state;
        const filterTable = data.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(serchValue.toLowerCase())
          )
        );            
        this.setState({ filterTable });
    };

    componentDidMount() {
       this.asyncBoot();
    }

    asyncBoot = async () => {
        this.setState({ loading: true });
        let groupData = await GroupService.GetAllGroups();
        let Data = await dashboard.get_live_stocks({categoryName: 'All'});
        let AllFarmLocations = [];
        Data.map(animals => {
            if(AllFarmLocations.filter(data => data.value === animals.location_id).length === 0) {
                AllFarmLocations.push({text: animals.farm.locations.filter(data => data._id === animals.location_id)[0]['locationName'], value: animals.location_id})
            }
        })
        this.setState({data: Data, groupData: groupData.data, loading: false});
        this.GenerateTableColumns(AllFarmLocations);
    }

    GenerateTableColumns = async (AllFarmLocations) => {
        let Columns = [
            {
                title: 'Category',
                key: 'categoryName',
                dataIndex: 'categoryName',
                width: 120,
                fixed: 'left',
                sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
            },
            {
                title: 'Breed',
                key: 'breedName',
                dataIndex: 'breedName',
                width: 120,
                fixed: 'left',
                sorter: (a, b) => a.breedName.localeCompare(b.breedName),
            },
            {
                title: 'Farm',
                key: '_id',
                width: 200,
                render: (data) => {return (data.farm.name)},
                sorter: (a, b) => a.farm.name.localeCompare(b.farm.name),
            },
            {
                title: 'Location',
                key: 'location_id',
                width: 120,
                filters: AllFarmLocations,
                render: (data) => {
                    let locationId = data.location_id;
                    return data.farm.locations.filter(x => x._id === locationId)[0]['locationName'];
                },
                onFilter: (value, record) => record.location_id.indexOf(value) === 0,
                sorter: (a, b) => a.locations['locationName'].localeCompare(b.locations['locationName']),
            },
            {
                title: 'Movement Type',
                key: 'movementType',
                dataIndex: 'movementType',
                width: 180,
                filters: [
                    {text: 'Arrival', value: 'Arrival'},
                    {text: 'Pen', value: 'Pen'},
                    {text: 'Dispatch', value: 'Dispatch'},
                    {text: 'Hospital', value: 'Hospital'},
                    {text: 'Dead', value: 'Dead'},
                    {text: 'Off Farm Movement', value: 'Off Farm Movement'},
                ],
                onFilter: (value, record) => record.movementType.indexOf(value) === 0,
                sorter: (a, b) => a.movementType.localeCompare(b.movementType),
            },
            {
                title: 'Current Weight kg',
                key: 'Current Weight kg',
                render: (data) => {
                    return (data.weight.currentWeightkg);
                },
                width: 200,
                sorter: (a, b) => a.weight.currentWeightkg.localeCompare(b.weight.currentWeightkg),
                hidden: this.state.currentWeightkg,
            },
            {
                title: 'Condition Score',
                key: 'conditionScore',
                render: (data) => {
                    return (data.weight.conditionScore);
                },
                width: 200,
                sorter: (a, b) => a.weight.conditionScore.localeCompare(b.weight.conditionScore),
                hidden: this.state.conditionScore,
    
            },
            {
                title: 'Sire ID',
                key: 'sireID',
                render: (data) => {
                    return (data.identification.sireID);
                },
                width: 200,
                sorter: (a, b) => a.identification.sireID.localeCompare(b.identification.sireID),
                hidden: this.state.sireID,
    
            },
            {
                title: 'Dam ID',
                key: 'damID',
                render: (data) => {
                    return (data.identification.damID);
                },
                width: 200,
                sorter: (a, b) => a.identification.damID.localeCompare(b.identification.damID),
                hidden: this.state.damID,
    
            },
            {
                title: 'Current Lactation Stage',
                key: 'currentLactationStage',
                render: (data) => {
                    return (data.production.currentLactationStage);
                },
                width: 200,
                sorter: (a, b) => a.production.currentLactationStage.localeCompare(b.production.currentLactationStage),
                hidden: this.state.currentLactationStage,
            },
            {
                title: 'Visual ID',
                key: 'visualID',
                render: (data) => {
                    return (data.identification.visualID);
                },
                width: 200,
                sorter: (a, b) => a.identification.visualID - b.identification.visualID,
            },
            {
                title: 'Date of Birth',
                key: 'dob',
                dataIndex: 'dob',
                width: 200,
                sorter: (a, b) => { return moment(a.dob).unix() - moment(b.dob).unix()},
                render: (value) => moment(value).format('ll'),
            },
            {
                title: 'Sex',
                key: 'sex',
                dataIndex: 'sex',
                width: 200,
                sorter: (a, b) => a.sex.localeCompare(b.sex),
                filters: [
                    {text: 'Male', value: 'Male'},
                    {text: 'Female', value: 'Female'},
                    {text: 'Other', value: 'Other'},
                ],
                onFilter: (value, record) => {
                    return record.sex === value
                },
            },
        ];

        this.setState({baseColumns: Columns});

    }

    DeleteGroupConfirm = async (data) => {
        this.setState({ loading: true });
        await GroupService.DeleteGroup(data._id);
        this.asyncBoot();
    }

    DeleteGroupCancel = () => {
    }


    onPaginationChange = (page) => {
        this.asyncBoot(page, {keyword: this.state.SearchQuery});
    }

    onSuccess = () => {
        this.asyncBoot();
    }

    render() {

        const { data, groupData, loading, saving, filterTable, selectedRowKeys, baseColumns } = this.state;

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              let Data = [];
              selectedRows.map(data => {
                  Data.push(data._id);
              })
              this.setState({selectedAnimals: Data});
            },
          };

        const tableProps = {
            size: 'middle',
            columns: baseColumns,
            rowKey: 'id',
            dataSource: filterTable == null ? data : filterTable,
            rowSelection: rowSelection,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: this.state.loading,
            scroll: { x: 1000 },
        };

        const groupTableProps = {
            size: 'middle',
            columns: this.groupColumns,
            rowKey: 'id',
            dataSource: groupData,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: this.state.loading,
            scroll: { x: 1000 },
        };

        return (
            <Row gutter={[24, 24]}>
                <GroupModal wrappedComponentRef={c => this.groupModal = c} onSuccess={this.onSuccess} />
                <Col span={24}>
                    <Header
                        icon="people"
                        title="Management Groups"
                        right={(
                            <Button disabled={loading || saving} type='primary' onClick={() => this.groupModal.OpenModel(this.state.selectedAnimals)}>Create New Group</Button>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Card bordered={false}>
                        <h4>Animal Information</h4>
                        <Row>
                            <Col span={24}>
                                <Input placeholder="Search Live Stocks" style={{ width: '100%' }} onKeyUp={this.keywordSearchHandler}/> 
                            </Col>
                        </Row> 
                        <Table rowClassName={(record, index) => (record.sex === 'Female') ? 'female-row' : (record.sex === 'Male') ? 'male-row' : '' } {...tableProps} />
                    </Card>
                </Col>
                <Col span={24}>
                    <Card bordered={false}>    
                        <h4>Group Information</h4>
                        <Table {...groupTableProps} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default ManagementGroups