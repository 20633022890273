import React, { Component } from 'react';
import { Row, Col, Card, Table, Button } from 'antd';
import { Header, EditLivestock } from '../../components';
import SessionsService from '../../services/Sessions-Service';
import moment from 'moment-timezone';

class SessionAnimals extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            sessionId: null,
            loading: false,
            SearchQuery: '',
            pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
            },
        };

        this.columns = [
            {
                title: 'Category',
                key: 'categoryName',
                dataIndex: 'categoryName',
                width: 150,
            },
            {
                title: 'Breed Name',
                key: 'breedName',
                dataIndex: 'breedName',
                width: 150,
            },
            {
                title: 'Sex',
                key: 'sex',
                dataIndex: 'sex',
                width: 100,
            },
            {
                title: 'Date Of Birth',
                key: 'dob',
                dataIndex: 'dob',
                width: 150,
                render: (value) => moment(value).format('ll'),
            },
            {
                title: 'Edit',
                key: '_id',
                width: 50,
                render: (data) => {
                    return(
                        <>
                        <a onClick={() => this.OpenLiveStockModel(data)} >Edit</a>
                        </>
                    );
                }
            },
        ];
    }

    OpenLiveStockModel = (data) => {
        this.EditLiveStockModel.OpenModel(data, '1');
    }

    SearchHandler = (event) => {
        let query = event.target.value;
        this.setState({SearchQuery: query});
        if(event.key === 'Enter') {
            this.asyncBoot(1, {keyword: query});
        }

        if(query.length === 0) {
            this.asyncBoot();
        }
    }


    componentDidMount() {
        let Id = this.props.match.params.Id;
        this.setState({sessionId: Id});
        this.asyncBoot(Id);
    }


    asyncBoot = async (Id) => {
        this.setState({loading: true});
        let response = await SessionsService.GetAnimalsBySessionId(Id);
        this.setState({loading: false, data: response.data.associatedAnimals});
    }


    onPaginationChange = (page) => {
        this.asyncBoot(page, {keyword: this.state.SearchQuery});
    }

    onSuccess = () => {
        this.asyncBoot(this.state.sessionId);
    }

    render() {

        const { data, loading, saving } = this.state;

        const tableProps = {
            size: 'middle',
            columns: this.columns,
            rowKey: 'id',
            dataSource: data,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: this.state.loading,
            scroll: { x: 1000 },
        };

        return (
            <Row gutter={[24, 24]}>
                <EditLivestock wrappedComponentRef={(data) => this.EditLiveStockModel = data} onSuccess={this.onSuccess} />
                <Col span={24}>
                    <Header
                        icon="people"
                        title="Session Animals"
                    />
                </Col>
                <Col span={24}>
                    <Card bordered={false}>    
                        <Table {...tableProps} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default SessionAnimals