import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Select, Input, notification, Spin } from 'antd';
import { connect } from 'react-redux';

// Farm Project Import Services
import GroupService from '../../services/Group-Service';
import MilkingService from '../../services/Milking-Service';
// Farm Project Import Services
const { Option } = Select;

class StartMilking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAnimals: [],
            selectedGroup: null,
            GroupsList: [],
            loading: false,
            saving: false,
            Model: false,
            loadingSession: false,
            isEdit: false,
            ModelTitle: null,
            Role: null,
        };
    }

    componentDidMount() {
        this.GetAllAnimalsGroups();
    }

    GetAllAnimalsGroups = async () => {
        this.setState({ loadingSession: true });
        let groupData = await GroupService.GetAllGroups();
        this.setState({GroupsList: groupData.data, loadingSession: false});
    }


    OpenModel = (data, isEdit) => {
        this.props.form.resetFields();
        if(isEdit) {
            const { setFieldsValue } = this.props.form;
            setFieldsValue(data);
        }
        this.setState({ModelTitle: isEdit ? 'Edit Milking' : 'Start New Milking', Model: true, isEdit: isEdit});         
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    ResponseSuccessfull(message) {
		notification.success({ message: 'Animal Milking', description: message});
        this.setState({ loading: false }, this.ClosedModel);
        this.props.form.resetFields();
        if (this.props.onSuccess) this.props.onSuccess();
	}

    ErrorAlert(error) {
        notification.error({ message: 'Animal Milking', description: 'Service Unavailable'});
    }

    onSubmit = (e) => {
        if (e) e.preventDefault()
        const {validateFields} = this.props.form; 

        validateFields((err, values) => {
            if (err) return;
            

            this.setState({ loading: true });

            let body = {
                animals: this.state.selectedAnimals,
                title: values.title,
                groupName: this.state.selectedGroup,
                status: values.status,
            };
            
            // Update Existing Record
            if(this.state.isEdit) {
                delete body.animals;
                delete body.groupName;
                body._id = values._id;
            }

            let ServiceType = this.state.isEdit ? 'UpdateMilkingById' : 'CreateNewMilking';

            MilkingService[ServiceType](body)
            .then(resp => {
                if(resp.success) this.ResponseSuccessfull(resp.message)
            }).catch( error => this.ErrorAlert());
            this.props.form.resetFields();

        });
    }

    onGroupChange = (Id) => {
        let data = this.state.GroupsList.filter(data => data._id === Id)[0];
        let selectedAnimals = [];
        data.animal.map(data => {
            selectedAnimals.push({animal: data, alert: "62967d931806e47c981a27c0"});
        });
        this.setState({selectedAnimals: selectedAnimals, selectedGroup: data.name});
    }

    render() {

        const { loading, Model, isEdit } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '800px'
        };

        getFieldDecorator('_id', { initialValue: null });

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>{this.state.ModelTitle}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label='Title'>
                                {getFieldDecorator('title', {
                                    rules: [{ required: true, message: 'Title is required' }]
                                })(
                                    <Input />
                                )}
                        </Form.Item> 
                        </Col>
                        { !isEdit &&
                            <Col span={12}>
                            <Form.Item label='Group'>
                                {getFieldDecorator('group', {
                                    rules: [{ required: true, message: 'Group is required' }]
                                })(
                                    <Select disabled={this.state.isEdit} showSearch className='width-100' onChange={this.onGroupChange}>
                                        {this.state.GroupsList.map((data, key) => {
                                                return (<Option key={key + ""} value={data._id}>{data.name}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item> 
                        </Col>
                        }
                        <Col span={12}>
                            <Form.Item label='status'>
                                {getFieldDecorator('status', {
                                    rules: [{ required: true, message: 'Status is required' }]
                                })(
                                    <Select showSearch className='width-100'>
                                        <Option key={1} value="Completed">Completed</Option>
                                        <Option key={2} value="In Progress">In Progress</Option>
                                    </Select>
                                )}
                        </Form.Item> 
                        </Col>
                    </Row>  
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Save</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _StartMilking = Form.create()(StartMilking);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_StartMilking);
