import React, { Component } from 'react';
import { Row, Col, Card, Table, Button, Tabs } from 'antd';
import { Header, FarmModal } from '../../components';
import moment from 'moment-timezone';
import FarmService from '../../services/Farm-Service';

const { TabPane } = Tabs;

class Farms extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tableData: [],
            categoriesList: [],
            selectedCategoriesID: null,
            loading: false,
            SearchQuery: '',
            columns: [],
            tabType: 'farms',
            pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
            },
        };
    }

    SearchHandler = (event) => {
        let query = event.target.value;
        this.setState({SearchQuery: query});
        if(event.key === 'Enter') {
            this.asyncBoot(1, {keyword: query});
        }

        if(query.length === 0) {
            this.asyncBoot();
        }
    }


    componentDidMount() {
        this.asyncBoot();
    }


    asyncBoot = async () => {
        this.setState({ loading: true });
        let response = await FarmService.GetAllFarms();
        this.setState({data: response.data, tableData: response.data, loading: false});
        this.onChangeFarmTab(this.state.tabType);
    }


    onPaginationChange = (page) => {
        this.asyncBoot(page, {keyword: this.state.SearchQuery});
    }

    onSuccess = () => {
        this.asyncBoot();
    }

    onChangeFarmTab = (tab) => {
        this.setState({tabType: tab});
        const locations = [];
        const Pens = [];
        const Bunks = [];
        this.state.data.map(farm => { // Add Farm Location List Data
            farm.locations.map(row => { 
                locations.push({
                    id: row._id,
                    farm_id : farm._id,
                    farmName: farm.name,
                    locationName: row.locationName,
                    startDate: row.startDate,
                });
            });
            farm.pens.map(row => { // Add Farm Pens List Data
                Pens.push({
                    id: row._id, 
                    farm_id: farm._id,
                    farmName: farm.name,
                    location_id : row.location_id,
                    locationName: farm.locations.filter(data => data._id === row.location_id)[0]['locationName'],
                    penName: row.penName,
                    penType: row.penType,
                    startDate: row.startDate,
                    endDate: row.endDate,
                });
            });
            farm.bunks.map(row => { // Add Farm Bunks List Data
                let filterLocation = farm.locations.filter(data => data._id === row.location_id)[0];
                let filterPen = farm.pens.filter(data => data._id === row.pen_id)[0];
                Bunks.push({
                    id: row._id, 
                    farm_id: farm._id,
                    farmName: farm.name,
                    location_id : row.location_id,
                    locationName: filterLocation['locationName'],
                    penName: filterPen['penName'],
                    pen_id: row.pen_id,
                    bunkName: row.bunkName,
                    bunkType: row.bunkType,
                });
            })
        });

         if(tab === 'farms') { // Load Farm Table Grid    
            const UserFarms = this.state.data;
            this.setState({
                tableData: UserFarms,
                columns : [
                    {
                        title: 'Farm Name',
                        key: 'name',
                        dataIndex: 'name',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: 'Email',
                        key: 'email',
                        dataIndex: 'email',
                        width: 200,
                    },
                    {
                        title: 'Country',
                        key: 'country',
                        dataIndex: 'country',
                        width: 150,
                    },
                    {
                        title: 'Town City',
                        key: 'town',
                        dataIndex: 'town',
                        width: 150,
                    },
                    {
                        title: 'Address',
                        key: 'address',
                        dataIndex: 'address',
                        width: 250,
                    },
                    {
                        title: 'Postal Code',
                        key: 'postalCode',
                        dataIndex: 'postalCode',
                        width: 150,
                    },
                    {
                        title: 'Date opened',
                        key: 'DateOpened',
                        dataIndex: 'DateOpened',
                        width: 150,
                        render: (value) => moment(value).format('ll'),
                    },
                    {
                        title: 'Telephone',
                        key: 'phone',
                        dataIndex: 'phone',
                        width: 200,
                    },
                    {
                        title: 'Action',
                        key: 'id',
                        width: 100,
                        fixed: 'right',
                        render: (data) => {
                            return(
                                <>
                                <a key={data._id} onClick={ () => this.OpenFarmModal(data, 'Edit')} >Edit</a>
                                </>
                            );
                        }
                    },
                ]
            });
         }
         else if( tab === 'location') {
            this.setState({
                tableData: locations,
                columns : [
                    {
                        title: 'Farm Name',
                        key: 'farmName',
                        dataIndex: 'farmName',
                        width: 200,
                    },
                    {
                        title: 'Location',
                        key: 'locationName',
                        dataIndex: 'locationName',
                        width: 200,
                    },
                    {
                        title: 'Start Date',
                        key: 'startDate',
                        dataIndex: 'startDate',
                        width: 150,
                        render: (value) => moment(value).format('ll'),
                    },
                    {
                        title: 'Action',
                        key: 'id',
                        width: 100,
                        render: (data) => {
                            return(
                                <>
                                <a key={data._id} onClick={ () => this.OpenFarmModal(data, 'Edit')} >Edit</a>
                                </>
                            );
                        }
                    },
                ]
            });
         }
         else if( tab === 'pen') {
            this.setState({
                tableData: Pens,
                columns : [
                    {
                        title: 'Farm Name',
                        key: 'farmName',
                        dataIndex: 'farmName',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: 'Location',
                        key: 'locationName',
                        dataIndex: 'locationName',
                        width: 200,
                    },
                    {
                        title: 'Pen Name',
                        key: 'penName',
                        dataIndex: 'penName',
                        width: 200,
                    },
                    {
                        title: 'Type',
                        key: 'penType',
                        dataIndex: 'penType',
                        width: 200,
                    },
                    {
                        title: 'Start Date',
                        key: 'startDate',
                        dataIndex: 'startDate',
                        render: (value) => moment(value).format('ll'),
                        width: 150,
                    },
                    {
                        title: 'End Date',
                        key: 'endDate',
                        dataIndex: 'endDate',
                        render: (value) => moment(value).format('ll'),
                        width: 150,
                    },
                    {
                        title: 'Action',
                        key: 'id',
                        width: 100,
                        fixed: 'right',
                        render: (data) => {
                            return(
                                <>
                                <a key={data._id} onClick={ () => this.OpenFarmModal(data, 'Edit')} >Edit</a>
                                </>
                            );
                        }
                    },

                ]
            });
         }
         else if( tab === 'bunks') {
            this.setState({
                tableData: Bunks,
                columns : [
                    {
                        title: 'Farm Name',
                        key: 'farmName',
                        dataIndex: 'farmName',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: 'Location',
                        key: 'locationName',
                        dataIndex: 'locationName',
                        width: 200,
                    },
                    {
                        title: 'Pen Name',
                        key: 'penName',
                        dataIndex: 'penName',
                        width: 200,
                    },
                    {
                        title: 'Bunk Name',
                        key: 'bunkName',
                        dataIndex: 'bunkName',
                        width: 200,
                    },
                    {
                        title: 'Bunk Type',
                        key: 'bunkType',
                        dataIndex: 'bunkType',
                        width: 200,
                    },
                    {
                        title: 'Action',
                        key: 'id',
                        width: 100,
                        fixed: 'right',
                        render: (data) => {
                            return(
                                <>
                                <a key={data._id} onClick={ () => this.OpenFarmModal(data, 'Edit')} >Edit</a>
                                </>
                            );
                        }
                    },
                    
                ]
            });
         }
    }

    OpenFarmModal = (data = null, formType) => {
       this.farmModel.OpenModel(this.state.tabType, data, formType);
    }

    render() {

        const { tableData, loading, saving } = this.state;

        const tableProps = {
            size: 'middle',
            columns: this.state.columns,
            rowKey: 'id',
            dataSource: tableData,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: this.state.loading,
            scroll: { x: 1000 },
        };

        return (
            <Row gutter={[24, 24]}>
                <FarmModal wrappedComponentRef={c => this.farmModel = c} onSuccess={this.onSuccess} />
                <Col span={24}>
                    <Header icon="edit" title="My Farms" right={(<Button disabled={loading || saving} type='primary' onClick={ () => this.OpenFarmModal(null, 'Add')}>Add New</Button>)}/>
                </Col>
                <Col span={24}>
                    <Card bordered={false}>   
                        <Tabs onChange={ this.onChangeFarmTab }>
                            <TabPane tab="Farms" key="farms"></TabPane>
                            <TabPane tab="Location" key="location"></TabPane>
                            <TabPane tab="Pen/Lot" key="pen"></TabPane>
                            <TabPane tab="Bunks/Feeding Pen" key="bunks"></TabPane>
                        </Tabs> 
                        <Table {...tableProps} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Farms