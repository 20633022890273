import React, { Component } from 'react';

import svgFire from '../../assets/images/icon/ic-trend.svg';
import svgFacebook from '../../assets/images/icon/ic-facebook.svg';
import svgInstagram from '../../assets/images/icon/ic-instagram.svg';
import svgLinkedin from '../../assets/images/icon/ic-linkedin.svg';

class Icon extends Component {

    static defaultProps = {
        size: 16,
        type: undefined,
        name: 'fire'
    };

    render() {

        let svg = '';

        switch (this.props.name) {
            default:
            case 'fire': svg = svgFire; break;
            case 'facebook': svg = svgFacebook; break;
            case 'linkedin': svg = svgInstagram; break;
            case 'instagram': svg = svgLinkedin; break;
        }

        return (
            <div style={{
                display: 'inline-block',
                width: this.props.size + 'px',
                height: this.props.size + 'px',
            }}>
                <figure style={{
                    width: this.props.size + 'px',
                    height: this.props.size + 'px',
                }}>
                    <img style={{
                        display: 'block',
                        margin: '0',
                        padding: '0',
                        border: '0',
                        width: '100%'
                    }} src={svg} alt="" />
                </figure>
            </div>
        );


    }

}

export default Icon;
