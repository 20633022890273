import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Select, Input, notification, Spin, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Admin from '../../services/admin';

const { Option } = Select;

class EditSubscriptionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscription: null,
            saving: false,
            Model: false,
            ModelTitle: null,
        };
    }

    componentDidMount() {
    
    }
    
    DateFormat(date){
        if(typeof date !== undefined && date !== "" ){
            return moment(date);
        }
    }


    OpenModel = (data) => {
        const {setFieldsValue} = this.props.form;
        setFieldsValue({
            _id: data._id,
            user: data.user,
            plan: data.plan,
            limit: data.limit,
            payment_status: data.payment_status,
            cost: data.cost, 
            expire_date: this.DateFormat(data.expire_date),
            activation_date: this.DateFormat(data.activation_date),
        });
        this.setState({
            ModelTitle: 'User Subscription',
            Model: true,
            subscription: data
        });            
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    successAlert(resp) {
        if (this.props.onSuccess) this.props.onSuccess();
        notification.success({ message: 'User Subscription', description: resp.message});
    }

    ErrorAlert(error) {
        notification.error({ message: 'User Subscription', description: 'Service Unavailable'});
    }

    UpdateSubscription = async (values) => {
        await Admin.ConfirmSubscription(values)
        .then(resp => this.successAlert(resp))
        .catch(error => this.ErrorAlert(error));
    }


    onSubmit = (e) => {
        if (e) e.preventDefault()
        const {validateFields} = this.props.form; 

        validateFields((err, values) => {
            if (err) return;
            this.setState({ loading: true });

            this.UpdateSubscription(values);
            
            this.setState({loading: false, Model: false});
            this.props.form.resetFields();
            
        });
    }

    render() {

        const { saving, Model } = this.state;
        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('_id', { initialValue: null });
        getFieldDecorator('user', { initialValue: null });
        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '600px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={saving}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>{this.state.ModelTitle}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Select Plan'>
                                    {getFieldDecorator('plan', {
                                        rules: [{ required: true, message: 'Plan is required' }]
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="plan_1" value="Basic">Basic Plan</Option>
                                            <Option key="plan_2" value="Custom">Custom Plan</Option>
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Animal Limit'>
                                    {getFieldDecorator('limit', {
                                        rules: [{ required: true, message: 'Animal limit is required' }]
                                    })(
                                        <Input />
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Plan Cost'>
                                    {getFieldDecorator('cost', {
                                        rules: [{ required: true, message: 'Cost is required' }]
                                    })(
                                        <Input />
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Payment Status'>
                                    {getFieldDecorator('payment_status', {
                                        rules: [{ required: true, message: 'Plan is required' }]
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="plan_1" value={true}>PAID</Option>
                                            <Option key="plan_2" value={false}>DUE</Option>
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Active Date'>
                                {getFieldDecorator('activation_date', {
                                    rules: [{ required: true, message: 'Please select Activation Date' }]})
                                    (<DatePicker className="width-100"/>)
                                }
                            </Form.Item>    
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Expire Date'>
                                {getFieldDecorator('expire_date', {
                                    rules: [{ required: true, message: 'Please select Expire Date' }]})
                                    (<DatePicker className="width-100"/>)
                                }
                            </Form.Item>    
                        </Col>
                    </Row>    
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Save</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _EditSubscriptionModal = Form.create()(EditSubscriptionModal);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_EditSubscriptionModal);
