import React, { Component } from 'react';
import { Row, Col, Card, Table, Button } from 'antd';
import { Header, BreedCategoryModal } from '../../components';
import moment from 'moment-timezone';
import categories from '../../services/categories';

//const { Option } = Select;

class UserBreed extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            categoriesList: [],
            selectedCategoriesID: null,
            loading: false,
            SearchQuery: '',
            pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
            },
        };

        this.columns = [
            {
                title: 'Animal Category',
                key: 'id',
                width: 300,
                render: (data) => {
                    return (data.animalCategoryID.animalCategoryName);
                }
            },
            {
                title: 'Animal Breed',
                key: 'animalBreedName',
                dataIndex: 'animalBreedName',
                width: 300,
            },
            {
                title: 'Created At',
                key: 'created',
                dataIndex: 'created',
                width: 150,
                render: (value) => moment(value).format('ll'),
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                width: 150,
            },
        ];
    }

    SearchHandler = (event) => {
        let query = event.target.value;
        this.setState({SearchQuery: query});
        if(event.key === 'Enter') {
            this.asyncBoot(1, {keyword: query});
        }

        if(query.length === 0) {
            this.asyncBoot();
        }
    }


    componentDidMount() {
        this.asyncBoot();
    }


    asyncBoot = async () => {
        this.setState({ loading: true });
        let response = await categories.RequestNewBreed();
        this.setState({data: response, loading: false});
    }


    onPaginationChange = (page) => {
        this.asyncBoot(page, {keyword: this.state.SearchQuery});
    }

    onSuccess = () => {
        this.asyncBoot();
    }

    render() {

        const { data, loading, saving } = this.state;

        const tableProps = {
            size: 'middle',
            columns: this.columns,
            rowKey: 'id',
            dataSource: data,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: this.state.loading,
            scroll: { x: 1000 },
        };

        return (
            <Row gutter={[24, 24]}>
                <BreedCategoryModal wrappedComponentRef={c => this.categoryModel = c} onSuccess={this.onSuccess} />
                <Col span={24}>
                    <Header
                        icon="people"
                        title="My Breed Request"
                        right={(
                            <Button disabled={loading || saving} type='primary' onClick={() => this.categoryModel.OpenModel(null, 'User')}>Add New</Button>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Card bordered={false}>    
                        <Table {...tableProps} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default UserBreed