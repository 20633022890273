import React, { Component } from 'react';
import { Modal, Row, Col, Spin, Form, Timeline, Card, Descriptions } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

class ReportingModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Model: false,
            Data : [],
            modelType: '',

        };
    }

    componentDidMount() {
    }

    OpenModel = (data, type) => {
        this.setState({ Model: true, modelType: type, Data: data });
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    DateFormat(date){
        if(date && typeof date !== undefined && date !== "" ){
            return moment(date).format('ll');
        }
    }

    PrintData = () => {
        let {Data, modelType} = this.state;
        let Result = null;
        if(modelType === "Session") {
        Result = Data.map( (data, key) => {
            return(    
                <Timeline.Item key={key}>
                    <Card key={key}>
                        <Descriptions key={key}>
                            <Descriptions.Item label="Session">{data.name}</Descriptions.Item>
                            <Descriptions.Item label="Date">-</Descriptions.Item>
                            <Descriptions.Item label="Status">{data.status}</Descriptions.Item>
                            <Descriptions.Item label="Description">{data.description}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Timeline.Item>
            );                
        })
        }
        else if(modelType === "Milk") {
            Result = Data.map( (data, key) => {
                return(    
                    <Timeline.Item key={key}>
                        <Card key={key}>
                            <Descriptions key={key} column={{ xl: 4, lg: 4, md: 2, sm: 1, xs: 1 }}>
                                <Descriptions.Item label="Title">{data.title}</Descriptions.Item>
                                <Descriptions.Item label="Status">{data.status}</Descriptions.Item>
                                <Descriptions.Item label="Group Name">{data.groupName}</Descriptions.Item>
                                <Descriptions.Item label="Date">{this.DateFormat(data.created)}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Timeline.Item>
                );                
            })
        }

        return (<>{Result}</>);


    }


    render() {

        const { loading, Model, modelType, Data } = this.state;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '1200px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>{modelType} Detail</span>
                            </div>
                            <br/>
                            <Timeline>
                                { this.PrintData() }
                            </Timeline>
                        </Col>
                    </Row>
                </Spin>
            </Modal >
        );
    }

}

const _ReportingModel = Form.create()(ReportingModel);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_ReportingModel);
