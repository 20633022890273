import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Input, Select, notification, Spin, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

// Farm Project Import Services
import SessionsService from '../../services/Sessions-Service';
// Farm Project Import Services

const { Option } = Select;

class SessionsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAnimals: [],
            sessionActivities: [],
            loading: false,
            saving: false,
            Model: false,
            isEdit: false,
            ModelTitle: null,
            Role: null,
            sessionId: null,
        };
    }

    componentDidMount() {
        this.GetSessionActivitiesList();
    }

    GetSessionActivitiesList = async () => {
        let response = await SessionsService.GetSessionActivities();
        this.setState({sessionActivities: response.data});
    }

    DateFormat(date){
        if(typeof date !== undefined && date !== "" ){
            return moment(date);
        }
       }


    OpenModel = (animals, isEdit) => {
        if(isEdit) { // set form data if form type is edit
            const { setFieldsValue } = this.props.form;
            setFieldsValue({
                builtinAcitvity: animals.builtinAcitvity._id,
                description: animals.description,
                name: animals.name,
                status: animals.status,
                date: this.DateFormat(animals.date)
            });
        }
        this.setState({
            ModelTitle: 'Animal Sessions',
            sessionId: animals._id,
            Model: true,
            isEdit: isEdit,
            selectedAnimals: isEdit ? animals.animals : animals
        });         
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    ResponseSuccessfull(message){
		notification.success({ message: 'Animal Sessions', description: message});
        this.setState({ loading: false }, this.ClosedModel);
        this.props.form.resetFields();
        if (this.props.onSuccess) this.props.onSuccess();
	}

    ErrorAlert(error) {
        notification.error({ message: 'Animal Sessions', description: 'Service Unavailable'});
    }

    onSubmit = (e) => {
        if (e) e.preventDefault()
        const {validateFields} = this.props.form; 

        validateFields((err, values) => {
            if (err) return;

            if(this.state.selectedAnimals.length === 0) {
                return notification.error({message: 'Animal Sessions', description: 'Animal or group selection is required'});
            }
            
            let body = {
                animals: this.state.selectedAnimals,
                name: values.name,
                date: values.date,
                builtinAcitvity: values.builtinAcitvity,
                status: values.status,
                description: values.description,
            };
            this.setState({ loading: true });
            this.state.isEdit ? this.UpdateSession(body) : this.AddSession(body);
            this.props.form.resetFields();

        });
    }


    AddSession = (body) => {
        SessionsService.AddSessions(body).then(resp => {
            if(resp.success) {
               this.ResponseSuccessfull(resp.message);
            }
            else {
                this.ErrorAlert();
            }
        })
        .catch( error => {
            this.ErrorAlert();
        });
    }

    UpdateSession = (body) => {
        SessionsService.UpdateSessions(body, this.state.sessionId)
        .then(resp => {
            if(resp.success) {
               this.ResponseSuccessfull(resp.message);
            }
            else {
                this.ErrorAlert();
            }
        })
        .catch( error => {
            this.ErrorAlert();
        });
    }

    render() {

        const { loading, Model } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '800px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>{this.state.ModelTitle}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Sessions Name'>
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: 'Session name is required' }]
                                })(
                                    <Input />
                                )}
                        </Form.Item> 
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Sessions Date'>
                                {getFieldDecorator('date', {
                                        rules: [{ required: true, message: 'Session date is required' }]
                                    })(
                                        <DatePicker className="width-100"/>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Built in Acitvity'>
                                {getFieldDecorator('builtinAcitvity', {
                                    rules: [{ required: false, message: '' }]
                                })(
                                    <Select showSearch className='width-100'>
                                        {this.state.sessionActivities.map((data, key) => {
                                        return (<Option key={key} value={data._id}>{data.name}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item> 
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Status'>
                                {getFieldDecorator('status', {
                                        rules: [{ required: true, message: 'Status is required' }]
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key={1} value="Schedule">Schedule</Option>
                                            <Option key={2} value="In-progress">In-progress</Option>
                                            <Option key={3} value="Completed">Completed</Option>
                                            <Option key={3} value="Cancel">Cancel</Option>
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label='Custom Activity/Description'>
                                    {getFieldDecorator('description', {
                                        rules: [{ required: true, message: 'Sessions Activity is required' }]
                                    })(
                                        <Input />
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>    
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Save</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _SessionsModal = Form.create()(SessionsModal);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_SessionsModal);
