import api from '../lib/api';

export default {

    get_live_stocks: (params = {}) => {
        return new Promise((resolve, reject) => {
            api()
                .get('/live-stocks', { params })
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`get text code failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

}