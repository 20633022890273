import realEstate from '../dashboard/realEstate';
import AdminDashboard from '../admin/dashboard';
import AnimalCategory from '../admin/animal-category';
import BreedCategory from '../admin/breed-category';
import RequestBread from '../admin/request-bread';
import UserBreed from '../user-breed-Request/user-breed-request';
import Farms from '../farms';
import ManagementGroups from '../management-groups';
import AnimalMovement from '../animal-movement';
import Sessions from '../sessions';
import ReportingComponent from '../reporting';
import Subscription from '../subscription';
import SubscriptionRequest from '../admin/subscription-request';
import Milking from '../milking';
import moment from 'moment-timezone';

const routes = [

    {
        icon: 'space_dashboard',
        label: 'Live Stocks',
        path: '/dashboard',
        roleName: ['User', 'Staff'],
        disabled: true,
        routes: [
            { exact: true, path: '/dashboard', component: realEstate },
        ]
    },

    {
        icon: 'pets',
        label: 'Breed Request',
        path: '/mybreed-request',
        roleName: ['User', 'Staff'],
        disabled: true,
        routes: [
            { exact: true, path: '/mybreed-request', component: UserBreed },
        ]
    },

    // UNDER CONSTRUCTION MENUS
    {
        icon: 'agriculture',
        label: 'Farms',
        path: '/farms',
        roleName: ['User'],
        disabled: true,
        routes: [
            { exact: true, path: '/farms', component: Farms },
        ]
    },
    {
        icon: 'face',
        label: 'Users',
        path: '/users',
        roleName: ['Admin'],
        disabled: false,
        routes: [
            { exact: true, path: '/users', component: AdminDashboard },
        ]
    },
    {
        icon: 'person_pin',
        label: 'Management Groups',
        path: '/management-groups',
        roleName: ['User', 'Staff'],
        disabled: true,
        routes: [
            { exact: true, path: '/management-groups', component: ManagementGroups },
        ]
    },
    {
        icon: 'person_pin',
        label: 'Animal Movement',
        path: '/animal-movement',
        roleName: ['User', 'Staff'],
        disabled: true,
        routes: [
            { exact: true, path: '/animal-movement', component: AnimalMovement },
        ]
    },
    {
        icon: 'fact_check',
        label: 'Sessions',
        path: '/sessions',
        roleName: ['User', 'Staff'],
        disabled: true,
        routes: [
            { exact: true, path: '/sessions', component: Sessions },
        ]
    },
    {
        icon: 'pan_tool',
        label: 'Milking',
        path: '/milking',
        roleName: ['User', 'Staff'],
        disabled: true,
        routes: [
            { exact: true, path: '/milking', component: Milking },
        ]
    },
    {
        icon: 'analytics',
        label: 'Reporting',
        path: '/reporting',
        roleName: ['User', 'Staff'],
        disabled: true,
        routes: [
            { exact: true, path: '/reporting', component: ReportingComponent },
        ]
    },
    {
        icon: 'currency_exchange',
        label: 'Subscription',
        path: '/subscription',
        roleName: ['User', 'Staff'],
        disabled: false,
        routes: [
            { exact: true, path: '/subscription', component: Subscription },
        ]
    },
    // END OF UNDER CONSTRUCTION MENUS

    {
        icon: 'lock_open',
        label: 'Animal Category',
        path: '/admin/animal-catergory',
        roleName: ['Admin'],
        disabled: false,
        routes: [
            { exact: true, path: '/admin/animal-catergory', component: AnimalCategory },
        ]
    },

    {
        icon: 'lock_open',
        label: 'Breed Categories',
        path: '/admin/breed-category',
        roleName: ['Admin'],
        disabled: false,
        routes: [
            { exact: true, path: '/admin/breed-category', component: BreedCategory },
        ]
    },

    {
        icon: 'lock_open',
        label: 'Request New Breed',
        path: '/admin/request-breed',
        roleName: ['Admin'],
        disabled: false,
        routes: [
            { exact: true, path: '/admin/request-breed', component: RequestBread },
        ]
    },

    {
        icon: 'lock_open',
        label: 'subscription Request',
        path: '/admin/subscription-request',
        roleName: ['Admin'],
        disabled: false,
        routes: [
            { exact: true, path: '/admin/subscription-request', component: SubscriptionRequest },
        ]
    },


];


const getMenuItems = (roleName, subscription) => {

    let todayDate = moment().format("YYYY-MM-DD");
    let subscriptionDate = moment().format("YYYY-MM-DD");

    if(roleName && roleName !== "Admin") {
        subscriptionDate = moment(subscription.expire_date).format("YYYY-MM-DD");
    }

    let items = [];
       
        for (let i = 0; i < routes.length; i++) {
            const element = routes[i];
            // Check here if user has Admin
            if (roleName === "Admin" && element.roleName.includes(roleName)) {
                items.push({
                    icon: element.icon,
                    label: element.label,
                    path: element.path,
                    disabled: element.disabled === false
                });
            }

            if(subscription !== undefined) {
                // Check here if user has active subscription
                if (roleName !== "Admin" && element.roleName.includes(roleName) && subscriptionDate > todayDate && element.disabled == subscription.payment_status){
                    items.push({
                        icon: element.icon,
                        label: element.label,
                        path: element.path,
                        disabled: element.disabled === false
                    });
                }

                // add subscription routes for users only
                if (roleName !== "Admin" && element.roleName.includes(roleName) && element.disabled == false){
                    items.push({
                        icon: element.icon,
                        label: element.label,
                        path: element.path,
                        disabled: element.disabled === false
                    });
                }
                
            }
   
        }

    return items;
}

const getRoutes = (roleName, subscription) => {
    
    let todayDate = moment().format("YYYY-MM-DD");
    let subscriptionDate = moment().format("YYYY-MM-DD");

    if(roleName && roleName !== "Admin") {
        subscriptionDate = moment(subscription.expire_date).format("YYYY-MM-DD");
    }

    let items = [];

    for (let i = 0; i < routes.length; i++) {
        const element = routes[i];

        // Check here if user has Admin
        if (roleName === "Admin" && element.roleName.includes(roleName)) {
            items = items.concat(element.routes);
        }

        if(subscription !== undefined) {
            // Check here if user has active subscription
            if (roleName !== "Admin" && element.roleName.includes(roleName) && subscriptionDate > todayDate && element.disabled == subscription.payment_status){
                items = items.concat(element.routes);
            }
            // Check here if user has active subscription
            if (roleName !== "Admin" && element.roleName.includes(roleName) && element.disabled == false) {
                items = items.concat(element.routes);
            }
        }


    }
    return items;
}

export {
    getMenuItems,
    getRoutes
}