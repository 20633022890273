const initialState = {
    authenticated: false,
    token: '',
    user: {},
    root: undefined,
    selectedAnimals: false,
    AnimalsIds: []
};

export default (state = initialState, action) => {

    switch (action.type) {
        case 'AUTH_SIGNIN':
            return {
                ...state,
                authenticated: true,
                token: action.payload.token,
                user: action.payload.user,
            }
        case 'AUTH_SIGNOUT':
            return {
                ...state,
                ...initialState
            }
        case 'AUTH_UPDATE_PROFILE':
            return {
                ...state,
                user: action.payload.user,
            }
        case 'AUTH_VIEWAS':
            let root = state.root || { user: state.user, token: state.token };
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                root: root,
            }
        case 'AUTH_RETURN':
            return {
                ...state,
                token: state.root.token,
                user: state.root.user,
                root: undefined
            }
        case 'SELECTED_ANIMALS':
            return {
                ...state,
                selectedAnimals: action.payload.selectedAnimals,
                AnimalsIds: action.payload.AnimalsIds
            }
        default:
            return state
    }
}