export const authSignin = (token, user, remember = false, viewAs = false) => {
    return dispatch => {
        localStorage.setItem('FARM_TOKEN', token);
        if (viewAs) {
            dispatch({ type: 'AUTH_VIEWAS', payload: { token, user } });
        } else {
            dispatch({ type: 'AUTH_SIGNIN', payload: { token, user } });
        }
    }
}

export const authSignout = () => {
    return dispatch => {
        localStorage.removeItem('FARM_TOKEN');
        dispatch({ type: 'AUTH_SIGNOUT' });
    }
}

export const authUpdateProfile = (user = {}) => {
    return dispatch => {
        dispatch({ type: 'AUTH_UPDATE_PROFILE', payload: { user } });
    }
}

export const authReturn = (token) => {
    return dispatch => {
        localStorage.setItem('FARM_TOKEN', token);
        dispatch({ type: 'AUTH_RETURN' });
    }
}

export const catchLiveStockIds = (payload) => {
    return dispatch => {
        dispatch({ type: 'SELECTED_ANIMALS', payload });
    }
}