import React, { Component } from 'react';
import { Row, Col, Card, Table, Button, Checkbox, Tabs, Input   } from 'antd';
import { Header, SessionsModel } from '../../components';
import moment from 'moment-timezone';
import categories from '../../services/categories';
import dashboard from '../../services/dashboard';
import GroupService from '../../services/Group-Service';
import SessionsService from '../../services/Sessions-Service';
//const { Option } = Select;
const { TabPane } = Tabs;


class Sessions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            groupData: [],
            sessionsData: [],
            categoriesList: [],
            selectedCategoriesID: null,
            loading: false,
            loadingSession: false,
            filterTable: null,
            activeTabe: 1,
            selectedAnimals: [],
            SearchQuery: '',
            pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
            },
        };

        this.baseColumns = [
            {
                title: 'Category',
                key: 'categoryName',
                dataIndex: 'categoryName',
                width: 120,
                fixed: 'left',
                sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
            },
            {
                title: 'Breed',
                key: 'breedName',
                dataIndex: 'breedName',
                width: 120,
                fixed: 'left',
                sorter: (a, b) => a.breedName.localeCompare(b.breedName),
            },
            {
                title: 'Farm',
                key: '_id',
                width: 200,
                render: (data) => {return (data.farm.name)},
                sorter: (a, b) => a.farm.name.localeCompare(b.farm.name),
            },
            {
                title: 'Location',
                key: 'location_id',
                width: 120,
                render: (data) => {
                    let locationId = data.location_id;
                    return data.farm.locations.filter(x => x._id === locationId)[0]['locationName'];
                },
                sorter: (a, b) => a.locations['locationName'].localeCompare(b.locations['locationName']),
            },
            {
                title: 'Current Weight kg',
                key: 'Current Weight kg',
                render: (data) => {
                    return (data.weight.currentWeightkg);
                },
                width: 200,
                sorter: (a, b) => a.weight.currentWeightkg.localeCompare(b.weight.currentWeightkg),
                hidden: this.state.currentWeightkg,
            },
            {
                title: 'Condition Score',
                key: 'conditionScore',
                render: (data) => {
                    return (data.weight.conditionScore);
                },
                width: 200,
                sorter: (a, b) => a.weight.conditionScore.localeCompare(b.weight.conditionScore),
                hidden: this.state.conditionScore,
    
            },
            {
                title: 'Sire ID',
                key: 'sireID',
                render: (data) => {
                    return (data.identification.sireID);
                },
                width: 200,
                sorter: (a, b) => a.identification.sireID.localeCompare(b.identification.sireID),
                hidden: this.state.sireID,
    
            },
            {
                title: 'Dam ID',
                key: 'damID',
                render: (data) => {
                    return (data.identification.damID);
                },
                width: 200,
                sorter: (a, b) => a.identification.damID.localeCompare(b.identification.damID),
                hidden: this.state.damID,
    
            },
            {
                title: 'Current Lactation Stage',
                key: 'currentLactationStage',
                render: (data) => {
                    return (data.production.currentLactationStage);
                },
                width: 200,
                sorter: (a, b) => a.production.currentLactationStage.localeCompare(b.production.currentLactationStage),
                hidden: this.state.currentLactationStage,
            },
            {
                title: 'Visual ID',
                key: 'visualID',
                render: (data) => {
                    return (data.identification.visualID);
                },
                width: 200,
                sorter: (a, b) => a.identification.visualID - b.identification.visualID,
            },
            {
                title: 'Date of Birth',
                key: 'dob',
                dataIndex: 'dob',
                width: 200,
                sorter: (a, b) => { return moment(a.dob).unix() - moment(b.dob).unix()},
                render: (value) => moment(value).format('ll'),
            },
            {
                title: 'Sex',
                key: 'sex',
                dataIndex: 'sex',
                width: 200,
                sorter: (a, b) => a.sex.localeCompare(b.sex),
                filters: [
                    {text: 'Male', value: 'Male'},
                    {text: 'Female', value: 'Female'},
                    {text: 'Other', value: 'Other'},
                ],
                onFilter: (value, record) => {
                    return record.sex === value
                },
            },
        ];

        this.groupColumns = [
            {
                title: 'Group Name',
                key: 'name',
                dataIndex: 'name',
                width: 100,
            },
            {
                title: 'Description',
                key: 'description',
                dataIndex: 'description',
                width: 150,
            },
            {
                title: 'Number Of Animals',
                key: 'animal',
                dataIndex: 'animal',
                width: 100,
                render: (data) => data.length
            },
            {
                title: 'Create Date',
                key: 'created',
                dataIndex: 'created',
                width: 100,
                render: (value) => moment(value).format('ll'),
            }
        ];

        this.sessionsColumns = [
            {
                title: 'Sessions Name',
                key: 'name',
                dataIndex: 'name',
                width: 100,
            },
            {
                title: 'built In Acitvity',
                key: 'builtinAcitvity',
                dataIndex: 'builtinAcitvity',
                width: 100,
                render: (data) => {
                    return data.name;
                }
            },
            {
                title: 'Activities/Description',
                key: 'description',
                dataIndex: 'description',
                width: 150,
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                width: 100,
            },
            {
                title: 'Start Date',
                key: 'date',
                dataIndex: 'date',
                width: 100,
                render: (value) => moment(value).format('ll'),
            },
            {
                title: 'Edit',
                key: 'id',
                width: 50,
                render: (data) => {
                    return(
                        <>
                        <a key={data._id} onClick={() => this.sessionModal.OpenModel(data, true)} >Edit</a>
                        </>
                    );
                }
            },
            {
                title: 'Animal Info',
                key: 'id',
                width: 100,
                render: (data) => {
                    if(data.builtinAcitvity !== "") {
                        const URL = window.location.origin + `/app/session/animals/${data._id}`;
                        return(<> <a href={URL}>Edit</a> </>);
                    }
                }
            },
        ];
    }

    SearchHandler = (event) => {
        let query = event.target.value;
        this.setState({SearchQuery: query});
        if(event.key === 'Enter') {
            this.asyncBoot(1, {keyword: query});
        }

        if(query.length === 0) {
            this.asyncBoot();
        }
    }


    componentDidMount() {
       this.asyncBoot();
    }


    asyncBoot = async () => {
        this.setState({ loading: true });
        let groupData = await GroupService.GetAllGroups();
        let Data = await dashboard.get_live_stocks({categoryName: 'All'});
        this.setState({data: Data, groupData: groupData.data, loading: false});
        this.GetSession();
    }


    GetSession = async () => {
        this.setState({ loadingSession: true });
        let Sessions = await SessionsService.GetAllSessions();
        this.setState({sessionsData: Sessions.data, loadingSession: false});
    }

    onPaginationChange = (page) => {
        this.asyncBoot(page, {keyword: this.state.SearchQuery});
    }
    

    onSuccess = () => {
       this.GetSession();
    }

    keywordSearchHandler = (event) => {
        let serchValue = event.target.value;
        const { data } = this.state;
        const filterTable = data.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(serchValue.toLowerCase())
          )
        );            
        this.setState({ filterTable });
    };

    onChangeTab = async (key) => {
        this.setState({activeTabe: key});
    }

    render() {

        const { data, groupData, loading, saving, filterTable, sessionsData, loadingSession } = this.state;

        const indivisualSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              let Data = [];
              selectedRows.map(data => {
                  Data.push(data._id);
              })
              this.setState({selectedAnimals: Data});
            },
          };

          const groupSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              let Data = [];
              selectedRows.map(data => {
                if(data.animal.length > 0) {
                    data.animal.map(animal => {
                        Data.push(animal);
                    });
                    
                }
              });
            
              let FilterAnimals = Data.filter((c, index) => {
                return Data.indexOf(c) === index;
            });
               this.setState({selectedAnimals: FilterAnimals});
            },
          };

        const tableProps = {
            size: 'middle',
            columns: this.baseColumns,
            rowKey: 'id',
            dataSource: filterTable == null ? data : filterTable,
            rowSelection: indivisualSelection,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: loading,
            scroll: { x: 1000 },
        };

        const groupTableProps = {
            size: 'middle',
            columns: this.groupColumns,
            rowKey: 'id',
            dataSource: groupData,
            rowSelection: groupSelection,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: loading,
            scroll: { x: 1000 },
        };

        const SessionsTableProps = {
            size: 'middle',
            columns: this.sessionsColumns,
            rowKey: 'id',
            dataSource: sessionsData,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: loadingSession,
            scroll: { x: 1000 },
        };

        return (
            <Row gutter={[24, 24]}>
                <SessionsModel wrappedComponentRef={c => this.sessionModal = c} onSuccess={this.onSuccess} />
                <Col span={24}>
                    <Header
                        icon="people"
                        title="Session Animal"
                        right={(
                            <Button disabled={loading || saving} type='primary' onClick={() => this.sessionModal.OpenModel(this.state.selectedAnimals, false)}>Create Sessions</Button>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Card bordered={false}>
                    <Tabs defaultActiveKey="1" onChange={this.onChangeTab}>
                        <TabPane tab="Individual Sessions" key="1">
                            <Row>
                                <Col className='mb-20' span={24}>
                                    <Input placeholder="Search Live Stocks" style={{ width: '100%' }} onKeyUp={this.keywordSearchHandler}/> 
                                </Col>
                            </Row>  
                            <Table rowClassName={(record, index) => (record.sex === 'Female') ? 'female-row' : (record.sex === 'Male') ? 'male-row' : '' } {...tableProps} />
                        </TabPane>
                        <TabPane tab="Group Sessions" key="2">
                            <Table {...groupTableProps} />
                        </TabPane>
                    </Tabs>    
                        
                    </Card>
                </Col>
                <Col span={24}>
                    <Card bordered={false}>    
                        <h4>Sessions Information</h4>
                        <Table {...SessionsTableProps} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Sessions