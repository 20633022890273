const flag = "production";

const env = {
    localhost: {
        API_URL: "http://localhost:4000",
        SWP_URL: "https://swpdev.farmapp.co",

    },
    production: {
        API_URL: "https://api.maveshi365.com",
        SWP_URL: "https://swpdev.farmapp.co",

    },
    staging: {
        API_URL: "https://api.maveshi365.com",
        SWP_URL: "https://swpdev.farmapp.co",
    },


};

export default {
    ...env[flag],
    flag: flag,
};