import React, { Component } from 'react';
import { Row, Col, Card, Layout, Button, Avatar, Table, notification, Menu, Dropdown, Icon, Modal, Tag  } from 'antd';
import { connect } from 'react-redux';
//import moment from 'moment-timezone';

import { NewUser, ChangeCredentialsComponent, ImportLiveStock, StockLimitModal, Header } from '../../components';

//import usrService from '../../services/users';
import admin from '../../services/admin';
import { forgotPassword, viewAs } from '../../services/auth';
import FarmService from '../../services/Farm-Service';

import * as actions from '../../actions/auth'

import icUser from '../../assets/images/ic-user.svg';

const { Content } = Layout;
const { confirm } = Modal;
//const { Option } = Select;

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
            loading: false,
            type: 'rea',
            lastFilters: {}
        };

        this.menu = (record) => {

            return (
                <Menu onClick={(item) => this.doAction(null, item.key, record)}>
                    <Menu.Item key="import_live_stocks">
                         Import Live Stocks
                    </Menu.Item>
                    <Menu.Item key="live_stock_limit">
                         Live Stock Limit
                    </Menu.Item>
                    <Menu.Item key="delete_user">
                        Delete
                    </Menu.Item>
                </Menu>
            )
        }

        this.columns = [
            {
                title: 'Full Name',
                key: 'firstName',
                dataIndex: 'firstName',
                width: 250,
                render: (text, record) => {
                    return (
                        <div>
                            <Avatar size={32} src={record.picture ? record.picture : icUser} />
                            <span>&nbsp;&nbsp;{record.firstName} {record.lastName}</span>
                        </div>
                    )
                }
            },
            
            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                width: 250
            },
            {
                title: 'Phone',
                key: 'phone',
                dataIndex: 'phone',
                width: 150
            },
            {
                title: 'Country',
                key: 'country',
                dataIndex: 'country',
                width: 120
            },
            {
                title: 'State',
                key: 'state',
                dataIndex: 'state',
                width: 100
            },
            {
                title: 'Postal Code',
                key: 'postalCode',
                dataIndex: 'postalCode',
                width: 100
            },
            {
                title: 'Limit',
                key: 'limit',
                dataIndex: 'limit',
                width: 100
            },
            {
                title: 'Role',
                key: 'role',
                dataIndex: 'role',
                width: 80
            },
            {
                title: 'Farm',
                key: 'id',
                dataIndex: 'id',
                width: 250,
                render: (text, record) => {
                    return (
                        <div>
                             {
                                 record.farms.map(farm => {
                                     return (<Tag>{farm.name}</Tag>)
                                 })
                             }
                        </div>
                    )
                }
            },
        ];
    }

    componentDidMount() {
      this.asyncBoot();
    }

    asyncBoot = async (page = 1, filters = {}) => {
        const { auth } = this.props;
        const { user } = auth;
        const role = user.role;
        const params = {
            page: page,
            ...filters
        }
        this.setState({ loading: true });
        let response;
        if( role === 'Admin' ) {
            response = await admin.ViewAllAccounts()
        }
        else {
            response = await FarmService.GetAllFarmUsers();
        }

        this.setState({
            data: role === 'Admin' ? response.data.docs : response.data,
            pagination: {
                ...this.state.pagination,
                total: role === 'Admin' ? response.data.totalDocs : response.data,
                currentPage: response.data.page,
                perPage: 10
            },
            loading: false,
            lastFilters: filters,
        });
    }

    onSuccess = () => { // When new live stock add this function call
      this.asyncBoot();
    }


    reloadTable = () => {
        const page = this.state.pagination.currentPage;
        const filters = { ...this.state.lastFilters };
        this.asyncBoot(page, filters);
    }

    applyFilter = (filters) => {
        if (filters.signup_date) {
            filters.signup_date = filters.signup_date.format('YYYY-MM-DD');
        }
        if (filters.login_date) {
            filters.login_date = filters.login_date.format('YYYY-MM-DD');
        }
        this.asyncBoot(1, filters);
    }


    onPaginationChange = (page, perPage) => {
        this.asyncBoot(page, this.state.lastFilters);
    }

    doAction = (e, action, record) => {
        if (e) e.preventDefault();
        const _this = this;
        switch (action) {
            default: break;
            case "send_password_link":
                notification.success({
                    message: 'Resend password link',
                    description: 'We have mailed the password reset link'
                });
                forgotPassword({ email: record.email }).then(() => {
                    // who cares?
                }).catch(() => {
                    // who cares?
                });
                break;
            case "import_live_stocks":
                this.importLiveStock.OpenModel(record);
                break;    
            case "live_stock_limit":
                    this.limitModel.openLimitModal(record);
                break;    
            case "delete_user":
                let content = `with name ${record.firstName} ${record.lastName} and email ${record.email}`;
                confirm({
                    title: 'Are you sure delete this user?',
                    content: content,
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        _this.setState({ loading: true });
                        admin.DeleteUser(record.id).then(() => {
                            notification.success({
                                message: 'Delete User',
                                description: 'User has been deleted successfully!'
                            });
                            _this.asyncBoot();
                        }).catch(() => {
                            // who cares?
                        }).finally(() => {

                        });
                    }
                });
                break;
            case "sign_in":
                viewAs(record.user_id)
                    .then(response => {
                        if (response.status && response.status !== 200) {
                            alert('Error on "View As" service');
                            return;
                        }
                        _this.props.authSignin(response.token, response.user, false, true);
                        _this.props.history.push('/app/dashboard');
                    })
                    .catch(err => {
                        alert('Error on "View As" service');
                    })
                    .finally(() => { });
                break;
            case "change_credentials":
                this.changeCredentials.CredentialsModalOpen({
                    id: record.user_id,
                    email: record.email,
                });
                break;
            case "additional_lo":
                this.AdditionalLO.AdditionalModalOpen({
                    id: record.user_id,
                    num_of_lo: record.num_of_lo,
                });
                break;    

        }
    }

    render() {

        const { user } = this.props.auth;

        const { data } = this.state;

        const tableProps = {
            size: 'middle',
            columns: this.columns,
            rowKey: 'id',
            dataSource: data,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: this.state.loading,
             scroll: { x: 100 },
        };

        return (
            <Content>
                <NewUser wrappedComponentRef={c => this.newUser = c} onSuccess={this.onSuccess}/>
                <ImportLiveStock wrappedComponentRef={c => this.importLiveStock = c} onSuccess={this.onSuccess}/>
                <StockLimitModal wrappedComponentRef={c => this.limitModel = c} onSuccess={this.onSuccess}/>
                <ChangeCredentialsComponent wrappedComponentRef={(data) => this.changeCredentials = data} />
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                    <Header
                        icon="edit"
                        title="My Staff"
                        right={(
                            <Button type='primary' onClick={() => this.newUser.open()}>CREATE NEW</Button>
                        )}
                    />
                </Col>
                <Col span={24}>
                        <Card bordered={false}>
                            <Table {...tableProps} />
                        </Card>
                    </Col>
                </Row>

            </Content>
        );

    }

}

export default connect(
    (state) => {
        return {
            auth: state.auth
        }
    },
    actions,
)(Dashboard);
