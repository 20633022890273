import React, { Component } from 'react';

import { Row, Col, Card, Button, Form, notification, Skeleton, Icon, Descriptions, Tag, Avatar  } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';

// Farm Project Import Services
import categories from '../../services/categories';
import liveStock from '../../services/liveStock';
import moment from 'moment-timezone';
// Farm Project Import Services

import { Header, EditLivestock } from '../../components';

import icUser from '../../assets/images/ic-user.svg';

import { getBase64, applyViewAs } from '../../utils';


//const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

//const { TextArea } = Input;
//const STATES = getStates();

// Farm Project Properties


// Farm Project Properties

const ButtonGroup = Button.Group;


class AnimalForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            LiveStockId: null,
            loading: true,
            saving: false,
            imageBase64: icUser,
            AnimalData: null,
            categories: [],
            animal_breed_category: [],
            AnimalIndex: 0,
            AnimalsIds: props.auth.AnimalsIds
        };
    }

    

    componentDidMount() {
        let Id = this.state.AnimalsIds[0];
        this.setState({LiveStockId: Id});
        this.GetAnimalCategory();
        this.GetLiveStockById(Id);
    }

    onSuccess = () => { // When Live Stock Edit this function refresh data again
        this.setState({loading: true});
        this.GetLiveStockById(this.state.LiveStockId);
    }

    onCategoryChange = (Id) => {
        categories.GetAnimalBreedCategory({id: Id})
        .then(resp => this.setState({animal_breed_category: resp.docs}));
        const { setFieldsValue } = this.props.form;
        setFieldsValue({breedName: null});
    }

    DateFormat(date){
        return date ? moment(date).format('ll') : "";
    }

    GetLiveStockById = async (Id) => {
        let response = await liveStock.GetLiveStockById(Id);
        this.setState({AnimalData: response, loading: false});

    }

    GetAnimalCategory(){
        categories.animal_category()
        .then(resp => this.setState({categories: resp}));
    }

    onCategoryChange = (Id) => {
        categories.GetAnimalBreedCategory({id: Id})
        .then(resp => this.setState({animal_breed_category: resp}));
    }


    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };


    userFileChange = (input) => {
        if (input.files && input.files[0]) {
            const { setFieldsValue } = this.props.form;
            setFieldsValue({ picture: input.files[0] });
            getBase64(input.files[0]).then(imageBase64 => {
                this.setState({ imageBase64 });
            }).catch(() => { });
        }
    }


    save = () => {

        const { validateFields, getFieldValue } = this.props.form;     
        
        validateFields((err, values) => {
            if (err) return true;        

            this.setState({ saving: true });
            let Id = getFieldValue('_id');
            liveStock.UpdateLiveStockById(values,Id)
                .then(resp => {
                    notification.success({
                        message: 'Live Stock',
                        description: resp.message
                    });
            
                })
                .catch(error => {
                    notification.error({
                        message: 'Live Stock',
                        description: 'Request fail please contact your admin'
                    });
                })
                .finally(() => {
                    this.setState({ saving: false });
                });

        });
    }

    onChangeTabs = (data) => {
    
    }

    OpenLiveStockModel = (tabId) => {
        this.EditLiveStockModel.OpenModel(this.state.AnimalData, tabId);
    }


    ReproductionFormFields = (Data) => {
        const type = Data ? Data.sex : null; 
        if(type === 'Male') {
            return(
                <Descriptions title="Reproduction" column={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}> 
                    <Descriptions.Item label="Bred to">{Data ? Data.reproduction.breto : ''}</Descriptions.Item>
                    <Descriptions.Item label="Mating ability">{Data ? Data.reproduction.matingAbility : ''}</Descriptions.Item>
                    <Descriptions.Item label="Fertility">{Data ? Data.reproduction.fertility : ''}</Descriptions.Item>
                </Descriptions>
            );
        }
        else {
            return (
                <Descriptions title="Reproduction" column={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}> 
                    <Descriptions.Item label="Pregnancy Number">{Data ? Data.reproduction.pregnancyNumber : ''}</Descriptions.Item>
                    <Descriptions.Item label="Last Kiding Date">{Data ? this.DateFormat(Data.reproduction.lastKidingOnDate) : ''}</Descriptions.Item>
                    <Descriptions.Item label="Current Conception Date">{Data ? this.DateFormat(Data.reproduction.currentConceptionDate) : ''}</Descriptions.Item>
                    <Descriptions.Item label="Kidding Date">{Data ? this.DateFormat(Data.reproduction.kiddingDate) : ''}</Descriptions.Item>
                    <Descriptions.Item label="Age of Joining">{Data ? this.DateFormat(Data.reproduction.ageOfJoining) : ''}</Descriptions.Item>
                    <Descriptions.Item label="Production record">{Data ? Data.reproduction.productionRecord : ''}</Descriptions.Item>
                    <Descriptions.Item label="Total kids Scanned">{Data ? Data.reproduction.totalKidsScanned : ''}</Descriptions.Item>
                    <Descriptions.Item label="Scan dry">{Data ? Data.reproduction.scanDry : ''}</Descriptions.Item>
                    <Descriptions.Item label="Last still birth/abortion">{Data ? Data.reproduction.lastStillBirth : ''}</Descriptions.Item>
                    <Descriptions.Item label="Number of kids Born Alive">{Data ? Data.reproduction.numberOfKidsBornAlive : ''}</Descriptions.Item>
                    <Descriptions.Item label="Number of Kids weaned">{Data ? Data.reproduction.numberOfKidsWeaned : ''}</Descriptions.Item>
                    <Descriptions.Item label="Fostering kid(s)">{Data ? Data.reproduction.fosteringKid : ''}</Descriptions.Item>
                </Descriptions>
            ); 
        }
    }

    returnBack() {
        const URL = window.location.origin + '/app/dashboard';
        window.open(URL, '_self');
    }

    PreviousAnimals = () => {
        let Index = this.state.AnimalIndex - 1;
        this.loadAnimals(Index);
    }

    NextAnimals = () => {
        let Index = this.state.AnimalIndex + 1;
        this.loadAnimals(Index);
    }


    loadAnimals = (Index) => {
        let liveStockId = this.state.AnimalsIds[Index];
        this.setState({AnimalIndex: Index, liveStockId: liveStockId, loading: true});
        this.GetLiveStockById(liveStockId);
    }



    render() {

        const {loading, AnimalIndex, AnimalsIds} = this.state;
        
        const totalAnimals = AnimalsIds.length;

        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('_id', { initialValue: null });
        getFieldDecorator('picture', { initialValue: null });

        const Data =  this.state.AnimalData;
        const Location = Data ? Data.farm.locations.filter(data => data._id === Data.location_id)[0]['locationName'] : '-';
        const PenName = Data && Data.pen_id !== null ? Data.farm.pens.filter(data => data._id === Data.pen_id)[0]['penName'] : '-';
        const Bunks = Data ? Data.farm.bunks.filter(data => data._id === Data.bunk_id)[0] : [];

        const size = { xs: 24, sm: 24, md: 24, lg: 24 };
        const gridStyle = {width: '100%', margin: '5px', padding: '24px'};


        return (
            <Row gutter={[24, 24]}>
                <EditLivestock wrappedComponentRef={(data) => this.EditLiveStockModel = data} onSuccess={this.onSuccess} />
                <Col span={24}>
                    <Header icon="edit" title={'Animal Information ' + (AnimalIndex + 1)  + '-' + totalAnimals}
                    right={(
                        <> 
                            <ButtonGroup>
                                <Button onClick={this.returnBack}>Back</Button>
                                <Button disabled={AnimalIndex === 0 ? true : false} onClick={this.PreviousAnimals}> Previous</Button>
                                <Button disabled={(AnimalIndex + 1) === totalAnimals ? true : false} onClick={this.NextAnimals}>Next</Button>
                            </ButtonGroup>
                        </>
                    )}/>
                </Col>
                <Col span={24}>
                    <Card bordered={false} className='full-height-livestock'>
                        <Row gutter={24}>
                        <Col span={24} className='mb-20'>
                            <div style={{ position: 'relative', width: '122px' }}>
                                <Avatar size={122} src={Data && Data.picture !== null ? Data.picture :icUser} icon='user' />
                            </div>
                        </Col>
                        <Col {...size}>
                                <Skeleton loading={loading} active>
                                    <Card.Grid style={gridStyle}>
                                    <a className='text-right' onClick={() => this.OpenLiveStockModel('3')}> <Icon type="edit" /> Edit</a>
                                        <Descriptions title="Identification" column={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}>
                                            <Descriptions.Item label="Reference ID">{Data ? Data.identification.rfID : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Dam ID">{Data ? Data.identification.damID : ''}</Descriptions.Item>
                                            <Descriptions.Item label="E ID">{Data ? Data.identification.eID : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Name">{Data ? Data.identification.name : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Old ID">{Data ? Data.identification.oldID : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Sire ID">{Data ? Data.identification.sireID : ''}</Descriptions.Item>
                                        </Descriptions>
                                    </Card.Grid>
                                </Skeleton>    
                            </Col>
                            <Col {...size}>
                                <Skeleton loading={loading} active>
                                    <Card.Grid style={gridStyle}>
                                        <a className='text-right' onClick={() => this.OpenLiveStockModel('1')}> <Icon type="edit" /> Edit</a>
                                        <Descriptions title="Life Data" column={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}>
                                            <Descriptions.Item label="Farm">{Data ? Data.farm.name : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Location">{Location}</Descriptions.Item>
                                            <Descriptions.Item label="Pen">{PenName}</Descriptions.Item>
                                            <Descriptions.Item label="Bunk">{Bunks ? Bunks.bunkName : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Category">{Data ? Data.categoryName : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Breed">{Data ? Data.breedName : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Date Of Birth">{Data ? this.DateFormat(Data.dob): ''}</Descriptions.Item>
                                            <Descriptions.Item label="Sex">{Data ? Data.sex : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Oirgin">{Data ? Data.origin : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Status">{Data ? Data.status : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Groups">{Data ? Data.group.map(data => data.name + ', ') : ''}</Descriptions.Item>
                                        </Descriptions>
                                    </Card.Grid>
                                </Skeleton>
                            </Col>
                            <Col {...size}>
                                <Skeleton loading={loading} active>
                                    <Card.Grid style={gridStyle}>
                                    <a className='text-right' onClick={() => this.OpenLiveStockModel('2')}> <Icon type="edit" /> Edit</a>
                                        <Descriptions title="Weight" column={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}>
                                            <Descriptions.Item label="Initial Weightkg">{Data ? Data.weight.initialWeightkg : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Initial Weight Date">{Data ? this.DateFormat(Data.weight.initialWeightDate) : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Current Weight Kg">{Data ? Data.weight.currentWeightkg : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Current Weight Date">{Data ? this.DateFormat(Data.weight.currentWeightDate) : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Condition Score">{Data ? Data.weight.conditionScore : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Condition Score Date">{Data ? this.DateFormat(Data.weight.conditionScoreDate): ''}</Descriptions.Item>
                                        </Descriptions>
                                    </Card.Grid>
                                </Skeleton>    
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col {...size}>
                                <Skeleton loading={loading} active>
                                    <Card.Grid style={gridStyle}>
                                    <a className='text-right' onClick={() => this.OpenLiveStockModel('4')}> <Icon type="edit" /> Edit</a>
                                        <Descriptions title="Production" column={{ xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}>
                                            <Descriptions.Item label="lactation Number">{Data ? Data.production.lactationNumber : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Current Lactation Stage">{Data ? Data.production.currentLactationStage : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Animal Lactation cycle">{Data ? Data.production.lactationCycle : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Fat">{Data ? Data.production.fat: ''}</Descriptions.Item>
                                            <Descriptions.Item label="Protien">{Data ? Data.production.protien :''}</Descriptions.Item>
                                            <Descriptions.Item label="Carcass weight">{Data ? Data.production.carcassWeight : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Udder/teat Score">{Data ? Data.production.teatScore : ''}</Descriptions.Item>                              
                                            <Descriptions.Item label="Problem Group">{Data ? Data.problemGroup.map(data => data + ', ' ) : '-'}</Descriptions.Item> 

                                            {Data && Data.sex === "Female" ?  <Descriptions.Item label="Total Milk">{Data ? Data.production.totalMilk : ''}</Descriptions.Item> : ''}
                                            {Data && Data.sex === "Female" ?  <Descriptions.Item label="Milking Date">{Data ? this.DateFormat(Data.production.milkingDate) : ''}</Descriptions.Item> : ''}
                                            {Data && Data.sex === "Female" ?  <Descriptions.Item label="Milking AM">{Data ? Data.production.milkAM : ''}</Descriptions.Item>  : ''}
                                            {Data && Data.sex === "Female" ?  <Descriptions.Item label="Milking PM">{Data ? Data.production.milkPM : ''}</Descriptions.Item> : ''}
                                            {Data && Data.sex === "Female" ?  <Descriptions.Item label="Milk Solids">{Data ? Data.production.milkSolids : ''}</Descriptions.Item> : ''}                                            
                                        </Descriptions>
                                    </Card.Grid>
                                </Skeleton>    
                            </Col>
                            <Col {...size}>
                                <Skeleton loading={loading} active>
                                    <Card.Grid style={gridStyle}>
                                    <a className='text-right' onClick={() => this.OpenLiveStockModel('5')}> <Icon type="edit" /> Edit</a>   
                                        {this.ReproductionFormFields(Data)}
                                    </Card.Grid>
                                </Skeleton>    
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col {...size}>
                                <Skeleton loading={loading} active>
                                    <Card.Grid style={gridStyle}>
                                    <a className='text-right' onClick={() => this.OpenLiveStockModel('6')}> <Icon type="edit" /> Edit</a>
                                        <Descriptions title="Notes" column={{ xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}>
                                            <Descriptions.Item label="Date">{Data ? this.DateFormat(Data.notes.date) : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Details">{Data ? Data.notes.details : ''}</Descriptions.Item>
                                        </Descriptions>
                                    </Card.Grid>
                                </Skeleton>    
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col {...size}>
                                <Skeleton loading={loading} active>
                                    <Card.Grid style={gridStyle}>
                                    <a className='text-right' onClick={() => this.OpenLiveStockModel('7')}> <Icon type="edit" /> Edit</a>
                                        <Descriptions title="Treatment" column={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}>
                                            <Descriptions.Item label="Vaccine Treatment">{Data ? Data.treatment.VaccineTreatment : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Affecting Area">{Data ? Data.treatment.affectingArea : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Date Seperated">{Data ? this.DateFormat(Data.treatment.dateSeperated) : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Does given">{Data ? Data.treatment.doesgiven : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Meat With Holding">{Data ? Data.treatment.meatWithHolding : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Milk With Holding">{Data ? Data.treatment.milkWithholding : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Return Vet Date">{Data ? this.DateFormat(Data.treatment.returnVetDate ) : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Treatment Date">{Data ? this.DateFormat(Data.treatment.treatmentDate) : ''}</Descriptions.Item>
                                            <Descriptions.Item label="Treatment Days">{Data ? Data.treatment.treatmentDays : ''}</Descriptions.Item>
                                        </Descriptions>
                                    </Card.Grid>
                                </Skeleton>
                            </Col>
                        </Row>

                    </Card>
                </Col>
            </Row>
        );
    }

}


const FormProfile = Form.create()(AnimalForm);

export default connect(
    (state) => {
        return {
            auth: state.auth,
            viewingAs: applyViewAs(state.auth)
        }
    },
    actions
)(FormProfile);
