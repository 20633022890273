import React, { Component } from 'react';
import { Row, Col, Card, Layout, Button, Avatar, Table, notification, message, Menu, Popconfirm, Icon, Modal, Tag  } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Header, EditSubscriptionModal } from '../../components';

//import usrService from '../../services/users';
import admin from '../../services/admin';
import { forgotPassword, viewAs } from '../../services/auth';

import * as actions from '../../actions/auth'

import icUser from '../../assets/images/ic-user.svg';

const { Content } = Layout;
const { confirm } = Modal;
//const { Option } = Select;

class SubscriptionRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
            loading: false,
            type: 'rea',
            lastFilters: {}
        };

        this.menu = (record) => {

            return (
                <Menu onClick={(item) => this.doAction(null, item.key, record)}>
                    <Menu.Item key="import_live_stocks">
                         Import Live Stocks
                    </Menu.Item>
                    <Menu.Item key="live_stock_limit">
                         Live Stock Limit
                    </Menu.Item>
                    <Menu.Item key="delete_user">
                        Delete
                    </Menu.Item>
                </Menu>
            )
        }

        this.columns = [
            {
                title: 'Full Name',
                key: 'firstName',
                dataIndex: 'firstName',
                width: 200,
                render: (text, record) => {
                    return (
                        <div>
                            <Avatar size={32} src={record.picture ? record.picture : icUser} />
                            <span>&nbsp;&nbsp;{record.firstName} {record.lastName}</span>
                        </div>
                    )
                }
            },
            
            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                width: 150
            },
            {
                title: 'Phone',
                key: 'phone',
                dataIndex: 'phone',
                width: 150
            },
            {
                title: 'Address',
                key: 'address',
                width: 200,
                render: (data) => (<div>{data.town + ' ' + data.state + ' ' + data.country}</div>)
            },
            {
                title: 'Limit',
                key: 'limit',
                width: 80,
                render: (data) => (<div>{data.subscription.limit}</div>)
            },
            {
                title: 'Plan',
                key: 'plan',
                dataIndex: 'plan',
                width: 150,
                render: (text, data) => {
                    let color = '#87d068';
                    let plan = data.subscription.plan;
                    if(plan === 'Basic') {
                        color = '#03a9f4';
                    }
                    else if(plan == 'Standard') {
                        color = '#e91e63';
                    }
                    else if(plan === 'Premium') {
                        color = '#ff9800';
                    }
                    else if(plan === 'Custom Plan') {
                        color = '#4caf50';
                    }
                    return (
                        <div>
                            <Tag color={color}>{plan}</Tag>
                        </div>
                    )

                }
            },
            {
                title: 'cost',
                key: 'cost',
                width: 60,
                render: (data) => (<div>${data.subscription.cost}</div>)
            },
            {
                title: 'Payment',
                key: 'payment',
                width: 150,
                render: (data) => (<div><Tag color={data.subscription.payment_status ? 'green' : 'red'}>{data.subscription.payment_status ? 'Completed' : 'Incompleted'}</Tag></div>)
            },
            {
                title: 'Activation',
                key: 'activation',
                width: 120,
                render: (data) => moment(data.subscription.activation_date).format('ll'),
            },
            {
                title: 'Expire',
                key: 'expire',
                width: 120,
                render: (data) =>  moment(data.subscription.expire_date).format('ll'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 100,
                fixed: 'right',
                render: (data) =>  {
                    return (
                        <div>
                            <Button onClick={() => this.subscriptionModal.OpenModel(data.subscription)} type="primary">Edit</Button>        
                        </div>
                    );
                },
            }
        ];
    }

    componentDidMount() {
      this.asyncBoot();
    }

    asyncBoot = async (page = 1, filters = {}) => {
        const { auth } = this.props;
        const { user } = auth;
        const role = user.role;
        const params = {
            page: page,
            ...filters
        }
        this.setState({ loading: true });
        let response = await admin.GetSubscriptionRequest();
        this.setState({
            data: response.data,
            pagination: {
                ...this.state.pagination,
                total: response.data,
                currentPage: response.data.page,
                perPage: 10
            },
            loading: false,
            lastFilters: filters,
        });
    }

    onSuccess = () => { // When new live stock add this function call
      this.asyncBoot();
    }

    async confirmSubscription(data){
        this.setState({ loading: true });
        let response = await admin.ConfirmSubscription(data.subscription);
        if(response.success) {
            notification.success({
                message: 'Subscription Plan',
                description: response.message,
            });
        }
        this.asyncBoot();
    }

    cancelSubscription() {
        message.info('Request cancel');
    }

    reloadTable = () => {
        const page = this.state.pagination.currentPage;
        const filters = { ...this.state.lastFilters };
        this.asyncBoot(page, filters);
    }

    onPaginationChange = (page, perPage) => {
        this.asyncBoot(page, this.state.lastFilters);
    }



    render() {

        const { user } = this.props.auth;

        const { data } = this.state;

        const tableProps = {
            size: 'middle',
            columns: this.columns,
            rowKey: 'id',
            dataSource: data,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: this.state.loading,
             scroll: { x: 800 },
        };

        return (
            <Content>
                 <EditSubscriptionModal wrappedComponentRef={c => this.subscriptionModal = c} onSuccess={this.onSuccess} />
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                    <Header
                        icon="edit"
                        title="Subscription Request"
                    />
                </Col>
                <Col span={24}>
                        <Card bordered={false}>
                            <Table {...tableProps}/>
                        </Card>
                    </Col>
                </Row>

            </Content>
        );

    }

}

export default connect(
    (state) => {
        return {
            auth: state.auth
        }
    },
    actions,
)(SubscriptionRequest);
