import React, { Component } from 'react';
import { Row, Col, Card, Table, Button } from 'antd';
import { Header, StartMilking } from '../../components';
import moment from 'moment-timezone';

import MilkingService from '../../services/Milking-Service';

class Milking extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            categoriesList: [],
            selectedCategoriesID: null,
            loading: false,
            SearchQuery: '',
            baseColumns: [],
            pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
            },
        };
    }

    SearchHandler = (event) => {
        let query = event.target.value;
        this.setState({SearchQuery: query});
        if(event.key === 'Enter') {
            this.asyncBoot(1, {keyword: query});
        }

        if(query.length === 0) {
            this.asyncBoot();
        }
    }


    componentDidMount() {
        this.asyncBoot();
    }


    asyncBoot = async () => {
        this.setState({loading: true});
        let Data = await MilkingService.GetAllMilkingGroup();
        let MilkingDates = [];
        Data.data.map(row => MilkingDates.push({text: moment(row.created).format('ll'), value: row.created}));
        this.GenerateTableColumns(MilkingDates);
        this.setState({loading: false, data: Data.data});
    }

    GenerateTableColumns = async (MilkingDates) => {
        let Columns = [
            {
                title: 'Title',
                key: 'title',
                width: 250,
                render: (data) => {
                    const URL = window.location.origin + '/app/animal-milking/' + data._id;
                    return(<> <a href={URL}>{data.title}</a> </>);
                }
            },
            {
                title: 'Group Name',
                key: 'groupName',
                dataIndex: 'groupName',
                width: 200,

            },
            {
                title: 'Number Of Animals',
                key: 'animals',
                dataIndex: 'animals',
                width: 150,
                render: (data) => data.length
            },
            {
                title: 'Created At',
                key: 'created',
                dataIndex: 'created',
                width: 100,
                filters: MilkingDates,
                onFilter: (value, record) => record.created.indexOf(value) === 0,
                render: (value) => moment(value).format('ll'),
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                width: 100,
                filters: [
                    {text: 'Completed', value: 'Completed'},
                    {text: 'In Progress', value: 'In Progress'},
                ],
                onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
            {
                title: 'Action',
                key: 'action',
                width: 100,
                render: (data) => {
                    console.log(data);
                    if(data.status !== 'Completed') 
                    return (<a key={data._id} onClick={ () => this.milkingModal.OpenModel(data, true)}>Edit</a>)
                }
            },
        ];

        this.setState({baseColumns: Columns});
    }

    onPaginationChange = (page) => {
        this.asyncBoot(page, {keyword: this.state.SearchQuery});
    }

    onSuccess = () => {
        this.asyncBoot();
    }

    render() {

        const { data, loading, saving, baseColumns } = this.state;

        const tableProps = {
            size: 'middle',
            columns: baseColumns,
            rowKey: 'id',
            dataSource: data,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: this.state.loading,
            scroll: { x: 1000 },
        };

        return (
            <Row gutter={[24, 24]}>
                <StartMilking wrappedComponentRef={c => this.milkingModal = c} onSuccess={this.onSuccess} />
                <Col span={24}>
                    <Header
                        icon="people"
                        title="Milking"
                        right={(
                            <Button disabled={loading || saving} type='primary' onClick={() => this.milkingModal.OpenModel(null, false)}>Start Milking</Button>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Card bordered={false}>    
                        <Table {...tableProps} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Milking