import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Input, notification, Spin } from 'antd';
import { connect } from 'react-redux';
import usersService from '../../services/users';

class ChangeCredentialsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            CredentialsModal: false,
            parents: [],
            user: {}
        };
    }

    CredentialsModalOpen = (user) => {
        this.setState({ CredentialsModal: true });
        if (user) {
            this.setState({ user });
            const { setFieldsValue } = this.props.form;
            setFieldsValue({
                id: user.id,
                email: user.email
            });
        }
    }

    CredentialsModalClosed = () => {
        this.setState({ CredentialsModal: false });
    }

    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        //const { user } = this.state;
        const { validateFields } = this.props.form;
        validateFields((err, values) => {
            if (err) return;
            
            this.setState({ loading: true });
            usersService.ChangeUserCredentials(values).then(resp => {
                notification.success({
                    message: 'Credentials',
                    description: resp.message
                });
                this.setState({ loading: false }, this.CredentialsModalClosed);
                this.props.form.resetFields();
            }).catch((error) => {
                notification.error({
                    message: 'Credentials',
                    description: 'Service Unavailable'
                });
                this.setState({ loading: false });
            });

        });
    }

    render() {

        const { loading, CredentialsModal } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: CredentialsModal,
            title: null,
            footer: null,
            onCancel: this.CredentialsModalClosed,
            width: '600px'
        };

        getFieldDecorator('id', { initialValue: undefined });

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '50px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>Change Credentials</span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label="Email">
                                {getFieldDecorator("email", {
                                    rules: [{ required: true, message: `Email is required!` }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>    
                    <Row gutter={24}>    
                        <Col span={24}>
                            <Form.Item label="Password">
                                {getFieldDecorator("password")(
                                    <Input.Password  />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Save</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _ChangeCredentialsComponent = Form.create()(ChangeCredentialsComponent);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_ChangeCredentialsComponent);
