const initialState = {
    selectedAnimals: false,
    data: {}
};

export default (state = initialState, action) => {

    switch (action.type) {
        case 'SELECTED_ANIMALS':

            return {
                ...state,
                data: action.data,
                selectedAnimals: action.selectedAnimals
            }

        default:
            return state;
    }
}