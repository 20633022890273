import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';

const styles = {
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '10px',
        borderRadius: '12px',
        backgroundColor: '#0091ff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    i: {
        color: '#fff',
        fontSize: '16px',
        // marginRight: '3px',
        // marginBottom: '2px',
    }
}

class Header extends Component {

    static defaultProps = {
        icon: '',
        title: '',
        right: (<div></div>),
    };

    constructor(props) {
        super();
    }

    render() {
        const size = { xs: 24, sm: 24, md: 12, lg: 12 };
        return (
            <Row>
                <Col span={24}>
                    <Card bordered={false}>
                        <Row>
                            <Col {...size}>
                                <div style={styles.row}>
                                    <div style={styles.icon}>
                                        <i className="material-icons-outlined" style={styles.i}>{this.props.icon}</i>
                                    </div>
                                    <h1 className='h1'>{this.props.title}</h1>
                                </div>
                            </Col>
                            <Col {...size} className='textRight'>
                                {this.props.right}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    }

}

export default Header;