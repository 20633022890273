import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
// import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers/index';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

// import history from './history';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['details'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {

    let store = createStore(
        persistedReducer,
        applyMiddleware(
            thunk,
            logger,
            // routerMiddleware(history)
        )
    );

    let persistor = persistStore(store);

    return { store, persistor };

}

export default configureStore;