import states from "./states";
import countries from "./countries";
import { message } from 'antd';

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


const getStates = () => states;
const getCountries = () => countries;

const getAnimalRank = () => {
    return [
        { name: "First time" },
        { name: "Second time" },
        { name: "Thrid time" },
        { name: "Fourth time" },
        { name: "Fifth time" },
        { name: "Sixth time" },
        { name: "Seventh time" },
        { name: "Eighth time" },
        { name: "Ninth time" },
        { name: "Tenth time" },
        { name: "Eleventh time" },
        { name: "Twelfth time" },
    ]
};

const getWeightScore = () => {
    return ['1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5'];
}


const createYouTubeEmbedLink = (link) => {
    return link.replace("youtube.com/watch?v=", "youtube.com/embed/");
}

const copyToClipboard = str => {
    const el = document.createElement('textarea'); // Create a <textarea> element
    el.value = str; // Set its value to the string that you want copied
    el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px'; // Move outside the screen to make it invisible
    document.body.appendChild(el); // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0 // Check if there is any content selected previously
        ?
        document.getSelection().getRangeAt(0) // Store selection if found
        :
        false; // Mark as false to know no selection existed before
    el.select(); // Select the <textarea> content
    document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el); // Remove the <textarea> element
    if (selected) { // If a selection existed before copying
        document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
        document.getSelection().addRange(selected); // Restore the original selection
    }
    message.success('Link copied to clipboard!');
};

function leftpad(str, len, ch) {
    str = String(str);
    var i = -1;
    if (!ch && ch !== 0) ch = ' ';
    len = len - str.length;
    while (++i < len) {
        str = ch + str;
    }
    return str;
}

function getRandom(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function debounce(fn, delay) {
    let timerId;
    return function(...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = null;
        }, delay);
    }
}




function shareOnFB(url) {
    url = url + '?track=facebook';
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'facebook-share-dialog', "width=626, height=436");
}

function shareOnInstagram(url) {
    window.open('https://www.instagram.com/?url' + url, 'instagram-share-dialog', "width=626, height=436");
}

function shareOnTwitter(url) {
    window.open('https://twitter.com/intent/tweet?text=' + url, 'twitter-share-dialog', "width=626, height=436");
}

function shareOnPinterest(url) {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'pinterest-share-dialog', "width=626, height=436");
}

function shareOnLinkedin(url) {
    window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + url, 'linkedin-share-dialog', "width=626, height=436");
}

function formatCurrency(number) {
    number = parseFloat(number);
    return (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

function ucwords(str) {
    let lower = str.toLowerCase();
    return lower.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

function applyViewAs(auth) {
    let apply = false;
    const { root } = auth;
    if (
        root !== undefined &&
        root.user.role.name !== 'admin'
    ) {
        apply = true;
    }
    return apply;
}

function isEmptyObject(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

export {
    getBase64,
    getStates,
    getCountries,
    getAnimalRank,
    getWeightScore,
    createYouTubeEmbedLink,
    copyToClipboard,
    getRandom,
    debounce,
    shareOnFB,
    shareOnInstagram,
    shareOnPinterest,
    shareOnTwitter,
    shareOnLinkedin,
    formatCurrency,
    leftpad,
    ucwords,
    applyViewAs,
    isEmptyObject
}