import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Form, Icon, Input, Button, notification } from 'antd';
import '../auth/index.css';
import users from '../../services/users';
import * as actions from '../../actions/auth';
import logo from '../../assets/images/logo.png';
import ReCAPTCHA from "react-google-recaptcha";


class Signup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fetching: false,
		};
	}

	componentDidMount() {

	}

	SignupSuccessfull(message){
		notification.success({ message: 'Signup', description: message});
	}

	SignupFail(message){
		notification.error({ message: 'Signup', description: message});
	}

	onChangeGoogleCaptcha(value) {
		
	}

	handleSubmit = (e) => {

	 //	const { authSignin } = this.props;

		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {


				// check passwords
				if (values.password !== values.confirmPassword) {
					return notification.warning({ message: 'Create Users', description: 'Password does not match'});
				}

				let formData = values;
				formData.acceptTerms = true;
				this.setState({ fetching: true });
				users.RegisterUser(formData)
					.then(response => {
						if(response.data.success) {
							this.SignupSuccessfull(response.data.message);
							this.props.form.resetFields();
							return (<Redirect to='app/dashboard' />);
						}
						else {
							this.SignupFail(response.message);	
						}
							
					})
					.catch(error => {
						this.SignupFail('Signup fail please try again later');
					})
					.finally(() => {
						this.setState({ fetching: false });
					});

			}
		});

	}

	render() {

		const { fetching } = this.state;
		const { history } = this.props;

		const { getFieldDecorator } = this.props.form;
		const { auth } = this.props;
		if (auth.authenticated)
			return (<Redirect to='app/dashboard' />);

		return (
			<div
				className="loginContainer"
			>
				<div className="y">

					<Form onSubmit={this.handleSubmit} className="login-form z">

					<div className='text-center'>
							<img src={logo} alt="Farm logo" height={120}/>
						</div>

						<div className="intro">
							<h1 className='text-center'>SignUp to Farm Application</h1>
						</div>
                        <Form.Item>
                            {getFieldDecorator('firstName', {
                                        rules: [{ required: true, message: 'First Name is required' }],
                                })(
                                    <Input prefix={<Icon type="user"/>}
                                            placeholder="First Name"
                                    />,
                                )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator('lastName', {
                                        rules: [{ required: true, message: 'Last Name is required' }],
                                })(
                                    <Input prefix={<Icon type="user"/>}
                                            placeholder="Last Name"
                                    />,
                                )}
                        </Form.Item>

                        <Form.Item>
							{getFieldDecorator('email', {
								rules: [{ required: true, message: 'Please input your email address!' }],
							})(
								<Input
									prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
									placeholder="Email Address"
								/>,
							)}
						</Form.Item>

						<Form.Item>
							{getFieldDecorator('password', {
								rules: [{ required: true, message: 'Please input your Password!' }],
							})(
								<Input.Password
									prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
									type="password"
									placeholder="Password"
								/>,
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('confirmPassword', {
								rules: [{ required: true, message: 'Please input your confirm Password!' }],
							})(
								<Input.Password
									prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
									type="password"
									placeholder="Confirm Password"
								/>,
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('google_captcha', {
								rules: [{ required: true, message: 'Google captcha is required' }],
							})(
								<ReCAPTCHA sitekey="6LfKlRQgAAAAAEV4Szu0Gnyv2KrfrV8uD0veCq1f" onChange={() => this.onChangeGoogleCaptcha()}/>
							)}
						</Form.Item>
						<Form.Item style={{ marginBottom: 0 }}>
							<Button loading={fetching} type="primary" htmlType="submit" className="login-form-button">
								Signup
          					</Button>
						</Form.Item>
						<div className='text-center'>
							<a onClick={() => { history.push(`/login`) }} className='link'>Login</a>
						</div>
					</Form>

				</div>
				<div style={{
					position: "absolute",
					left: "0",
					right: "0",
					zIndex: "1",
					textAlign: "right",
					padding: "0px 24px",
					fontWeight: "bold",
					color: "#e6f7ff",
				}}>
				</div>
			</div>
		);

	}

}

const _Signup = Form.create()(Signup);

export default connect(
	(state) => {
		return {
			auth: state.auth
		}
	},
	actions
)(_Signup);
