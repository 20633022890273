import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import './App.scss';

import Auth from './pages/auth';
import App from './pages/app';
import Signup from './pages/signup';
import Packages from './pages/signup/packages'
import SendPasswordReset from './pages/passwordReset/send';
import PasswordReset from './pages/passwordReset';
import farmWizard from './pages/farm-wizard';
import EmailVerification from './pages/email-verification';
import configureStore from './configureStore';

const { store, persistor } = configureStore();

class Root extends React.Component {

    render() {
        return ( <
            Provider store = { store } >
            <
            PersistGate loading = { null }
            persistor = { persistor } >
            <
            BrowserRouter >
            <
            Switch >

            <
            Route exact path = "/"
            component = { Auth }
            /> <
            Route exact path = "/signup"
            component = { Signup }
            /> <
            Route exact path = "/packages"
            component = { Packages }
            />  <
            Route exact path = "/farm-wizard"
            component = { farmWizard }
            /> <
            Route exact path = "/password/reset"
            component = { SendPasswordReset }
            /> <
            Route exact path = "/password/reset/:token"
            component = { PasswordReset }
            />
            <Route exact path = "/email/verification/:token" component = { EmailVerification }/>
            <
            Route path = "/app"
            component = { App }
            />

            { /* No Match 404 */ } <
            Route path = "*" >
            <
            Redirect to = '/' / >
            <
            /Route>

            <
            /Switch> < /
            BrowserRouter > <
            /PersistGate> < /
            Provider >
        );
    }

};

export default Root;