import React, { Component } from 'react';
import { Row, Tabs, Col, Card, Table, Button, Menu, Dropdown, notification, Modal, Input, Checkbox, Popover  } from 'antd';
import dashboard from '../../services/dashboard';
import liveStock  from '../../services/liveStock';
import { applyViewAs } from '../../utils';
import './index.css';
import AddNewLiveStock from '../../components/add-live-stock';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import categories from '../../services/categories';
import FarmService from '../../services/Farm-Service';

import * as actions from '../../actions/auth';

import Sheep from '../../assets/images/icon/sheep.png';
import Cow from '../../assets/images/icon/cow.png';
import Goat from '../../assets/images/icon/goat.png';
import Buffalo from '../../assets/images/icon/Buffalo.png';


const { confirm } = Modal;
//const { Content } = Layout;
const { TabPane } = Tabs;

class Dashboard extends Component {

    

    constructor(props) {
        super(props)
        this.state = {
            selectedAnimals: [],
            categoriesList: [],
            loading: false,
            activeTab: 'All',
            SearchQuery: '',
            countGoat: 0,
            countSheep: 0,
            countCow: 0,
            countBuffalo: 0,

            data: [],
            fetching: true,
            filtering: false,
            filterTable: null,

            color: true,
            currentLocation: true,
            lenght: true,
            height: true,
            temprament: true,
            bloodLine: true,

            fertilityStatus: true,
            conditionScore: true,
            conditionScoreDate: true,

            initialWeightkg: true,
            initialWeightDate: true,
            currentWeightkg: true,
            currentWeightDate: true,

            rfID: true,
            sireID: true,
            damID: true,
            name: true,
            eID: true,
            oldID: true,

            productionLastDate: true,
            totalMilk: true,
            currentLactationStage: true,

            lastkidingDate: true,
            currentConceptionDate: true,
            kiddingDate: true,
            
            notesDate: true,
            notesDetails: true,

            treatmentDate: true,
            treatmentStatus: true,
            treatmentCondition: true,

            affectingArea: true,
            VaccineTreatment: true,
            doesgiven: true,
            treatmentDays: true,
            milkWithholding: true,
            meatWithHolding: true,
            dateSeperated: true,
            returnVetDate: true,
        };

    }

    
    baseColumns = () => [
        {
            title: 'Category',
            key: 'categoryName',
            dataIndex: 'categoryName',
            width: 120,
            fixed: 'left',
            sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
        },
        {
            title: 'Breed',
            key: 'breedName',
            dataIndex: 'breedName',
            width: 120,
            fixed: 'left',
            sorter: (a, b) => a.breedName.localeCompare(b.breedName),
        },
        {
            title: 'Farm',
            key: '_id',
            dataIndex: 'farm',
            width: 200,
            render: (data) => {return (data.name)},
            sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
        },
        {
            title: 'Location',
            key: 'location_id',
            width: 120,
            render: (data) => {
                let locationId = data.location_id;
                return data.farm.locations.filter(x => x._id === locationId)[0]['locationName'];
            },
            sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
        },
        {
            title: 'Initial Weight kg',
            key: 'initialWeightkg',
            render: (data) => {
                return (data.weight.initialWeightkg);
            },
            width: 200,
            sorter: (a, b) => a.weight.initialWeightkg.localeCompare(b.weight.initialWeightkg),
            hidden: this.state.initialWeightkg,
        },
        {
            title: 'Initial Weight Date',
            key: 'initialWeightDate',
            render: (data) => {
                return (data.weight.initialWeightDate);
            },
            width: 200,
            sorter: (a, b) => a.weight.initialWeightDate.localeCompare(b.weight.initialWeightDate),
            hidden: this.state.initialWeightDate,
        },
        {
            title: 'Current Weight kg',
            key: 'Current Weight kg',
            render: (data) => {
                return (data.weight.currentWeightkg);
            },
            width: 200,
            sorter: (a, b) => a.weight.currentWeightkg.localeCompare(b.weight.currentWeightkg),
            hidden: this.state.currentWeightkg,
        },
        {
            title: 'Current Weight Date',
            key: 'currentWeightDate',
            render: (data) => {
                return (data.weight.currentWeightDate);
            },
            width: 200,
            sorter: (a, b) => a.weight.currentWeightDate.localeCompare(b.weight.currentWeightDate),
            hidden: this.state.currentWeightDate,
        },
        {
            title: 'Condition Score',
            key: 'conditionScore',
            render: (data) => {
                return (data.weight.conditionScore);
            },
            width: 200,
            sorter: (a, b) => a.weight.conditionScore.localeCompare(b.weight.conditionScore),
            hidden: this.state.conditionScore,

        },
        {
            title: 'condition Score Date',
            key: 'conditionScoreDate',
            render: (data) => {
                return (data.weight.conditionScoreDate);
            },
            width: 200,
            sorter: (a, b) => a.weight.conditionScoreDate.localeCompare(b.weight.conditionScoreDate),
            hidden: this.state.conditionScoreDate,

        },
        {
            title: 'RFID',
            key: 'rfID',
            render: (data) => {
                return (data.identification.rfID);
            },
            width: 200,
            sorter: (a, b) => a.identification.rfID.localeCompare(b.identification.rfID),
            hidden: this.state.rfID,

        },
        {
            title: 'Sire ID',
            key: 'sireID',
            render: (data) => {
                return (data.identification.sireID);
            },
            width: 200,
            sorter: (a, b) => a.identification.sireID.localeCompare(b.identification.sireID),
            hidden: this.state.sireID,

        },
        {
            title: 'Dam ID',
            key: 'damID',
            render: (data) => {
                return (data.identification.damID);
            },
            width: 200,
            sorter: (a, b) => a.identification.damID.localeCompare(b.identification.damID),
            hidden: this.state.damID,

        },
        {
            title: 'Identify Name',
            key: 'name',
            render: (data) => {
                return (data.identification.name);
            },
            width: 200,
            sorter: (a, b) => a.identification.name.localeCompare(b.identification.name),
            hidden: this.state.name,

        },
        {
            title: 'EID',
            key: 'eid',
            render: (data) => {
                return (data.identification.eID);
            },
            width: 200,
            sorter: (a, b) => a.identification.eID.localeCompare(b.identification.eID),
            hidden: this.state.eID,

        },
        {
            title: 'Old ID',
            key: 'oldID',
            render: (data) => {
                return (data.identification.oldID);
            },
            width: 200,
            sorter: (a, b) => a.identification.oldID.localeCompare(b.identification.oldID),
            hidden: this.state.oldID,
        },

        {
            title: 'Production Last Date',
            key: 'lastDate',
            render: (data) => {
                return (data.production.lastDate);
            },
            width: 200,
            sorter: (a, b) => a.production.lastDate.localeCompare(b.production.lastDate),
            hidden: this.state.productionLastDate,
        },

        {
            title: 'Total Milk',
            key: 'totalMilk',
            render: (data) => {
                return (data.production.totalMilk);
            },
            width: 200,
            sorter: (a, b) => a.production.totalMilk.localeCompare(b.production.totalMilk),
            hidden: this.state.totalMilk,
        },

        {
            title: 'Current Lactation Stage',
            key: 'currentLactationStage',
            render: (data) => {
                return (data.production.currentLactationStage);
            },
            width: 200,
            sorter: (a, b) => a.production.currentLactationStage.localeCompare(b.production.currentLactationStage),
            hidden: this.state.currentLactationStage,
        },

        {
            title: 'Last kiding Date',
            key: 'lastkidingDate',
            render: (data) => {
                return (data.reproduction.lastkidingDate);
            },
            width: 200,
            sorter: (a, b) => a.reproduction.lastkidingDate.localeCompare(b.reproduction.lastkidingDate),
            hidden: this.state.lastkidingDate,
        },

        {
            title: 'Current Conception Date',
            key: 'currentConceptionDate',
            render: (data) => {
                return (data.reproduction.currentConceptionDate);
            },
            width: 200,
            sorter: (a, b) => a.reproduction.currentConceptionDate.localeCompare(b.reproduction.currentConceptionDate),
            hidden: this.state.currentConceptionDate,
        },

        {
            title: 'Kidding Date',
            key: 'kiddingDate',
            render: (data) => {
                return (data.reproduction.kiddingDate);
            },
            width: 200,
            sorter: (a, b) => a.reproduction.kiddingDate.localeCompare(b.reproduction.kiddingDate),
            hidden: this.state.kiddingDate,
        },
        {
            title: 'Notes Date',
            key: 'date',
            render: (data) => {
                return (data.notes.date);
            },
            width: 200,
            sorter: (a, b) => a.notes.date.localeCompare(b.notes.date),
            hidden: this.state.notesDate,
        },
        {
            title: 'Notes Details',
            key: 'details',
            render: (data) => {
                return (data.notes.details);
            },
            width: 200,
            sorter: (a, b) => a.notes.details.localeCompare(b.notes.details),
            hidden: this.state.notesDetails,
        },

        {
            title: 'Treatment Date',
            key: 'treatmentDate',
            render: (data) => {
                return (data.treatment.treatmentDate);
            },
            width: 200,
            sorter: (a, b) => a.treatment.treatmentDate.localeCompare(b.treatment.treatmentDate),
            hidden: this.state.treatmentDate,
        },
        {
            title: 'Treatment Status',
            key: 'treatmentStatus',
            render: (data) => {
                return (data.treatment.treatmentStatus);
            },
            width: 200,
            sorter: (a, b) => a.treatment.treatmentStatus.localeCompare(b.treatment.treatmentStatus),
            hidden: this.state.treatmentStatus,
        },
        {
            title: 'Treatment Condition',
            key: 'treatmentCondition',
            render: (data) => {
                return (data.treatment.treatmentCondition);
            },
            width: 200,
            sorter: (a, b) => a.treatment.treatmentCondition.localeCompare(b.treatment.treatmentCondition),
            hidden: this.state.treatmentCondition,
        },

        {
            title: 'Affecting Area',
            key: 'affectingArea',
            render: (data) => {
                return (data.affectingArea);
            },
            width: 200,
            sorter: (a, b) => a.affectingArea.localeCompare(b.affectingArea),
            hidden: this.state.affectingArea,
        },
        {
            title: 'Vaccine Treatment',
            key: 'VaccineTreatment',
            render: (data) => {
                return (data.VaccineTreatment);
            },
            width: 200,
            sorter: (a, b) => a.VaccineTreatment.localeCompare(b.VaccineTreatment),
            hidden: this.state.VaccineTreatment,
        },

        {
            title: 'Does given',
            key: 'doesgiven',
            render: (data) => {
                return (data.doesgiven);
            },
            width: 200,
            sorter: (a, b) => a.doesgiven.localeCompare(b.doesgiven),
            hidden: this.state.doesgiven,
        },
        {
            title: 'Treatment Days',
            key: 'treatmentDays',
            render: (data) => {
                return (data.treatmentDays);
            },
            width: 200,
            sorter: (a, b) => a.treatmentDays.localeCompare(b.treatmentDays),
            hidden: this.state.treatmentDays,
        },

        {
            title: 'Milk With holding',
            key: 'milkWithholding',
            render: (data) => {
                return (data.milkWithholding);
            },
            width: 200,
            sorter: (a, b) => a.milkWithholding.localeCompare(b.milkWithholding),
            hidden: this.state.milkWithholding,
        },

        {
            title: 'Meat With Holding',
            key: 'meatWithHolding',
            render: (data) => {
                return (data.meatWithHolding);
            },
            width: 200,
            sorter: (a, b) => a.meatWithHolding.localeCompare(b.meatWithHolding),
            hidden: this.state.meatWithHolding,
        },

        {
            title: 'Date Seperated',
            key: 'dateSeperated',
            render: (data) => {
                return (data.dateSeperated);
            },
            width: 200,
            sorter: (a, b) => a.dateSeperated.localeCompare(b.dateSeperated),
            hidden: this.state.dateSeperated,
        },

        {
            title: 'Return Vet Date',
            key: 'returnVetDate',
            render: (data) => {
                return (data.returnVetDate);
            },
            width: 200,
            sorter: (a, b) => a.returnVetDate.localeCompare(b.returnVetDate),
            hidden: this.state.returnVetDate,
        },

        {
            title: 'Color',
            key: 'color',
            hidden: this.state.color,
            render: (data) => {
                return (data.color);
            },
            width: 200,
            sorter: (a, b) => a.color.localeCompare(b.color),
        },
        {
            title: 'Current Location',
            key: 'currentLocation',
            hidden: this.state.currentLocation,
            render: (data) => {
                return (data.currentLocation);
            },
            width: 200,
            sorter: (a, b) => a.currentLocation.localeCompare(b.currentLocation),
        },
        {
            title: 'Lenght',
            key: 'lenght',
            hidden: this.state.lenght,
            render: (data) => {
                return (data.lenght);
            },
            width: 200,
            sorter: (a, b) => a.lenght.localeCompare(b.lenght),
        },
        {
            title: 'Height',
            key: 'height',
            hidden: this.state.height,
            render: (data) => {
                return (data.height);
            },
            width: 200,
            sorter: (a, b) => a.height.localeCompare(b.height),
        },
        {
            title: 'Temprament',
            key: 'temprament',
            hidden: this.state.temprament,
            render: (data) => {
                return (data.temprament);
            },
            width: 200,
            sorter: (a, b) => a.temprament.localeCompare(b.temprament),
        },
        {
            title: 'Blood Line',
            key: 'bloodLine',
            hidden: this.state.bloodLine,
            render: (data) => {
                return (data.bloodLine);
            },
            width: 200,
            sorter: (a, b) => a.bloodLine.localeCompare(b.bloodLine),
        },
        {
            title: 'Fertility Status',
            key: 'fertilityStatus',
            hidden: this.state.fertilityStatus,
            render: (data) => {
                return (data.fertilityStatus);
            },
            width: 200,
            sorter: (a, b) => a.fertilityStatus.localeCompare(b.fertilityStatus),
        },
        {
            title: 'Visual ID',
            key: 'visualID',
            render: (data) => {
                return (data.identification.visualID);
            },
            width: 200,
            sorter: (a, b) => a.identification.visualID - b.identification.visualID,
        },
        {
            title: 'Date of Birth',
            key: 'dob',
            dataIndex: 'dob',
            width: 200,
            sorter: (a, b) => { return moment(a.dob).unix() - moment(b.dob).unix()},
            render: (value) => moment(value).format('ll'),
        },
        {
            title: 'Sex',
            key: 'sex',
            dataIndex: 'sex',
            width: 200,
            sorter: (a, b) => a.sex.localeCompare(b.sex),
            filters: [
                {text: 'Male', value: 'Male'},
                {text: 'Female', value: 'Female'},
                {text: 'Other', value: 'Other'},
            ],
            onFilter: (value, record) => {
                return record.sex === value
            },
        },
        {
            title: 'Origin',
            key: 'origin',
            dataIndex: 'origin',
            width: 200,
            sorter: (a, b) => a.origin.localeCompare(b.origin),
            filters: [
                {text: 'On Farm Birth', value: 'On Farm Birth'},
                {text: 'Purchased', value: 'Purchased'},
            ],
            onFilter: (value, record) => {
                return record.origin === value
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status', 
            width: 200,
            sorter: (a, b) => a.status.localeCompare(b.status),
            filters: [
                {text: 'Active', value: 'Active'},
                {text: 'Sold', value: 'Sold'},
                {text: 'Lost', value: 'Lost'},
                {text: 'Deceased', value: 'Deceased'},
                {text: 'Archive', value: 'Archive'}
            ],
            onFilter: (value, record) => {
                return record.status === value
            },
        },
        {
            title: 'Action',
            key: '_id',
            dataIndex: '_id',
            width: 80,
            fixed: 'right',
            render: (_id) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <div className="mh-5" />
                        <Dropdown overlay={this.moreMenu(_id)} trigger={['click']} placement="bottomRight">
                            <span className="falseHover">
                                <i className="material-icons-outlined">more_vert</i>
                            </span>
                        </Dropdown>
                    </div>
                );
            }
        }
    ].filter(item => !item.hidden);

    keywordSearchHandler = (event) => {
        let serchValue = event.target.value;
        const { data } = this.state;
        const filterTable = data.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(serchValue.toLowerCase())
          )
        );
            
        this.setState({ filterTable });
    };

    moreMenu = (Id) => {
        return (
            <Menu onClick={(e) => this.menuClick(Id, e)}>
                <Menu.Item key="edit">
                    <div className="flexRow ph-5 pv-4">
                        <div className="menuIcon mr-13">
                            <i className="material-icons">edit</i>
                        </div>
                        <span>Edit</span>
                    </div>
                </Menu.Item>
                <Menu.Item key="history">
                    <div className="flexRow ph-5 pv-4">
                        <div className="menuIcon mr-13">
                            <i className="material-icons">list</i>
                        </div>
                        <span>History</span>
                    </div>
                </Menu.Item>
                <Menu.Item key="delete">
                    <div className="flexRow ph-5 pv-4">
                        <div className="menuIcon mr-13">
                            <i className="material-icons">delete</i>
                        </div>
                        <span>Delete</span>
                    </div>
                </Menu.Item>
            </Menu>
        );
    }

    menuClick = (Id, evt) => {
        const { key } = evt;
        // const { match: { url }, history } = this.props;
        const _this = this;
        const URL = window.location.origin;



        switch (key) {
            default:
                alert('We are working on it!!');
                break;

            // moreMenu
            case 'edit': 
                let AnimalsIds = this.state.selectedAnimals.length === 0 ? [Id] : this.state.selectedAnimals;
                let data = { AnimalsIds: AnimalsIds, selectedAnimals: true};
                this.props.catchLiveStockIds(data);
                window.open(URL + `/app/animalform/${Id}`, '_blank');
                break;
            case 'history':
                window.open(URL + `/app/live-stocks-history/${Id}`, '_blank');
                break;    
            case 'delete':
                confirm({
                    title: 'Are you sure you want to delete this Live Stock ?',
                    content: '',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        liveStock.DeleteLiveStockById(Id).then(() => {
                            notification.success({
                                message: 'Live Stock',
                                description: 'Live Stock deleted successfully!'
                            });
                            _this.GetLiveStock();
                        }).catch(() => {
                            // who cares?
                        }).finally(() => {

                        });
                    }
                });
                break;
        }
    }

    SearchHandler = (event) => {
        let query = event.target.value;
        this.setState({SearchQuery: query});
        if(event.key === 'Enter') {
            this.GetLiveStock(1, {keyword: query});
        }

        if(query.length === 0) {
            this.GetLiveStock();
        }
    }

    componentDidMount() {
        this.GetAllFarms();
        this.Categories();
        this.GetLiveStock();
        this.GetStockCount();
    }

    GetAllFarms(){
        FarmService.GetAllFarms()
        .then(resp => {
            if(resp.data.length === 0) {
                const URL = window.location.origin + '/farm-wizard';
                window.open(URL, '_self');
            }
        });
    }

    GetStockCount = async () => {
        const resp = await categories.countCategory();
        this.setState({countGoat: resp.goat, countSheep: resp.sheep, countCow: resp.cow, countBuffalo: resp.buffalo});
    }

    Categories = async () => {
        const response = await categories.animal_category();
        this.setState({categoriesList: response});
    }

    GetLiveStock = async () => {
        let params = {categoryName: this.state.activeTab};
        this.setState({ loading: true, filtering: true });
        let response = await dashboard.get_live_stocks(params);
        this.setState({loading: false, filtering: false, data: response});
    }

    onSuccess = () => { // When new live stock add this function call
        this.GetLiveStock();
        this.GetStockCount();
    }

    OpenLiveStockModel = () => {
        this.AddLiveStockModel.OpenModel();
    }

    onChangeLiveStock = async (value) => {
        if(value === 'All') {
            return this.setState({filterTable: null});
        }
        let filterTable = this.state.data.filter(row => row.categoryName === value);
        this.setState({filterTable: filterTable});
    }

    FilterContent = () => {
        return (
            <>
            <Checkbox onChange={() => this.setState(({ color }) => ({ color: !color }))}>Color</Checkbox> <br/>
            <Checkbox onChange={() => this.setState(({ currentLocation }) => ({ currentLocation: !currentLocation }))}>Current Location</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ lenght }) => ({ lenght: !lenght }))}>Lenght</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ height }) => ({ height: !height }))}>Height</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ bloodLine }) => ({ bloodLine: !bloodLine }))}>Blood Line</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ fertilityStatus }) => ({ fertilityStatus: !fertilityStatus }))}>Fertility Status</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ temprament }) => ({ temprament: !temprament }))}>Temprament</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ initialWeightkg }) => ({ initialWeightkg: !initialWeightkg }))}>Initial Weight kg</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ initialWeightDate }) => ({ initialWeightDate: !initialWeightDate }))}>Initial Weight Date</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ currentWeightkg }) => ({ currentWeightkg: !currentWeightkg }))}>Current Weight kg</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ initialWeightDate }) => ({ initialWeightDate: !initialWeightDate }))}>Initial Weight Date</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ currentWeightDate }) => ({ currentWeightDate: !currentWeightDate }))}>Current Weight Date</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ rfID }) => ({ rfID: !rfID }))}>RfID</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ sireID }) => ({ sireID: !sireID }))}>Sire ID</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ damID }) => ({ damID: !damID }))}>Dam ID</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ name }) => ({ name: !name }))}>Name</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ eID }) => ({ eID: !eID }))}>EID</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ oldID }) => ({ oldID: !oldID }))}>Old ID</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ productionLastDate }) => ({ productionLastDate: !productionLastDate }))}>Production Last Date</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ totalMilk }) => ({ totalMilk: !totalMilk }))}>Total Milk</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ currentLactationStage }) => ({ currentLactationStage: !currentLactationStage }))}>Current Lactation Stage</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ lastkidingDate }) => ({ lastkidingDate: !lastkidingDate }))}>Last kiding Date</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ currentConceptionDate }) => ({ currentConceptionDate: !currentConceptionDate }))}>Current Conception Date</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ kiddingDate }) => ({ kiddingDate: !kiddingDate }))}>Kidding Date</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ notesDate }) => ({ notesDate: !notesDate }))}>Notes Date</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ notesDetails }) => ({ notesDetails: !notesDetails }))}>Notes Details</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ treatmentDate }) => ({ treatmentDate: !treatmentDate }))}>Treatment Date</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ treatmentStatus }) => ({ treatmentStatus: !treatmentStatus }))}>Treatment Status</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ treatmentCondition }) => ({ treatmentCondition: !treatmentCondition }))}>Treatment Condition</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ affectingArea }) => ({ affectingArea: !affectingArea }))}>Notes Date</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ VaccineTreatment }) => ({ VaccineTreatment: !VaccineTreatment }))}>Vaccine Treatment</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ doesgiven }) => ({ doesgiven: !doesgiven }))}>Does given</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ treatmentDays }) => ({ treatmentDays: !treatmentDays }))}>Treatment Days</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ milkWithholding }) => ({ milkWithholding: !milkWithholding }))}>Milk With holding</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ meatWithHolding }) => ({ meatWithHolding: !meatWithHolding }))}>Meat With Holding</Checkbox><br/>

            <Checkbox onChange={() => this.setState(({ dateSeperated }) => ({ dateSeperated: !dateSeperated }))}>Date Seperated</Checkbox><br/>
            <Checkbox onChange={() => this.setState(({ returnVetDate }) => ({ returnVetDate: !returnVetDate }))}>Return Vet Date</Checkbox><br/>
            
      
            </>
        );
    }

    render() {
        const { data, filtering, filterTable } = this.state;
        const { auth } = this.props;
        const { user } = auth;


        const AnimalsSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              let Data = [];
              selectedRows.map(data => {
                  Data.push(data._id);
              })
              this.setState({selectedAnimals: Data});
            },
          };


        const tableProps = {
            size: 'middle',
            columns: this.baseColumns(),
            rowKey: '_id',
            rowSelection: AnimalsSelection,
            dataSource: filterTable == null ? data : filterTable,
            pagination: {
                size: 'small',
                showSizeChanger: true,
                defaultPageSize: 10,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            scroll: { x: 1000 },
            loading: filtering
        };

        const size = { xs: 24, sm: 12, md: 6, lg: 6 };

        return (
            <>
            <Row gutter={[24, 24]}>
                <AddNewLiveStock wrappedComponentRef={(data) => this.AddLiveStockModel = data} onSuccess={this.onSuccess} />
                <Col {...size}>
                <Card className="box-s1" bordered={true}>
                        <Row type="flex">
                            <Col span={12}> 
                                <img alt="Maveshi365 Goat" src={Goat}/>
                                <span className="animal-name">Goat</span>
                             </Col>
                            <Col span={12}> 
                                <div className="total">{this.state.countGoat}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col {...size}>
                    <Card className="box-s1" bordered={false}>
                        <Row type="flex">
                            <Col span={12}> 
                                <img alt="Maveshi365 Sheep" src={Sheep}/>
                                <span className="animal-name">Sheep</span>
                             </Col>
                            <Col span={12}> 
                                <div className="total">{this.state.countSheep}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col {...size}>
                    <Card className="box-s1" bordered={false}>
                        <Row type="flex">
                            <Col span={12}> 
                                <img alt="Maveshi365 Cow" src={Cow}/>
                                <span className="animal-name">Cow</span>
                             </Col>
                            <Col span={12}> 
                                <div className="total">{this.state.countCow}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col {...size}>
                    <Card className="box-s1" bordered={false}>
                        <Row type="flex">
                            <Col span={12}> 
                                <img alt="Maveshi365 Buffalo" src={Buffalo}/>
                                <span className="animal-name">Buffalo</span>
                             </Col>
                            <Col span={12}> 
                                <div className="total">{this.state.countBuffalo}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col lg={24} style={{'marginTop' : '20px', 'marginBottom' : '20px'}}>
                    
                </Col>
                <Col span={24}>
                    <Card bordered={false}>
                    <Tabs onChange={this.onChangeLiveStock}>
                        <TabPane tab="ALL Live Stock" key="All"></TabPane>
                        <TabPane tab="Goat" key="Goat"></TabPane>
                        <TabPane tab="Sheep" key="Sheep"></TabPane>
                        <TabPane tab="Cow" key="Cow"></TabPane>
                        <TabPane tab="Buffalo" key="Buffalo"></TabPane>
                    </Tabs>
                    <Row>
                        <Col span={16}>
                            <Input placeholder="Search Live Stocks" style={{ width: '100%' }} onKeyUp={this.keywordSearchHandler}/> 
                        </Col>
                        <Col span={8} className='textRight'>
                        <Popover content={this.FilterContent()} title="Live Stocks Filter" trigger="click">
                        <Button type='default'>Columns to View</Button>
                        </Popover>
                            &emsp;
                         {user.subscription.limit >= data.length &&
                         <Button type="primary" disable={'true'} onClick={this.OpenLiveStockModel}>Add New Live Stock</Button>
                         }
                        
                        </Col>
                    </Row>
                        <Table rowClassName={(record, index) => (record.sex === 'Female') ? 'female-row' : (record.sex === 'Male') ? 'male-row' : '' } {...tableProps} scroll={{ x: 500}}/>
                    </Card>
                </Col>
            </Row>

            </>
        );
    }
}




export default connect(
    (state) => {
        return {
            auth: state.auth,
            viewingAs: applyViewAs(state.auth)
        }
    },
    actions,
)(Dashboard);