import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Input, notification, Spin, Select } from 'antd';
import { connect } from 'react-redux';

import usersService from '../../services/users';
import FarmService from '../../services/Farm-Service';
import {getCountries} from '../../utils';



const { Option } = Select;
const COUNTRIES = getCountries();


class NewUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            parents: [],
            farmsListData: []
        };
    }

    GetAllFarms(){
        FarmService.GetAllFarms()
        .then(resp => this.setState({farmsListData: resp.data}));
    }

    open = () => {
        this.GetAllFarms();
        this.setState({ visible: true });
    }

    close = () => {
        this.setState({ visible: false });
    }

    reset = () => {
        this.setState({ loading: false });
        this.props.form.resetFields();
    }

    onCancel = () => {
        const { loading } = this.state;
        if (loading) return;
        this.reset();
        this.close();
    }

    onReset = () => {
        this.props.form.resetFields();
    }

    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { validateFields } = this.props.form;
		const { auth } = this.props;
        const { user } = auth;

        validateFields((err, values) => {
            if (err) return;


            // check passwords
            if (values.password !== values.confirmPassword) {
                notification.warning({
                    message: 'Create Users',
                    description: 'Password does not match'
                });
                return;
            }

            


            let data = values;
            data.acceptTerms = true;
            data.parentUser = user.id;

            this.setState({ loading: true });
            usersService.RegisterUser(data)
                .then(resp => {
                    notification.success({
                        message: 'Create Users',
                        description: resp.data.message
                    });
                    this.props.form.resetFields();
                    if (this.props.onSuccess) this.props.onSuccess();
                    this.setState({visible: false});
                })
                .catch(err => {
                    notification.error({
                        message: 'Create Users',
                        description: 'Service Unavailable'
                    });
                    this.setState({ loading: false });
                });

        });
    }

    render() {

        const { loading, visible } = this.state;
        const { getFieldDecorator } = this.props.form;

        const modalProps = {
            visible: visible,
            title: null,
            footer: null,
            onCancel: this.onCancel,
            width: '600px'
        };

        return (
            <Modal {...modalProps}>
                <Spin spinning={loading}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <div className='textCenter'>
                                    <span className='h1 x2 colorPrimary'>Create New User</span>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="title-type-1 mb-30">
                                    <span>Profile</span>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="Select Farm">
                                    {getFieldDecorator("farms", {
                                        rules: [{ required: true, message: 'Please select at least on farm' }],
                                    })(
                                      <Select showSearch className='width-100'>
                                      {this.state.farmsListData.map((data, key) => {
                                          return (<Option key={key} value={data._id}>{data.name}</Option>)
                                      })}
                                      </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="First Name">
                                    {getFieldDecorator("firstName", {
                                        rules: [{ required: true, message: 'Please input first name!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Last Name">
                                    {getFieldDecorator("lastName", {
                                        rules: [{ required: true, message: 'Please input last name!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="Email">
                                    {getFieldDecorator("email", {
                                        rules: [{ required: true, message: 'Please input email!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Phone">
                                    {getFieldDecorator("phone", {
                                        rules: [{ required: true, message: 'Please input phone!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="Country">
                                    {getFieldDecorator("country", {
                                        rules: [{ required: true, message: 'Please input country!' }],
                                    })(
                                        <Select showSearch className='width-100'>
                                        {COUNTRIES.map((country, key) => {
                                            return (<Option key={key} value={country}>{country}</Option>)
                                        })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="State">
                                    {getFieldDecorator("state", {
                                        rules: [{ required: true, message: 'Please input state!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="Postal Code">
                                    {getFieldDecorator("postalCode", {
                                        rules: [{ required: false, message: 'Please input postal code!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Town">
                                    {getFieldDecorator("town", {
                                        rules: [{ required: false, message: 'Please input town!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="Password">
                                    {getFieldDecorator("password", {
                                        rules: [{ required: true, message: 'Please input password!' }],
                                    })(
                                        <Input.Password />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Confirm Password">
                                    {getFieldDecorator("confirmPassword", {
                                        rules: [{ required: true, message: 'Please input confirm password!' }],
                                    })(
                                        <Input.Password />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col  span={24} className='textRight mt-20'>
                                <Button type='default' onClick={this.onReset} disabled={loading}>Reset</Button>
                                &emsp;
                                <Button type="primary" onClick={this.onSubmit}>CREATE</Button> 
                            </Col>
                        </Row>
                </Spin>
            </Modal >
        );
    }

}

const _NewUser = Form.create()(NewUser);

export default connect(
    (state) => {
        return {auth: state.auth}
    },
    null,
    null,
    { forwardRef: true }
)(_NewUser);
