import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Input, notification, Spin } from 'antd';
import { connect } from 'react-redux';
import admin from '../../services/admin';

class StockLimitModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            limitModel: false,
            parents: [],
            user: {},
            userName: null,
        };
    }

    openLimitModal = (user) => {
        this.setState({ limitModel: true, userName: user.firstName + ' ' + user.lastName });
        if (user) {
            this.setState({ user });
            const { setFieldsValue } = this.props.form;
            setFieldsValue({userID: user.id, limit: user.limit});
        }
    }

    closedLimitModal = () => {
        this.setState({ limitModel: false });
    }

    onSubmit =  (e) => {
        if (e) {
            e.preventDefault();
        }
        const { validateFields } = this.props.form;
        validateFields((err, values) => {
            if (err) return;
            
            this.setState({ loading: true });
            admin.LiveStockLimit(values).then(resp => {
                if(resp.data.success) {
                    notification.success({
                        message: 'Live Stocks Limit',
                        description: resp.data.message
                    });
                    this.setState({ loading: false, limitModel: false });
                    if (this.props.onSuccess) this.props.onSuccess();
                    this.props.form.resetFields();
                }

            }).catch((error) => {
                notification.error({
                    message: 'Live Stocks Limit',
                    description: 'Service Unavailable'
                });
                this.setState({ loading: false });
            });

        });
    }

    render() {

        const { loading, limitModel } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: limitModel,
            title: null,
            footer: null,
            onCancel: this.closedLimitModal,
            width: '600px'
        };

        getFieldDecorator('userID', { initialValue: undefined });

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '50px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>Live Stock Limit</span>
                                <br/>
                                <span className='h3 x2 colorPrimary'>{this.state.userName}</span>
                            </div>
                        </Col>
                    </Row> 
                    <Row gutter={24}>    
                        <Col span={24}>
                            <Form.Item label="Live Stock Limit">
                                {getFieldDecorator("limit")(
                                    <Input  />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Save</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _StockLimitModal = Form.create()(StockLimitModal);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_StockLimitModal);
