import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, message, Divider,  notification, Spin } from 'antd';
import { connect } from 'react-redux';

import liveStock from '../../services/liveStock';

class ImportLiveStock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Model: false,
            userName: null,
            userId: null,
            fileName: 'Browse File',
        };
    }

    componentDidMount() {
        
    }

    OpenModel = (data) => {
        let user = data.firstName + ' ' + data.lastName;
        this.setState({ Model: true, userName: user, userId: data.id});
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }


    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { validateFields } = this.props.form;
        

        validateFields((err, values) => {
            if (err) return;
        
            // Append to form data
            const formData = new FormData();
            formData.append("userId", this.state.userId);
            formData.append("csv", values.csv);
            
            this.setState({ loading: true });
            liveStock.ImportLiveStock(formData).then(resp => {
                notification.success({
                    message: 'Live Stock',
                    description: 'Data Imported Successfully'
                });
                
                this.setState({ loading: false }, this.ClosedModel);
                this.props.form.resetFields();
            }).catch((error) => {
                notification.error({
                    message: 'Live Stock',
                    description: 'Service Unavailable'
                });
                this.setState({ loading: false });
            });

        });
    }

    UploadCSVFile = (input) => {
        if (input.files && input.files[0]) {
            this.setState({fileName: input.files[0].name});
            const { setFieldsValue } = this.props.form;
            setFieldsValue({ csv: input.files[0] });
            message.success('File Selected');
        }
    }

    render() {

        const { loading, Model } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '500px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>Import Live Stock</span>
                            </div>
                            <Divider/>
                            <span className='h3 x2 colorPrimary'>Import Data For {this.state.userName}</span>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label={this.state.fileName}>
                                    {getFieldDecorator('csv', {
                                        rules: [
                                            { required: true, message: 'Please select file' }
                                        ]
                                    })(
                                        <>
                                        <input
                                        type='file' accept="image/*" style={{ display: 'none' }}
                                        ref={c => this.csvFile = c}
                                        onChange={() => this.UploadCSVFile(this.csvFile)} />
                                        <Button size="large" block icon='upload' onClick={() => this.csvFile.click()}>
                                            Upload Live Stock CSV
                                        </Button>   
                                        </>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>    
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Import</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _ImportLiveStock = Form.create()(ImportLiveStock);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_ImportLiveStock);
