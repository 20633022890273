import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import './index.css';

import { passwordReset } from '../../services/auth';
import * as actions from '../../actions/auth';
import logo from '../../assets/images/logo.png';

class PasswordReset extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fetching: false,
			confirmDirty: false,
		};
	}

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['password_confirmation'], { force: true });
        }
        callback();
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Password not match');
        } else {
            callback();
        }
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

	handleSubmit = (e) => {

		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({ fetching: true });
				// let result = passwordReset(values);
				this.setState({ fetching: false });	
			}
		});

	}

	render() {

		const { fetching } = this.state;

		const { getFieldDecorator } = this.props.form;

		const { auth } = this.props;
		if (auth.authenticated)
			return (<Redirect to='app/dashboard' />);

		return (
			<div
				className="loginContainer"
			>
				<div className="y">

					<Form onSubmit={this.handleSubmit} className="login-form z">

						<div className='text-center'>
							<img src={logo} alt="farm logo" height={120} />
						</div>

						<div className="intro text-center">
							<h1>Reset Password</h1>
						</div>

						<Form.Item style={{display: "none"}}>
							{getFieldDecorator('token', {
								initialValue: this.props.match.params.token,
								rules: [{ required: true }],
							})(
								<Input type="hidden" />,
							)}
						</Form.Item>
						<Row gutter={24}>
							<Col span={24}>
							<Form.Item label='Password'>
									{getFieldDecorator('password', {
										rules: [
											{ required: true, message: 'Please input your Password' },
											{
												pattern: new RegExp("(?=.*[a-z])"),
												message: '1 lowercase letter, '
											},
											{
												pattern: new RegExp("(?=.*[A-Z])"),
												message: '1 uppercase letter, '
											},
											{
												pattern: new RegExp("(?=.*[0-9])"),
												message: '1 number, '
											},
											{
												pattern: new RegExp("(?=.{8,})"),
												message: '8 characters or longer'
											},
											{ validator: this.validateToNextPassword },
										],
									})(
										<Input.Password
											prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
										/>,
									)}
								</Form.Item>
							</Col>	
							<Col span={24}>
								<Form.Item label='Confirm Password'>
									{getFieldDecorator('confirmPassword', {
										rules: [
											{ required: true, message: 'Please confirm your Password!' },
											{ validator: this.compareToFirstPassword },
										],
									})(
										<Input.Password
											prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
											onBlur={this.handleConfirmBlur}
										/>,
									)}
								</Form.Item>
							</Col>
						</Row>

						<Form.Item style={{ marginBottom: 0 }}>
							<Button loading={fetching} type="primary" htmlType="submit" className="login-form-button">
								Reset Password
          					</Button>
						</Form.Item>
					</Form>

				</div>
			</div>
		);

	}

}

const _PasswordReset = Form.create()(PasswordReset);

export default connect(
	(state) => {
		return {
			auth: state.auth
		}
	},
	actions
)(_PasswordReset);
