import axios from 'axios';

import env from '../env.js';

export const API_URL = `${env.API_URL}/`;

const successHandler = (response) => {
    return response
};

const errorHandler = (error) => {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response) {
        if (error.response.status === 401) {
            alert('Farm App server has been updated please Logout');
        }
    }
    // The request was made but no response was received
    else if (error.request) {

    }
    // Something happened in setting up the request that triggered an Error
    else {

    }
    return Promise.reject({...error })
};

const api = (security = true) => {

    let configs = {
        baseURL: API_URL,
        headers: { "Content-Type": "application/json" }
    };

    if (security) {
        configs.headers['Authorization'] = 'Bearer ' + localStorage.getItem('FARM_TOKEN');
    }

    let axiosInstance = axios.create(configs);

    axiosInstance.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
    )

    return axiosInstance;
}

export default api;