const initialState = {
    fetching: false,
    data: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'DETAILS_FETCHING':
            return {
                ...state,
                fetching: action.payload.fetching,
            }
        case 'DETAILS_SET':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}