import id from 'date-fns/esm/locale/id/index.js';
import api from '../lib/api';

export default {

    UpdateMilkingRow: (values, Id) => {
        return new Promise((resolve, reject) => {
            api()
                .put(`milkings/timing/${Id}`, values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Update Milking failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetAllMilkingGroup: function() {
        return new Promise((resolve, reject) => {
            api()
                .get('milkings')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get Milking failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetMilkingAlerts: function() {
        return new Promise((resolve, reject) => {
            api()
                .get('milkings/alert')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get Milking alerts failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetMilkingStats: function(Id) {
        return new Promise((resolve, reject) => {
            api()
                .get(`milkings/stats/${Id}`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get Milking stats failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    CreateNewMilking: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .post('milkings', values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Create Milking failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    UpdateMilkingById: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .put(`milkings/${values._id}`, values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Update Milking failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    UpdateMilkingGroup: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .post('/milkings/default-group', values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Update Milking Group with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },


}