import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Tabs, Card, Descriptions, notification, Spin, Select } from 'antd';
import { connect } from 'react-redux';
import usersService from '../../services/users';

import JazzCash from '../../assets/images/icon/jazz-cash.jpg';
import EasyPaisa from '../../assets/images/icon/easypaisa.jpg';
import HBL from '../../assets/images/icon/HBL.png';
import contact_us from '../../assets/images/icon/contact-us.png';

import './package-modal.css'

const { TabPane } = Tabs;

class PackageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            cost: 0,
            plan: null,
            Animalslimit: 0,
            paymentType: 'JazzCash',
        };
    }


    open = (data) => {
        this.setState({ visible: true, cost: 0, plan: data.plan, Animalslimit: data.limit });
    }

    close = () => {
        this.setState({ visible: false });
    }

    reset = () => {
        this.setState({ loading: false });
        this.props.form.resetFields();
    }

    onCancel = () => {
        const { loading } = this.state;
        if (loading) return;
        this.reset();
        this.close();
    }

    onReset = () => {
        this.props.form.resetFields();
    }

    onChangeTab = (key) => {
        const KEY = Number(key);
        let payBy =  null;
        switch (KEY) {
            case 1:
                payBy = 'Online';
                break;
            case 2:
                payBy = 'Easy Paisa';
                break;
            case 3:
                payBy = 'Bank Transfer';
                break;    
            default:
                break;
        }
        this.setState({paymentType: payBy });
    }

    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { validateFields } = this.props.form;
		const { auth } = this.props;
        const { user } = auth;
        const {cost, plan, paymentType, Animalslimit} = this.state;

        validateFields((err, values) => {
            if (err) return;
            
            let data = {
                user: user.id,
                limit: Animalslimit,
                cost: cost,
                plan: plan,
                paymentType: paymentType,
                payment_status: true
            };
            
            this.setState({ loading: true });
            usersService.update_subscription(data)
                .then(resp => {
                    if(resp.success) {
                        notification.success({
                            message: 'Subscription Plan',
                            description: resp.message
                        });
                        this.props.form.resetFields();
                        if (this.props.onSuccess) this.props.onSuccess();
                    }
                    else {
                        notification.error({
                            message: 'Subscription Plan',
                            description: 'Request fail try later'
                        }); 
                    }


                    this.setState({visible: false, loading: false});
                })
                .catch(err => {
                    notification.error({
                        message: 'Subscription Plan',
                        description: 'Service Unavailable'
                    });
                    this.setState({ loading: false });
                });

        });
    }

    render() {

        const { loading, visible } = this.state;
        const { getFieldDecorator } = this.props.form;

        const modalProps = {
            visible: visible,
            title: null,
            footer: null,
            onCancel: this.onCancel,
            width: '700px'
        };

        return (
            <Modal {...modalProps}>
                <Spin spinning={loading}>
                <Tabs defaultActiveKey="1" onChange={ (key) => this.onChangeTab(key)}>
                    <TabPane tab="Contact Details" key="1">
                        <div className='payment-container'>
                            <div className='payment-logo'>
                                <img src={contact_us} />
                            </div>
                            <Descriptions title="Contact Details">
                                <Descriptions.Item label="Name">Abc</Descriptions.Item>
                                <Descriptions.Item label="Telephone">1810000000</Descriptions.Item>
                                <Descriptions.Item label="Amount">${this.state.cost}</Descriptions.Item>
                                <Descriptions.Item label="Plan">{this.state.plan}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </TabPane>
                    {/* <TabPane tab="Easy Paisa" key="2">
                        <div className='payment-container'>
                            <div className='payment-logo'>
                                <img src={EasyPaisa} />
                            </div>
                            <Descriptions title="Easy Paisa Details">
                                <Descriptions.Item label="Name">Abc</Descriptions.Item>
                                <Descriptions.Item label="Telephone">1810000000</Descriptions.Item>
                                <Descriptions.Item label="Amount">${this.state.cost}</Descriptions.Item>
                                <Descriptions.Item label="Plan">{this.state.plan}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </TabPane>
                    <TabPane tab="Bank Transfer" key="3">
                        <div className='payment-container'>
                            <div className='payment-logo'>
                                <img src={HBL} />
                            </div>
                            <Descriptions title="Bank Details">
                                <Descriptions.Item label="Account Title">Abc</Descriptions.Item>
                                <Descriptions.Item label="Account">1810000000</Descriptions.Item>
                                <Descriptions.Item label="Amount">${this.state.cost}</Descriptions.Item>
                                <Descriptions.Item label="Plan">{this.state.plan}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </TabPane> */}
                </Tabs>
                    <Row gutter={24}>
                        <Col  span={24} className='textRight mt-20'>
                            <Button type="primary" onClick={this.onSubmit}>Confirm</Button> 
                        </Col>
                    </Row>
                </Spin>
            </Modal >
        );
    }

}

const _PackageModal = Form.create()(PackageModal);

export default connect(
    (state) => {
        return {auth: state.auth}
    },
    null,
    null,
    { forwardRef: true }
)(_PackageModal);
