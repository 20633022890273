import React, { Component } from 'react';
import { Row, Tabs, Col, Card, Table, Modal, Input, Checkbox, Collapse, Select, Icon  } from 'antd';
import { applyViewAs } from '../../utils';
import { ReportingModel } from '../../components';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import categories from '../../services/categories';
import FarmService from '../../services/Farm-Service';
import users from '../../services/users';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const { confirm } = Modal;
//const { Content } = Layout;
const { TabPane } = Tabs;

const { Panel } = Collapse;
const { Option } = Select;

class ReportingComponent extends Component {

    

    constructor(props) {
        super(props)
        this.state = {

            liveStockDataSet: [],
            SessionDataSet: [],
            MilkingDataSet: [],

            categoriesList: [],
            selectedCategory: null,
            animal_breed_category: [],
            loading: false,
            activeTab: 'All',
            SearchQuery: '',
            countGoat: 0,
            countSheep: 0,
            countCow: 0,
            countBuffalo: 0,

            data: [],
            fetching: true,
            filtering: false,
            filterTable: null,

            color: true,
            currentLocation: true,
            lenght: true,
            height: true,
            temprament: true,
            bloodLine: true,

            fertilityStatus: true,
            conditionScore: true,
            conditionScoreDate: true,

            initialWeightkg: true,
            initialWeightDate: true,
            currentWeightkg: true,
            currentWeightDate: true,

            rfID: true,
            sireID: true,
            damID: true,
            name: true,
            eID: true,
            oldID: true,

            productionLastDate: true,
            totalMilk: true,
            currentLactationStage: true,

            lastkidingDate: true,
            currentConceptionDate: true,
            kiddingDate: true,
            
            notesDate: true,
            notesDetails: true,

            treatmentDate: true,
            treatmentStatus: true,
            treatmentCondition: true,

            affectingArea: true,
            VaccineTreatment: true,
            doesgiven: true,
            treatmentDays: true,
            milkWithholding: true,
            meatWithHolding: true,
            dateSeperated: true,
            returnVetDate: true,

            sessionName: true,
            milkTitle: true,
        };

    }

    
    baseColumns = () => [
        {
            title: 'Category',
            key: 'categoryName',
            dataIndex: 'categoryName',
            width: 120,
            fixed: 'left',
            sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
        },
        {
            title: 'Breed',
            key: 'breedName',
            dataIndex: 'breedName',
            width: 120,
            fixed: 'left',
            sorter: (a, b) => a.breedName.localeCompare(b.breedName),
        },
        {
            title: 'Farm',
            key: '_id',
            dataIndex: 'farm',
            width: 200,
            render: (data) => {return (data.name)},
            sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
        },
        {
            title: 'Location',
            key: 'location_id',
            width: 120,
            render: (data) => {
                let locationId = data.location_id;
                return data.farm.locations.filter(x => x._id === locationId)[0]['locationName'];
            },
            sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
        },
        {
            title: 'Initial Weight kg',
            key: 'initialWeightkg',
            render: (data) => {
                return (data.weight.initialWeightkg);
            },
            width: 200,
            sorter: (a, b) => a.weight.initialWeightkg.localeCompare(b.weight.initialWeightkg),
            hidden: this.state.initialWeightkg,
        },
        {
            title: 'Initial Weight Date',
            key: 'initialWeightDate',
            render: (data) => {
                return (data.weight.initialWeightDate);
            },
            width: 200,
            sorter: (a, b) => a.weight.initialWeightDate.localeCompare(b.weight.initialWeightDate),
            hidden: this.state.initialWeightDate,
        },
        {
            title: 'Current Weight kg',
            key: 'Current Weight kg',
            render: (data) => {
                return (data.weight.currentWeightkg);
            },
            width: 200,
            sorter: (a, b) => a.weight.currentWeightkg.localeCompare(b.weight.currentWeightkg),
            hidden: this.state.currentWeightkg,
        },
        {
            title: 'Current Weight Date',
            key: 'currentWeightDate',
            render: (data) => {
                return (data.weight.currentWeightDate);
            },
            width: 200,
            sorter: (a, b) => a.weight.currentWeightDate.localeCompare(b.weight.currentWeightDate),
            hidden: this.state.currentWeightDate,
        },
        {
            title: 'Condition Score',
            key: 'conditionScore',
            render: (data) => {
                return (data.weight.conditionScore);
            },
            width: 200,
            sorter: (a, b) => a.weight.conditionScore.localeCompare(b.weight.conditionScore),
            hidden: this.state.conditionScore,

        },
        {
            title: 'condition Score Date',
            key: 'conditionScoreDate',
            render: (data) => {
                return (data.weight.conditionScoreDate);
            },
            width: 200,
            sorter: (a, b) => a.weight.conditionScoreDate.localeCompare(b.weight.conditionScoreDate),
            hidden: this.state.conditionScoreDate,

        },
        {
            title: 'RFID',
            key: 'rfID',
            render: (data) => {
                return (data.identification.rfID);
            },
            width: 200,
            sorter: (a, b) => a.identification.rfID.localeCompare(b.identification.rfID),
            hidden: this.state.rfID,

        },
        {
            title: 'Sire ID',
            key: 'sireID',
            render: (data) => {
                return (data.identification.sireID);
            },
            width: 200,
            sorter: (a, b) => a.identification.sireID.localeCompare(b.identification.sireID),
            hidden: this.state.sireID,

        },
        {
            title: 'Dam ID',
            key: 'damID',
            render: (data) => {
                return (data.identification.damID);
            },
            width: 200,
            sorter: (a, b) => a.identification.damID.localeCompare(b.identification.damID),
            hidden: this.state.damID,

        },
        {
            title: 'Identify Name',
            key: 'name',
            render: (data) => {
                return (data.identification.name);
            },
            width: 200,
            sorter: (a, b) => a.identification.name.localeCompare(b.identification.name),
            hidden: this.state.name,

        },
        {
            title: 'EID',
            key: 'eid',
            render: (data) => {
                return (data.identification.eID);
            },
            width: 200,
            sorter: (a, b) => a.identification.eID.localeCompare(b.identification.eID),
            hidden: this.state.eID,

        },
        {
            title: 'Old ID',
            key: 'oldID',
            render: (data) => {
                return (data.identification.oldID);
            },
            width: 200,
            sorter: (a, b) => a.identification.oldID.localeCompare(b.identification.oldID),
            hidden: this.state.oldID,
        },

        {
            title: 'Production Last Date',
            key: 'lastDate',
            render: (data) => {
                return (data.production.lastDate);
            },
            width: 200,
            sorter: (a, b) => a.production.lastDate.localeCompare(b.production.lastDate),
            hidden: this.state.productionLastDate,
        },

        {
            title: 'Total Milk',
            key: 'totalMilk',
            render: (data) => {
                return (data.production.totalMilk);
            },
            width: 200,
            sorter: (a, b) => a.production.totalMilk.localeCompare(b.production.totalMilk),
            hidden: this.state.totalMilk,
        },

        {
            title: 'Current Lactation Stage',
            key: 'currentLactationStage',
            render: (data) => {
                return (data.production.currentLactationStage);
            },
            width: 200,
            sorter: (a, b) => a.production.currentLactationStage.localeCompare(b.production.currentLactationStage),
            hidden: this.state.currentLactationStage,
        },

        {
            title: 'Last kiding Date',
            key: 'lastkidingDate',
            render: (data) => {
                return (data.reproduction.lastkidingDate);
            },
            width: 200,
            sorter: (a, b) => a.reproduction.lastkidingDate.localeCompare(b.reproduction.lastkidingDate),
            hidden: this.state.lastkidingDate,
        },

        {
            title: 'Current Conception Date',
            key: 'currentConceptionDate',
            render: (data) => {
                return (data.reproduction.currentConceptionDate);
            },
            width: 200,
            sorter: (a, b) => a.reproduction.currentConceptionDate.localeCompare(b.reproduction.currentConceptionDate),
            hidden: this.state.currentConceptionDate,
        },

        {
            title: 'Kidding Date',
            key: 'kiddingDate',
            render: (data) => {
                return (data.reproduction.kiddingDate);
            },
            width: 200,
            sorter: (a, b) => a.reproduction.kiddingDate.localeCompare(b.reproduction.kiddingDate),
            hidden: this.state.kiddingDate,
        },
        {
            title: 'Notes Date',
            key: 'date',
            render: (data) => {
                return (data.notes.date);
            },
            width: 200,
            sorter: (a, b) => a.notes.date.localeCompare(b.notes.date),
            hidden: this.state.notesDate,
        },
        {
            title: 'Notes Details',
            key: 'details',
            render: (data) => {
                return (data.notes.details);
            },
            width: 200,
            sorter: (a, b) => a.notes.details.localeCompare(b.notes.details),
            hidden: this.state.notesDetails,
        },

        {
            title: 'Treatment Date',
            key: 'treatmentDate',
            render: (data) => {
                return (data.treatment.treatmentDate);
            },
            width: 200,
            sorter: (a, b) => a.treatment.treatmentDate.localeCompare(b.treatment.treatmentDate),
            hidden: this.state.treatmentDate,
        },
        {
            title: 'Treatment Status',
            key: 'treatmentStatus',
            render: (data) => {
                return (data.treatment.treatmentStatus);
            },
            width: 200,
            sorter: (a, b) => a.treatment.treatmentStatus.localeCompare(b.treatment.treatmentStatus),
            hidden: this.state.treatmentStatus,
        },
        {
            title: 'Treatment Condition',
            key: 'treatmentCondition',
            render: (data) => {
                return (data.treatment.treatmentCondition);
            },
            width: 200,
            sorter: (a, b) => a.treatment.treatmentCondition.localeCompare(b.treatment.treatmentCondition),
            hidden: this.state.treatmentCondition,
        },

        {
            title: 'Affecting Area',
            key: 'affectingArea',
            render: (data) => {
                return (data.affectingArea);
            },
            width: 200,
            sorter: (a, b) => a.affectingArea.localeCompare(b.affectingArea),
            hidden: this.state.affectingArea,
        },
        {
            title: 'Vaccine Treatment',
            key: 'VaccineTreatment',
            render: (data) => {
                return (data.VaccineTreatment);
            },
            width: 200,
            sorter: (a, b) => a.VaccineTreatment.localeCompare(b.VaccineTreatment),
            hidden: this.state.VaccineTreatment,
        },

        {
            title: 'Does given',
            key: 'doesgiven',
            render: (data) => {
                return (data.doesgiven);
            },
            width: 200,
            sorter: (a, b) => a.doesgiven.localeCompare(b.doesgiven),
            hidden: this.state.doesgiven,
        },
        {
            title: 'Treatment Days',
            key: 'treatmentDays',
            render: (data) => {
                return (data.treatmentDays);
            },
            width: 200,
            sorter: (a, b) => a.treatmentDays.localeCompare(b.treatmentDays),
            hidden: this.state.treatmentDays,
        },

        {
            title: 'Milk With holding',
            key: 'milkWithholding',
            render: (data) => {
                return (data.milkWithholding);
            },
            width: 200,
            sorter: (a, b) => a.milkWithholding.localeCompare(b.milkWithholding),
            hidden: this.state.milkWithholding,
        },

        {
            title: 'Meat With Holding',
            key: 'meatWithHolding',
            render: (data) => {
                return (data.meatWithHolding);
            },
            width: 200,
            sorter: (a, b) => a.meatWithHolding.localeCompare(b.meatWithHolding),
            hidden: this.state.meatWithHolding,
        },

        {
            title: 'Date Seperated',
            key: 'dateSeperated',
            render: (data) => {
                return (data.dateSeperated);
            },
            width: 200,
            sorter: (a, b) => a.dateSeperated.localeCompare(b.dateSeperated),
            hidden: this.state.dateSeperated,
        },

        {
            title: 'Return Vet Date',
            key: 'returnVetDate',
            render: (data) => {
                return (data.returnVetDate);
            },
            width: 200,
            sorter: (a, b) => a.returnVetDate.localeCompare(b.returnVetDate),
            hidden: this.state.returnVetDate,
        },

        {
            title: 'Color',
            key: 'color',
            hidden: this.state.color,
            render: (data) => {
                return (data.color);
            },
            width: 200,
            sorter: (a, b) => a.color.localeCompare(b.color),
        },
        {
            title: 'Current Location',
            key: 'currentLocation',
            hidden: this.state.currentLocation,
            render: (data) => {
                return (data.currentLocation);
            },
            width: 200,
            sorter: (a, b) => a.currentLocation.localeCompare(b.currentLocation),
        },
        {
            title: 'Lenght',
            key: 'lenght',
            hidden: this.state.lenght,
            render: (data) => {
                return (data.lenght);
            },
            width: 200,
            sorter: (a, b) => a.lenght.localeCompare(b.lenght),
        },
        {
            title: 'Height',
            key: 'height',
            hidden: this.state.height,
            render: (data) => {
                return (data.height);
            },
            width: 200,
            sorter: (a, b) => a.height.localeCompare(b.height),
        },
        {
            title: 'Temprament',
            key: 'temprament',
            hidden: this.state.temprament,
            render: (data) => {
                return (data.temprament);
            },
            width: 200,
            sorter: (a, b) => a.temprament.localeCompare(b.temprament),
        },
        {
            title: 'Blood Line',
            key: 'bloodLine',
            hidden: this.state.bloodLine,
            render: (data) => {
                return (data.bloodLine);
            },
            width: 200,
            sorter: (a, b) => a.bloodLine.localeCompare(b.bloodLine),
        },
        {
            title: 'Fertility Status',
            key: 'fertilityStatus',
            hidden: this.state.fertilityStatus,
            render: (data) => {
                return (data.fertilityStatus);
            },
            width: 200,
            sorter: (a, b) => a.fertilityStatus.localeCompare(b.fertilityStatus),
        },
        {
            title: 'Visual ID',
            key: 'visualID',
            render: (data) => {
                return (data.identification.visualID);
            },
            width: 200,
            sorter: (a, b) => a.identification.visualID - b.identification.visualID,
        },
        {
            title: 'Date of Birth',
            key: 'dob',
            dataIndex: 'dob',
            width: 200,
            sorter: (a, b) => { return moment(a.dob).unix() - moment(b.dob).unix()},
            render: (value) => moment(value).format('ll'),
        },
        {
            title: 'Sex',
            key: 'sex',
            dataIndex: 'sex',
            width: 200,
            sorter: (a, b) => a.sex.localeCompare(b.sex),
            filters: [
                {text: 'Male', value: 'Male'},
                {text: 'Female', value: 'Female'},
                {text: 'Other', value: 'Other'},
            ],
            onFilter: (value, record) => {
                return record.sex === value
            },
        },
        {
            title: 'Origin',
            key: 'origin',
            dataIndex: 'origin',
            width: 200,
            sorter: (a, b) => a.origin.localeCompare(b.origin),
            filters: [
                {text: 'On Farm Birth', value: 'On Farm Birth'},
                {text: 'Purchased', value: 'Purchased'},
            ],
            onFilter: (value, record) => {
                return record.origin === value
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status', 
            width: 200,
            sorter: (a, b) => a.status.localeCompare(b.status),
            filters: [
                {text: 'Active', value: 'Active'},
                {text: 'Sold', value: 'Sold'},
                {text: 'Lost', value: 'Lost'},
                {text: 'Deceased', value: 'Deceased'},
                {text: 'Archive', value: 'Archive'}
            ],
            onFilter: (value, record) => {
                return record.status === value
            },
        },
        {
            title: 'Session',
            key: 'session',
            hidden: this.state.sessionName,
            render: (data) => {
                if(data.sessions.length > 0) return (<><a onClick={() => this.OpenReportingModal(data.sessions, "Session")}>View</a></>);  
            },
            width: 100,
        },
        {
            title: 'Milk',
            key: 'milk',
            hidden: this.state.milkTitle,
            render: (data) => {
                if(data.milkings.length > 0) return (<><a onClick={() => this.OpenReportingModal(data.milkings, "Milk")}>View</a></>);  
            },
            width: 100,
        },
    ].filter(item => !item.hidden);

    keywordSearchHandler = (event) => {
        let serchValue = event.target.value;
        const { data } = this.state;
        const filterTable = data.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(serchValue.toLowerCase())
          )
        );
            
        this.setState({ filterTable });
    };



    SearchHandler = (event) => {
        let query = event.target.value;
        this.setState({SearchQuery: query});
        if(event.key === 'Enter') {
            this.GetLiveStock(1, {keyword: query});
        }

        if(query.length === 0) {
            this.GetLiveStock();
        }
    }

    componentDidMount() {
        this.GetAllFarms();
        this.Categories();
        this.GetLiveStock();
        this.GetStockCount();
    }

    GetAllFarms(){
        FarmService.GetAllFarms()
        .then(resp => {
            if(resp.data.length === 0) {
                const URL = window.location.origin + '/farm-wizard';
                window.open(URL, '_self');
            }
        });
    }

    GetStockCount = async () => {
        const resp = await categories.countCategory();
        this.setState({countGoat: resp.goat, countSheep: resp.sheep, countCow: resp.cow, countBuffalo: resp.buffalo});
    }

    Categories = async () => {
        const response = await categories.animal_category();
        this.setState({categoriesList: response});
    }

    onCategoryChange = (Id) => {
        this.setState({loading: true});
        let categoryName = this.state.categoriesList.filter(data => data._id === Id)[0]['animalCategoryName'];
        let filterTable = this.state.data.filter(row => row.categoryName === categoryName);
        categories.GetAnimalBreedCategory({id: Id})
        .then(resp => this.setState({selectedCategory: categoryName, filterTable: filterTable, animal_breed_category: resp, loading: false}));
    }

    onBreedChange = (value) => {
        let filterTable = this.state.data.filter(row => row.categoryName === this.state.selectedCategory && row.breedName === value);
        this.setState({filterTable: filterTable});
    }

    GetLiveStock = async () => {
        this.setState({ loading: true, filtering: true });
        let response = await users.GetReport();

        console.log(response);

        let ExportData = [];
        let ExportSession = [];
        let ExportMilking = [];

        response.map(data => {
            ExportData.push({
                categoryName: data.categoryName,
                breedName: data.breedName,
                color: data.color,
                sex: data.sex,
                origin: data.origin,
                farm: data.farm.name,
                location: data.farm.locations.length > 0 ? data.farm.locations.filter(a => a._id === data.location_id)[0]['locationName'] : '',
                bodyScore: data.weight.bodyScore,
                initialWeightkg: data.weight.initialWeightkg,
                initialWeightDate: data.weight.initialWeightDate,
                currentWeightkg: data.weight.currentWeightkg,

                carcassWeight: data.production.carcassWeight,
                currentLactationStage: data.production.currentLactationStage,
                fat: data.production.fat,
                lactationCycle: data.production.lactationCycle,
                lactationNumber: data.production.lactationNumber,
                milkAM: data.production.milkAM,
                milkPM: data.production.milkPM,
                milkSolids: data.production.milkSolids,
                milkingDate: data.production.milkingDate,
                protien: data.production.protien,
                teatScore: data.production.teatScore,
                totalMilk: data.production.totalMilk,

                VaccineTreatment: data.treatment.VaccineTreatment,
                affectingArea: data.treatment.affectingArea,
                condition: data.treatment.condition,
                dateSeperated: data.treatment.dateSeperated,
                doesgiven: data.treatment.doesgiven,
                meatWithHolding: data.treatment.meatWithHolding,
                milkWithholding: data.treatment.milkWithholding,
                returnVetDate: data.treatment.returnVetDate,
                treatmentDate: data.treatment.treatmentDate,
                treatmentDays: data.treatment.treatmentDays,
                treatmentStatus: data.treatment.treatmentStatus,
            });

            data.sessions.map(session => {
                ExportSession.push({
                    categoryName: data.categoryName,
                    breedName: data.breedName,
                    sex: data.sex,
                    session_name: session.name,
                    session_status: session.status,
                    session_description: session.description,
                    session_date: session.date,
                });
            });

            data.milkings.map(milk => {
                ExportMilking.push({
                    categoryName: data.categoryName,
                    breedName: data.breedName,
                    sex: data.sex,
                    title: milk.title,
                    groupName: milk.groupName,
                    status: milk.status,
                    date: milk.created,
                });
            });

        })

        this.setState({
            loading: false, filtering: false,
            data: response, liveStockDataSet: ExportData,
            SessionDataSet: ExportSession,
            MilkingDataSet: ExportMilking
        });
    }

    OpenReportingModal = (data, type) => {
        this.ReportingDetailModal.OpenModel(data, type);
    }


    FilterContent = () => {
        return (
            <>
              <Collapse defaultActiveKey={['1']}>
                    <Panel header="Live Stock" key="1">
                        <Checkbox onChange={() => this.setState(({ color }) => ({ color: !color }))}>Color</Checkbox> <br/>
                        <Checkbox onChange={() => this.setState(({ currentLocation }) => ({ currentLocation: !currentLocation }))}>Current Location</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ lenght }) => ({ lenght: !lenght }))}>Lenght</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ height }) => ({ height: !height }))}>Height</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ bloodLine }) => ({ bloodLine: !bloodLine }))}>Blood Line</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ fertilityStatus }) => ({ fertilityStatus: !fertilityStatus }))}>Fertility Status</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ temprament }) => ({ temprament: !temprament }))}>Temprament</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ initialWeightkg }) => ({ initialWeightkg: !initialWeightkg }))}>Initial Weight kg</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ initialWeightDate }) => ({ initialWeightDate: !initialWeightDate }))}>Initial Weight Date</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ currentWeightkg }) => ({ currentWeightkg: !currentWeightkg }))}>Current Weight kg</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ initialWeightDate }) => ({ initialWeightDate: !initialWeightDate }))}>Initial Weight Date</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ currentWeightDate }) => ({ currentWeightDate: !currentWeightDate }))}>Current Weight Date</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ rfID }) => ({ rfID: !rfID }))}>RfID</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ sireID }) => ({ sireID: !sireID }))}>Sire ID</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ damID }) => ({ damID: !damID }))}>Dam ID</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ name }) => ({ name: !name }))}>Name</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ eID }) => ({ eID: !eID }))}>EID</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ oldID }) => ({ oldID: !oldID }))}>Old ID</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ productionLastDate }) => ({ productionLastDate: !productionLastDate }))}>Production Last Date</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ totalMilk }) => ({ totalMilk: !totalMilk }))}>Total Milk</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ currentLactationStage }) => ({ currentLactationStage: !currentLactationStage }))}>Lactation Stage</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ lastkidingDate }) => ({ lastkidingDate: !lastkidingDate }))}>Last kiding Date</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ currentConceptionDate }) => ({ currentConceptionDate: !currentConceptionDate }))}>Conception Date</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ kiddingDate }) => ({ kiddingDate: !kiddingDate }))}>Kidding Date</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ notesDate }) => ({ notesDate: !notesDate }))}>Notes Date</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ notesDetails }) => ({ notesDetails: !notesDetails }))}>Notes Details</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ treatmentDate }) => ({ treatmentDate: !treatmentDate }))}>Treatment Date</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ treatmentStatus }) => ({ treatmentStatus: !treatmentStatus }))}>Treatment Status</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ treatmentCondition }) => ({ treatmentCondition: !treatmentCondition }))}>Treatment Condition</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ affectingArea }) => ({ affectingArea: !affectingArea }))}>Notes Date</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ VaccineTreatment }) => ({ VaccineTreatment: !VaccineTreatment }))}>Vaccine Treatment</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ doesgiven }) => ({ doesgiven: !doesgiven }))}>Does given</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ treatmentDays }) => ({ treatmentDays: !treatmentDays }))}>Treatment Days</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ milkWithholding }) => ({ milkWithholding: !milkWithholding }))}>Milk With holding</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ meatWithHolding }) => ({ meatWithHolding: !meatWithHolding }))}>Meat With Holding</Checkbox><br/>

                        <Checkbox onChange={() => this.setState(({ dateSeperated }) => ({ dateSeperated: !dateSeperated }))}>Date Seperated</Checkbox><br/>
                        <Checkbox onChange={() => this.setState(({ returnVetDate }) => ({ returnVetDate: !returnVetDate }))}>Return Vet Date</Checkbox><br/>
                    </Panel>
                    <Panel header="Sessions" key="2">
                        <Checkbox onChange={() => this.setState(({ sessionName }) => ({ sessionName: !sessionName }))}>Animal Sessions</Checkbox><br/>
                    </Panel>
                    <Panel header="Milking" key="3">
                        <Checkbox onChange={() => this.setState(({ milkTitle }) => ({ milkTitle: !milkTitle }))}> Animal Milking </Checkbox><br/>
                    </Panel>
                </Collapse>      
            </>
        );
    }

    render() {
        const { data, filtering, filterTable, liveStockDataSet, SessionDataSet, MilkingDataSet } = this.state;
        const { auth } = this.props;
        const { user } = auth;

        const tableProps = {
            size: 'middle',
            columns: this.baseColumns(),
            rowKey: '_id',
            dataSource: filterTable == null ? data : filterTable,
            pagination: {
                size: 'small',
                showSizeChanger: true,
                defaultPageSize: 10,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            scroll: { x: 1000 },
            loading: filtering
        };

        const size = { xs: 24, sm: 12, md: 6, lg: 6 };

        return (
            <>
            <ReportingModel wrappedComponentRef={(data) => this.ReportingDetailModal = data} />
            <Row gutter={12}>
                <Col span={6}>
                    <Card>
                        {this.FilterContent()}
                    </Card>
                </Col>
                <Col span={18}>
                    <Card bordered={false}>
                        <Row gutter={12}>
                            <Col span={8}>
                                <Input placeholder="Search" style={{ width: '100%' }} onKeyUp={this.keywordSearchHandler}/>  
                            </Col>
                            <Col span={6}>
                                <Select placeholder="Category" className='width-100' onChange={this.onCategoryChange}>
                                    {this.state.categoriesList.map((opt, key) => {
                                        return (<Option key={key + ""} value={opt._id}>{opt.animalCategoryName}</Option>)
                                    })}
                                </Select>  
                            </Col>
                            <Col span={6}>
                                <Select placeholder="Breed" disabled={this.state.animal_breed_category.length < 1}  onChange={this.onBreedChange} showSearch className='width-100'>
                                    {this.state.animal_breed_category.map((opt, key) => {
                                        return (<Option key={key + ""} value={opt.animalBreedName}>{opt.animalBreedName}</Option>)
                                    })}
                                </Select>
                            </Col>
                            <Col span={4}>

                            <ExcelFile element={<button type="primary"> <Icon type="file-excel" /> Export Data</button>}>
                                <ExcelSheet data={liveStockDataSet} name="Live Stock">
                                    <ExcelColumn label="Category Name" value="categoryName"/>
                                    <ExcelColumn label="Breed Name" value="breedName"/>
                                    <ExcelColumn label="Sex" value="sex"/>
                                    <ExcelColumn label="Origin" value="origin"/>
                                    <ExcelColumn label="Color" value="color"/>
                                    <ExcelColumn label="Farm" value="farm"/>
                                    <ExcelColumn label="Location" value="location"/>
                                    <ExcelColumn label="Body Score" value="bodyScore"/>
                                    <ExcelColumn label="Initial Weight kg" value="initialWeightkg"/>
                                    <ExcelColumn label="Current Weight kg" value="currentWeightkg"/>
                                    <ExcelColumn label="Vaccine Treatment" value="VaccineTreatment"/>
                                    <ExcelColumn label="Affecting Area" value="affectingArea"/>
                                    <ExcelColumn label="Condition" value="condition"/>
                                    <ExcelColumn label="Date Seperated" value="dateSeperated"/>
                                    <ExcelColumn label="Does Given" value="doesgiven"/>
                                    <ExcelColumn label="Meat With Holding" value="meatWithHolding"/>
                                    <ExcelColumn label="Milk With Holding" value="milkWithholding"/>
                                    <ExcelColumn label="Return Vet Date" value="returnVetDate"/>
                                    <ExcelColumn label="Treatment Date" value="treatmentDate"/>
                                    <ExcelColumn label="Treatment Status" value="treatmentStatus"/>
                                    <ExcelColumn label="Carcass Weight" value="carcassWeight"/>
                                    <ExcelColumn label="Current Lactation Stage" value="currentLactationStage"/>
                                    <ExcelColumn label="Fat" value="fat"/>
                                    <ExcelColumn label="Lactation Cycle" value="lactationCycle"/>
                                    <ExcelColumn label="Lactation Number" value="lactationNumber"/>
                                    <ExcelColumn label="Milk AM" value="milkAM"/>
                                    <ExcelColumn label="Milk PM" value="milkPM"/>
                                    <ExcelColumn label="Milk Solids" value="milkSolids"/>
                                    <ExcelColumn label="Milking Date" value="milkingDate"/>
                                    <ExcelColumn label="Protien" value="protien"/>
                                    <ExcelColumn label="Teat Score" value="teatScore"/>
                                    <ExcelColumn label="Total Milk" value="totalMilk"/>
                                </ExcelSheet>
                                <ExcelSheet data={SessionDataSet} name="Sessions">
                                    <ExcelColumn label="Category Name" value="categoryName"/>
                                    <ExcelColumn label="Breed Name" value="breedName"/>
                                    <ExcelColumn label="Sex" value="sex"/>
                                    <ExcelColumn label="Session Name" value="session_name"/>
                                    <ExcelColumn label="Session Status" value="session_status"/>
                                    <ExcelColumn label="Session Description" value="session_description"/>
                                    <ExcelColumn label="Session Date" value="session_date"/>
                                </ExcelSheet>
                                <ExcelSheet data={MilkingDataSet} name="Milking">
                                    <ExcelColumn label="Category Name" value="categoryName"/>
                                    <ExcelColumn label="Breed Name" value="breedName"/>
                                    <ExcelColumn label="Sex" value="sex"/>
                                    <ExcelColumn label="Title" value="title"/>
                                    <ExcelColumn label="Group Name" value="groupName"/>
                                    <ExcelColumn label="Milk Status" value="status"/>
                                    <ExcelColumn label="Date" value="date"/>
                                </ExcelSheet>
                            </ExcelFile>
                            </Col>
                        </Row>
                       
                    <Table rowClassName={(record, index) => (record.sex === 'Female') ? 'female-row' : (record.sex === 'Male') ? 'male-row' : '' } {...tableProps} scroll={{ x: 500}}/>
                    </Card>
                </Col>
            </Row>

            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            auth: state.auth,
            viewingAs: applyViewAs(state.auth)
        }
    },
    null,
)(ReportingComponent);