import React, { Component } from 'react';
import { Row, Col, Card, Table, Popconfirm, message, notification } from 'antd';
import { Header } from '../../components';
import moment from 'moment-timezone';
import categories from '../../services/categories';

class RequestBread extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: false,
            pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
            },
        };

        this.columns = [
            {
                title: 'User',
                key: 'id',
                render: (data) => {
                   return ('-');
                },
                width: 200,
            },
            {
                title: 'Animal Category',
                key: 'id',
                render: (data) => {
                    return (data.animalCategoryID.animalCategoryName);
                },
                width: 150,
            },
            {
                title: 'Animal Breed',
                key: 'animalBreedName',
                dataIndex: 'animalBreedName',
                width: 150,
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                width: 100,
                render: (value) => value == 'Pending' ? <div style={{'color': 'red'}}> {value}</div> : <div style={{'color': 'green', 'fontWeight': 'bold'}}> {value}</div>
            },
            {
                title: 'Created At',
                key: 'created_at',
                dataIndex: 'created_at',
                width: 150,
                render: (value) => moment(value).format('ll'),
            },
            {
                title: 'Action',
                key: 'id',
                dataIndex: 'id',
                width: 150,
                render: (text, record) => {
                    return (
                    <>    
                    <Popconfirm
                        title="Confirm approved request ?"
                        onConfirm={ () => this.ApproveModel(record)}
                        onCancel={this.ApproveCancel}
                        okText="Confirm" cancelText="Cancel">
                        {record.status == 'Pending'? <a href="#" style={{'marginRight': '10px', 'color': 'green'}}>Approve</a> : ''}
                    </Popconfirm>
                        <Popconfirm
                        title="Confirm decline request ?"
                        onConfirm={ () => this.DeclineModel(record)}
                        onCancel={this.DeclineCancel}
                        okText="Confirm" cancelText="Cancel">
                        {record.status == 'Pending'? <a href="#" style={{'color': 'red'}}>Decline</a> : ''}
                        </Popconfirm>
                    </>    
                      
                      );
                }
            }
        ];
    }

    componentDidMount() {
        this.asyncBoot();
    }


    asyncBoot = async () => {
      this.setState({ loading: true });
      let response = await categories.RequestNewBreed();
      this.setState({data: response, loading: false});
    }

    ApproveModel = async (data) => {
        let value = {status: 'Approved'};
        let response = await categories.ApprovedBreed(data._id, value);
        if(response.data.status === 'Approved') {
            notification.success({message: 'Breed Request', description: response.message });
            return this.asyncBoot();
        }
        notification.error({message: 'Breed Request', description: 'Breed Request Fail' });
        
    }

    DeclineModel = async (data) => {
        let value = {status: 'DECLINED'};
        let response = await categories.ApprovedBreed(data._id, value);
        if(response.data.status === 'DECLINED') {
            notification.success({message: 'Breed Request', description: response.message });
            return this.asyncBoot();
        }
        notification.error({message: 'Breed Request', description: 'Breed Request Fail' });
        
    }

    ApproveCancel = () => {
        message.info('Approved Cancel');
    }

    DeclineCancel = () => {
        message.info('Decline Cancel');
    }


    render() {

        const { data, loading, saving } = this.state;

        const tableProps = {
            size: 'middle',
            columns: this.columns,
            rowKey: 'id',
            dataSource: data,
            pagination: {
                size: 'small',
                defaultPageSize: this.state.pagination.perPage,
                total: this.state.pagination.total,
                onChange: this.onPaginationChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
            },
            loading: loading,
            scroll: { x: 1000 },
        };

        return (
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Header icon="people" title="Users Breed Request"/>
                </Col>
                <Col span={24}>
                    <Card bordered={false}>
                        <Table {...tableProps} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default RequestBread