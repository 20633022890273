import api from '../lib/api';

export default {

    GetGroupById: function (Id) {
        return new Promise((resolve, reject) => {
            api()
                .get(`groups/edit/${Id}`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get Groups failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetAllGroups: function () {
        return new Promise((resolve, reject) => {
            api()
                .get('groups')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get Groups failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    AddGroup: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .post('groups', values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Add new Group failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    UpdateGroup: (values, Id) => {
        return new Promise((resolve, reject) => {
            api()
                .put(`groups/edit/${Id}`, values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Update Grpup failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    DeleteGroup: (Id) => {
        return new Promise((resolve, reject) => {
            api()
                .delete(`groups/${Id}`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Delete request failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

}