import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Select, Input, notification, Spin } from 'antd';
import { connect } from 'react-redux';

// Farm Project Import Services
import MilkingService from '../../services/Milking-Service';
// Farm Project Import Services
const { Option } = Select;

class MilkingModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAnimal: [],
            milkingAlerts: [],
            loading: false,
            saving: false,
            Model: false,
            isEdit: false,
            ModelTitle: null,
            Role: null,
        };
    }

    componentDidMount() {
        this.GetMilkingAlerts();
    }

    GetMilkingAlerts = async () => {
        let result = await MilkingService.GetMilkingAlerts();
        this.setState({milkingAlerts: result.data});
    }


    OpenModel = (data) => {
       const { setFieldsValue } = this.props.form;
       setFieldsValue({ // set form values
        startTime: data.startTime,
        endTime: data.endTime,
        alert: data.alert ? data.alert._id : '',
        comments: data.comments,
        weightType: data.weightType,
        milk: data.milk,
       });
       this.setState({selectedAnimal: data, ModelTitle: 'Animal Milking', Model: true});         
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    ResponseSuccessfull(message) {
		notification.success({ message: 'Animal Milking', description: message});
        this.setState({ loading: false }, this.ClosedModel);
        this.props.form.resetFields();
        if (this.props.onSuccess) this.props.onSuccess();
	}

    ErrorAlert(error) {
        notification.error({ message: 'Animal Milking', description: 'Service Unavailable'});
    }

    onSubmit = (e) => {
        if (e) e.preventDefault()
        const {validateFields} = this.props.form; 

        validateFields((err, values) => {
            if (err) return;
            
            let body = {
                startTime: this.state.selectedAnimal['startTime'],
                endTime: this.state.selectedAnimal['endTime'],
                alert: values.alert,
                comments: values.comments,
                weightType: values.weightType,
                milk: values.milk,
            };

            this.setState({ loading: true });
            MilkingService.UpdateMilkingRow(body, this.state.selectedAnimal['_id'])
            .then(resp => {
                if(resp.success) {
                   this.ResponseSuccessfull(resp.message);
                }
                else {
                    this.ErrorAlert();
                }
            })
            .catch( error => {
                this.ErrorAlert();
            });
            this.props.form.resetFields();

        });
    }

    render() {

        const { loading, Model } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '800px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>{this.state.ModelTitle}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label='Weigth Type'>
                                {getFieldDecorator('weightType', {
                                    rules: [{ required: true, message: 'Weight Type is required' }]
                                })(
                                    <Select showSearch className='width-100'>
                                        <Option key={1} value="Liters">Liters</Option>
                                        <Option key={2} value="KG">KG</Option>
                                    </Select>
                                )}
                        </Form.Item> 
                        </Col>
                        <Col span={6}>
                            <Form.Item label='Milk'>
                                {getFieldDecorator('milk', {
                                    rules: [{ required: true, message: 'Milking is required' }]
                                })(
                                    <Input />
                                )}
                        </Form.Item> 
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Alerts'>
                                {getFieldDecorator('alert', {
                                    rules: [{ required: true, message: 'Alert is required' }]
                                })(
                                    <Select showSearch className='width-100'>
                                        {this.state.milkingAlerts.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.name}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item> 
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label='Comments'>
                                    {getFieldDecorator('comments', {
                                        rules: [{ required: false, message: 'Comments is required' }]
                                    })(
                                        <Input />
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>    
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Save</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _MilkingModal = Form.create()(MilkingModal);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_MilkingModal);
