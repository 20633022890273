import api from '../lib/api';

export default {

    ViewAllAccounts: function () {
        return new Promise((resolve, reject) => {
            api()
                .get('accounts/admin')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get User failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },  

    DeleteUser: (Id) => {
        return new Promise((resolve, reject) => {
            api()
                .delete(`/accounts/admin/${Id}`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Delete User failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    LiveStockLimit: function(value) {
        return new Promise((resolve, reject) => {
            api()
                .put(`/accounts/admin`, value)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Live Stock Limit Fail with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetSubscriptionRequest: function () {
        return new Promise((resolve, reject) => {
            api()
                .get('accounts/subscription-request')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get User failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },  

    ConfirmSubscription: (data) => {
        return new Promise((resolve, reject) => {
            api()
                .put(`accounts/confirm-subscription`, data)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Request failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },
    
 

}