import api from '../lib/api';

export default {

    RegisterUser: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .post('accounts/register', values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`create users failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },


    update_subscription: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .post('accounts/update-subscription', values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`subscription update failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    UpdateProfile: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .put('accounts/profile', values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`update users failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetProfile: () => {
        return new Promise((resolve, reject) => {
            api()
                .get('accounts/profile')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Get profile failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetReport: () => {
        return new Promise((resolve, reject) => {
            api()
                .get('accounts/report')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Get report failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },




}