import api from '../lib/api';

export default {

    GetLiveStockById: (Id) => {
        return new Promise((resolve, reject) => {
            api()
                .get(`live-stocks/edit/${Id}`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Get Live stock failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetLiveStockHistoryById: (Id) => {
        let params = { parentAnimalID: Id };
        return new Promise((resolve, reject) => {
            api()
                .get(`/live-stocks/history`, { params })
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Get Live stock failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetLiveStockCounts: (params = {}) => {
        return new Promise((resolve, reject) => {
            api()
                .get(`category/count-animals`, params)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Get Live stock failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    DeleteLiveStockById: (Id) => {
        return new Promise((resolve, reject) => {
            api()
                .delete(`live-stocks/${Id}`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Delete request failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    UpdateLiveStockById: function(data, Id) {
        return new Promise((resolve, reject) => {
            api().post(`live-stocks/edit/${Id}`, data)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`update live stock with status: ${status}`);

                })
                .catch(error => reject(error));
        });
    },



    AddLiveStock: function(data = {}) {
        return new Promise((resolve, reject) => {
            api()
                .post('live-stocks', data)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Live Stock Fail with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    ImportLiveStock: function(values) {
        return new Promise((resolve, reject) => {
            api()
                .post('live-stocks/import', values, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`update profil with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },
}