import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Select, DatePicker, notification, Row, Col, Steps, Spin  } from 'antd';
import '../auth/index.css';
import * as actions from '../../actions/auth';

import FarmService from '../../services/Farm-Service';

import { getCountries } from '../../utils';
const { Option } = Select;
const COUNTRIES = getCountries();

const { Step } = Steps;


class FarmWizard extends Component {

	constructor(props) {
		super(props);
		this.state = {
            current: 0,
			loading: false
		};

		this.steps = [
			{
				title: 'Add your Farm details',
				fields: [
					'name',
					'address',
					'town',
					'country',
					'phone',
					'postalCode',
					'dateOpened',
				],
				values: {},
				render: this.RenderFarmInformation
			},
			{
				title: 'Add You Farm Location',
				fields: [
					'locationName',
					'startDate',
					'endDate',
				],
				values: {},
				render: this.RenderFarmLocation
			},
			{
				title: 'Add You Pen/Lot',
				fields: [
					'penName',
					'penType',
					'startDate',
					'endDate',
				],
				values: {},
				render: this.RenderFarmPen
			},
			{
				title: 'Add You Bunks',
				fields: [
					'bunkName',
					'bunkType',
				],
				values: {},
				render: this.RenderFarmBunk
			},
			
		];
	}

	componentDidMount() {

	}

	ResponseSuccessfull(message){
		notification.success({ message: 'Farm', description: message});
		const URL = window.location.origin + '/app/farm';
        window.open(URL, '_self');
	}

	ResponseFail(message){
		notification.error({ message: 'Farm', description: message});
	}

    nextStep = () => {
        
        let { current } = this.state;
        const { validateFields, setFieldsValue } = this.props.form;

        let fields = [];

        if (this.steps[current].fields) {
            fields = this.steps[current].fields.map((field) => {
                if (typeof field === 'object') {
                    return field.name;
                }
                else {
                    return field;
                }
            });
        }

        validateFields(fields, async (errors, values) => {

            if (errors) return;

            this.steps[current].values = { ...values };

            if (current < 3) {

                current++;
                this.setState({ current }, () => {
                    setFieldsValue(this.steps[current].values);
                });

            } else {

                this.createNewFarm(current);

            }

        });
      }

	  createNewFarm = () => {
		let farm = this.steps[0]['values'];
		let locations = this.steps[1]['values'];
		let pens = this.steps[2]['values'];
		let bunks = this.steps[3]['values'];
		let body = {
			formWizard: true,
			name: farm.name,
			address: farm.address,
			country: farm.country,
			postalCode: farm.postalCode,
			phone: farm.phone,
			dateOpened: farm.dateOpened,
			locations: [locations],
			pens: [pens],
			bunks: [bunks]
		};

		this.setState({ loading: true });
		
		FarmService.AddFarm(body).then(resp => {
			this.ResponseSuccessfull(resp.message);		
			this.setState({ loading: false });
			this.props.form.resetFields();
		}).catch((error) => {
			this.ResponseFail('Service Unavailable');
			this.setState({ loading: false });
		});
		
	  }
    
      previousStep() {
		let { current } = this.state;
        const { setFieldsValue } = this.props.form;

        if (current) {
            current--;
            this.setState({ current }, () => {
                setFieldsValue(this.steps[current].values);
            });
        }
      }

	  RenderFarmLocation = () => {
		const { getFieldDecorator } = this.props.form;

		return (
			<div>
			<Row gutter={24}>
            <Col span={12}>
					<Form.Item label='Location Name'>
                        {getFieldDecorator('locationName', {
                                rules: [{ required: true, message: 'Location Name is required' }]
                            })(
                            <Input />
                            )}
                    </Form.Item>  
                </Col>
                <Col span={12}>
                    <Form.Item label='Start Date'>
                        {getFieldDecorator('startDate', {
                                rules: [{ required: true, message: 'Start Date is required' }]
                            })(
								<DatePicker className="width-100"/>
                            )}
                    </Form.Item>
                </Col>
            </Row>
			</div>
		);		
	  }
	  
	  RenderFarmPen = () => {
		const { getFieldDecorator } = this.props.form;

		return (
			<div>
			<Row gutter={24}>
            <Col span={12}>
					<Form.Item label='Pen Name'>
                        {getFieldDecorator('penName', {
                                rules: [{ required: false, message: 'Name is required' }]
                            })(
                            <Input />
                            )}
                    </Form.Item>  
                </Col>
                <Col span={12}>
                    <Form.Item label='Type'>
                        {getFieldDecorator('penType', {
                                rules: [{ required: false, message: 'Pen Type is required' }]
                            })(
								<Select showSearch className='width-100'>
                                    <Option key={1} value={'Home'}>Home</Option>
                                    <Option key={2} value={'Hospital Pen'}>Hospital Pen</Option>
                                    <Option key={3} value={'Cull Pen'}>Cull Pen</Option>
                                    <Option key={3} value={'Holding Pen'}>Holding Pen</Option>
                                    <Option key={3} value={'Special Pen'}>Special Pen</Option>
                                </Select>
                            )}
                    </Form.Item>
                </Col>
            </Row>
			</div>
		);
	  }

	  RenderFarmBunk = () => {
		const { getFieldDecorator } = this.props.form;
		return(
			<Row gutter={24}>
				<Col span={12}>
						<Form.Item label='Bunk Name'>
							{getFieldDecorator('bunkName', {
								rules: [{ required: false, message: '' }]
							})(
								<Input />
							)}
						</Form.Item>    
				</Col>
				<Col span={12}>
					<Form.Item label='Bunk Type'>
						{getFieldDecorator('bunkType', {
								rules: [{ required: false, message: 'Location is required' }]
							})(
								<Select showSearch className='width-100'>
									<Option key={1} value={'Feed'}>Feed</Option>
									<Option key={2} value={'Water'}>Water</Option>
								</Select>
							)}
					</Form.Item>
				</Col>
			</Row>
		);
	  }

	  RenderFarmInformation = () => {

        const { getFieldDecorator } = this.props.form;

		return (
			<div>
				<Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="Farm Name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Farm name is required' }],
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
				<Row gutter={24}>
            <Col span={12}>
                    <Form.Item label='Country'>
                        {getFieldDecorator('country', {
                                rules: [{ required: true, message: 'Please select country' }]
                            })(
                                <Select showSearch className='width-100'>
                                {COUNTRIES.map((country, key) => {
                                    return (<Option key={key} value={country}>{country}</Option>)
                                })}
                                </Select>
                            )}
                    </Form.Item>   
                </Col>
                <Col span={12}>
                    <Form.Item label='Town City'>
                        {getFieldDecorator('town', {
                                rules: [{ required: true, message: 'Town City is required' }]
                            })(
                            <Input />
                            )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
				<Col span={12}>
							<Form.Item label='Address'>
								{getFieldDecorator('address', {
									rules: [{ required: true, message: 'Address is required' }]
								})(
									<Input />
								)}
							</Form.Item>    
					</Col>
					<Col span={12}>
						<Form.Item label='Postal Code'>
							{getFieldDecorator('postalCode', {
									rules: [{ required: true, message: 'Postal Code is required' }]
								})(
									<Input />
								)}
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={24}>
				<Col span={12}>
							<Form.Item label='Date opened'>
								{getFieldDecorator('dateOpened', {
									rules: [{ required: true, message: 'Please select Date opened' }]
								})(
									<DatePicker className="width-100"/>
								)}
							</Form.Item>    
					</Col>
					<Col span={12}>
						<Form.Item label='Telephone'>
							{getFieldDecorator('phone', {
									rules: [{ required: true, message: 'Telephone is required' }]
								})(
									<Input />
								)}
						</Form.Item>
					</Col>
				</Row>
			</div>

		);

    }  

	render() {

		const { current, loading  } = this.state;
		// const { history } = this.props;

		// const { getFieldDecorator } = this.props.form;
		// const { auth } = this.props;

		return (
			<div className="loginContainer">
				<div className="y">

					<Form className="farm-wizard z">

						<div className="intro">
							<h1 className='text-center'>{this.steps[current].title}</h1>
						</div>

                        <Spin spinning={loading}>
                            <Steps current={current}>
								<Step title="Farm"/>
								<Step title="Location"/>
								<Step title="Pen/Lot"/>
								<Step title="Bunks/Feeding Pen"/>
                            </Steps>
                            <div className="steps-content mb-20">
								{this.steps[current].render()}
							</div>
							<Row>
								<Col  span={24} className='textRight'>
									<Button type='default' style={{ marginLeft: 8 }} onClick={() => this.previousStep()}>Previous</Button>
									&emsp;
									<Button type="primary" disabled={current === 3 && loading} onClick={() => this.nextStep()}> {current === 3 ? 'Save' : 'Next'} </Button> 
								</Col>
							</Row>
                        </Spin>
					</Form>

				</div>
				<div style={{
					position: "absolute",
					left: "0",
					right: "0",
					zIndex: "1",
					textAlign: "right",
					padding: "0px 24px",
					fontWeight: "bold",
					color: "#e6f7ff",
				}}>
				</div>
			</div>
		);

	}

}

const _FarmWizard = Form.create()(FarmWizard);

export default connect(
	(state) => {
		return {
			auth: state.auth
		}
	},
	actions
)(_FarmWizard);
