import api from '../lib/api';


export const attemptLogin = (credentials) => {

    return new Promise((resolve, reject) => {

        api(false)
            .post('accounts/authenticate', credentials)
            .then(({ status, data }) => {

                if (status === 200)
                    resolve(data);

                throw new Error({
                    status: status,
                    message: `attemptLogin failed with status: ${status}`
                });

            })
            .catch(error => {
                if (error && error.response) {
                    resolve(error.response.data);
                }
                reject(error)
            });

    });

}

export const signup = (credentials) => {

    return new Promise((resolve, reject) => {

        api(false)
            .post('signup', credentials)
            .then(({ status, data }) => {

                if (status === 200)
                    resolve(data);

                throw new Error({
                    status: status,
                    message: `post signup failed with status: ${status}`
                });

            })
            .catch(error => reject(error));

    });

}

export const viewAs = (id) => {

    return new Promise((resolve, reject) => {

        api(false)
            .post('view/user/' + id)
            .then(({ status, data }) => {

                if (status === 200)
                    resolve(data);

                throw new Error({
                    status: status,
                    message: `post signup failed with status: ${status}`
                });

            })
            .catch(error => reject(error));

    });

}

export const forgotPassword = (params) => {

    return new Promise((resolve, reject) => {

        api(false)
            .post('accounts/forgot-password', params)
            .then(({ status, data }) => {

                if (status === 200)
                    resolve(data);

                throw new Error({
                    status: status,
                    message: `password Email failed with status: ${status}`
                });

            })
            .catch(error => reject(error));

    });

}

export const passwordReset = (params) => {

    return new Promise((resolve, reject) => {

        api(false)
            .post('accounts/reset-password', params)
            .then(({ status, data }) => {

                if (status === 200)
                resolve(data);

                throw new Error({
                    status: status,
                    message: `password Reset failed with status: ${status}`
                });

            })
            .catch(error => reject(error));

    });

}

export const VerifyAccount = (params) => {
    return new Promise((resolve, reject) => {
        api(false)
            .post('accounts/verify-email', params)
            .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Account Verification fail: ${status}`);
            })
            .catch(error => {
                reject(error)
            });
    });
}

