import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Form, Icon, Input, Button, notification, Card, Row, Col, List, Radio  } from 'antd';
import logo from '../../../assets/images/logo.png';
import './index.css'
import * as actions from '../../../actions/auth';

class Packages extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fetching: false,
		};
	}

	componentDidMount() {

	}

	render() {


		const { getFieldDecorator } = this.props.form;
		const { auth } = this.props;
		if (auth.authenticated)
			return (<Redirect to='app/dashboard' />);


		const PackageList = [
			'Live Stock',
			'Farms',
			'Management Groups',
			'Animal Movement',
			'Sessions',
			'Milking',
		];
		
		let animals_50 = ['50 Number of Animals'];
		let animals_100 = ['100 Number of Animals'];
		let animals_200 = ['200 Number of Animals'];
		let custom_animal = ['Custom Number of Animals'];
		
		const BasicPlan = animals_50.concat(PackageList);
		const StandardPlan = animals_100.concat(PackageList);
		const PremiumPlan = animals_200.concat(PackageList);
		const CustomPlan = custom_animal.concat(PackageList);


		return (
			<div className="packageContainer">
				<Card>
					<div className='text-center'>
						<img src={logo} alt="Farm logo" height={120}/>
						<div className='plan_switch'>
							<Radio.Group size="small" defaultValue="0" buttonStyle="solid">
								<Radio.Button value="0">Monthly</Radio.Button>
								<Radio.Button value="1">Yearly</Radio.Button>
							</Radio.Group>
						</div>
					</div>
						<Row type="flex" justify="center" align="middle">
							<Col span={20}>
							<Row type="flex" justify="space-around" align="middle">
							<Col span={5}>
								<Card.Grid>
									<div className='package-header basic-package'>
										<span>Basic</span>
									</div>
									<div className='price-box'>
										<span className='price'>$50</span>
										<span>/month</span>
									</div>
									<div className='list-area'>
										<List size="small" bordered={false} dataSource={BasicPlan} renderItem={item => <List.Item>{item}</List.Item>} />
									</div>
									<div className='get-started-btn'>
										<Button size='large' type="primary">Get Started</Button>
									</div>
								</Card.Grid>								
							</Col>
							<Col span={5}>
								<Card.Grid>
									<div className='package-header standard-package'>
										<span>Standard</span>
									</div>
									<div className='price-box'>
										<span className='price'>$100</span>
										<span>/month</span>
									</div>
									<div className='list-area'>
										<List size="small" bordered={false} dataSource={StandardPlan} renderItem={item => <List.Item>{item}</List.Item>} />
									</div>
									<div className='get-started-btn'>
										<Button size='large' type="primary">Get Started</Button>
									</div>
								</Card.Grid>
							</Col>
							<Col span={5}>
								<Card.Grid>
									<div className='package-header premium-package'>
										<span>Premium</span>
									</div>
									<div className='price-box'>
										<span className='price'>$150</span>
										<span>/month</span>
									</div>
									<div className='list-area'>
										<List size="small" bordered={false} dataSource={PremiumPlan} renderItem={item => <List.Item>{item}</List.Item>} />
									</div>
									<div className='get-started-btn'>
										<Button size='large' type="primary">Get Started</Button>
									</div>
								</Card.Grid>
							</Col>
							<Col span={5}>
								<Card.Grid>
									<div className='package-header custom-package'>
										<span>Custom Plan</span>
									</div>
									<div className='price-box'>
										<span className='price'>Get A Quote</span>
									</div>
									<div className='list-area'>
										<List size="small" bordered={false} dataSource={CustomPlan} renderItem={item => <List.Item>{item}</List.Item>} />
									</div>
									<div className='get-started-btn'>
										<Button size='large' type="primary">Get Started</Button>
									</div>
								</Card.Grid>
							</Col>
						</Row>
							</Col>
						</Row>

				</Card>
			</div>
		);

	}

}

const _Packages = Form.create()(Packages);

export default connect(
	(state) => {
		return {
			auth: state.auth
		}
	},
	actions
)(_Packages);
