import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Form, Icon, Input, Button, Row, Col, notification } from 'antd';
import '../passwordReset/index.css';

import * as actions from '../../actions/auth';
import { VerifyAccount } from '../../services/auth';
import logo from '../../assets/images/logo.png';
import verifiedIcon from '../../assets/images/icon/verified.png';

class EmailVerification extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		let token = {token: this.props.match.params.token};
		VerifyAccount(token).then(response => {
			notification.success({message: 'Email Verification', description: response.message})
		}).catch( error => {
			notification.error({message: 'Email Verification', description: "verification Fail Please contact your Admin"})
		});
	}


	render() {

		const { history } = this.props;
		return (
			<div className="loginContainer">
				<div className="y">
                    <div className='z'>
						<div className='text-center'>
							<img src={logo} alt="farm logo" height={100} />
						</div>
						<div className="intro text-center">
							<h1><img src={verifiedIcon} alt="farm logo" height={32} /> Verification Completed!</h1>
							<h3>Your have Successfully Verified your account</h3>
							<a onClick={() => { history.push(`/login`) }}  className='link'>
							<Button type="primary" className="login-form-button">
								Login
          					</Button>
							</a>
						</div>
					</div>
				</div>
			</div>
		);

	}

}

const _EmailVerification = Form.create()(EmailVerification);

export default connect(
	(state) => {
		return {
			auth: state.auth
		}
	},
	actions
)(_EmailVerification);
