import React, { Component } from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import { Row, Col, Card, Table, Form, Tag, Icon, Button, notification, Checkbox   } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';
import { Header, MilkingModal, EditLivestock } from '../../components';
import './milking.scss';
import moment from 'moment-timezone';
import CowIcon from '../../assets/images/icon/cow.png';
import DashboardIcon from '../../assets/images/icon/dashboard.png';
import MilkingService from '../../services/Milking-Service';
import liveStock from '../../services/liveStock';

import Clock from 'react-live-clock';

class AnimalMilking extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            milkingId: null,
            categoriesList: [],
            selectedCategoriesID: null,
            loading: false,
            AnimalData: null,
            selectedAnimalId: 0,
            milkingCount: 0,
            AnimalProblemGroup: [],
            SearchQuery: '',
            pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
            },
        };

        this.columns = [
            {
                title: 'Row',
                key: '_id',
                width: 50,
                render: (data, values, Index) => {
                    return (
                        <p>{Index + 1}</p>
                    );
                }
            },
            {
                title: 'Animal',
                key: 'animal',
                width: 50,
                render: (data) => {
                    return data.animal.identification.visualID;
                }
            },
            {
                title: 'Alerts',
                key: 'alert',
                width: 100,
                render: (data) => {
                    if(data.alert) {
                        return (
                            <>
                            <Tag color={data.alert.color}>{data.alert.name}</Tag>
                            </>
                        );
                    }
                    
                }
            },
            {
                title: 'Weight',
                key: 'milk',
                width: 50,
                render: (data) => {
                    return (
                        <>
                            {data.milk + ' ' + data.weightType}
                        </>
                    );
                }
            },
            {
                title: 'Timer',
                key: 'startTime',
                width: 100,
                render: (data) => {
                    if(data.startTime === "" && data.endTime === "") {
                        return(<><Button onClick={ () => this.UpdateMilkTime(data, 'start')} type="primary"><Icon type="clock-circle" />Start</Button></>);
                    }
                    else if (data.startTime !== "" && data.endTime === ""){
                        return (<><Button onClick={ () => this.UpdateMilkTime(data, 'end')}><Icon type="sync" spin />Stop</Button></>);
                    }
                    else {
                        return data.startTime + ' - ' + data.endTime
                    }
                }
            },
            {
                title: 'Action',
                key: 'edit',
                width: 50,
                render: (data) => {
                    return(
                        <>
                        <a key={data._id} onClick={ () => this.Modal.OpenModel(data)} >Edit</a>
                        </>
                    );
                }
            },
        ];
    }


    SelectedAnimal = async (data) => {
        this.setState({loading: true})
        let response = await liveStock.GetLiveStockById(data.animal._id);
        this.setState({
            AnimalData: response,
            loading: false,
            selectedAnimalId: response._id,
            AnimalProblemGroup: response.problemGroup
        });
    }

    OpenLiveStockModel = () => {
       this.EditLiveStockModel.OpenModel(this.state.AnimalData, "1");
    }

    ResponseSuccessfull(message) {
		notification.success({ message: 'Animal Milking', description: message});
        this.setState({ loading: false });
        this.onSuccess();
	}

    ErrorAlert(error) {
        notification.error({ message: 'Animal Milking', description: 'Service Unavailable'});
    }
    
    UpdateMilkTime = (data, type) => {
        let start_time = data.startTime;
        let end_time = data.endTime;
        if(type === 'start') {
            start_time = moment().add(642, 'seconds').format('hh:mm A');
        }
        else {
            end_time = moment().add(642, 'seconds').format('hh:mm A');
        }

        let body = {
            startTime: start_time,
            endTime: end_time,
            alert: data.alert ? data.alert._id : '',
            comments: data.comments,
            weightType: data.weightType,
            milk: data.milk,
        };


        this.setState({ loading: true });
        MilkingService.UpdateMilkingRow(body, data._id)
        .then(resp => {
            if(resp.success) {
               this.ResponseSuccessfull(resp.message);
            }
            else {
                this.ErrorAlert();
            }
        })
        .catch( error => {
            this.ErrorAlert();
        });
    }

    SearchHandler = (event) => {
        let query = event.target.value;
        this.setState({SearchQuery: query});
        if(event.key === 'Enter') {
            this.asyncBoot(1, {keyword: query});
        }

        if(query.length === 0) {
            this.asyncBoot();
        }
    }


    componentDidMount() {
        let Id = this.props.match.params.Id;
        this.asyncBoot(Id);
    }


    asyncBoot = async (Id) => {
        this.setState({ loading: true });
        let Data = await MilkingService.GetAllMilkingGroup();
        let counts = await MilkingService.GetMilkingStats(Id);
        let filterData = Data.data.filter(row => row._id === Id)[0];
        this.setState({data: filterData['animals'], loading: false, milkingId: Id, milkingCount: counts.data.milkingCount});
    }

    


    onPaginationChange = (page) => {
        this.asyncBoot(page, {keyword: this.state.SearchQuery});
    }

    onSuccess = () => {
        this.asyncBoot(this.state.milkingId);
    }

    BackToMilking = () => {
        this.props.history.push('/app/milking');
    }

    UpdateMilkingGroup = async (e) => {
        let value = e.target.defaultValue;
        let isChecked = e.target.checked;
        let selected = this.state.AnimalProblemGroup;
        let length = selected.filter(data => data === value).length;
        if(length === 0 ) {
            selected.push(value);
            this.setState({AnimalProblemGroup: selected});
            
        }
        else {
            let Index = selected.indexOf(value);
            selected.splice(Index, 1);
        }

        let body = {
            animal: this.state.selectedAnimalId,
            problemGroup: selected
        }

        this.setState({loading: true});
        let result = await MilkingService.UpdateMilkingGroup(body);
        if(result.success){
            let Data = {animal: {_id: this.state.selectedAnimalId}}
            this.SelectedAnimal(Data);
            notification.success({ message: 'Milking Group', description: 'Group Updated Successfully'});
        }
    }

    render() {

        const { data, loading } = this.state;

        const tableProps = {
            size: 'middle',
            columns: this.columns,
            rowKey: 'id',
            dataSource: data,
            pagination: false,
            loading: loading,
            scroll: { y: 800 },
        };
        

        return (
            <Row gutter={[24, 24]}>
                <MilkingModal wrappedComponentRef={c => this.Modal = c} onSuccess={this.onSuccess} />
                <EditLivestock wrappedComponentRef={(data) => this.EditLiveStockModel = data} onSuccess={this.onSuccess} />
                <Col span={24}>
                    <Header icon="people" title="Animal Milking"/>
                </Col>
                <Col span={19}>
                    <Card className='full-height' bordered={false}>    
                        <Table rowClassName={(record, index) => record.animal._id === this.state.selectedAnimalId ? 'selected-row' : ''  } onRow={(record) => {
                            return {
                                onClick: () => this.SelectedAnimal(record)
                                }
                        }} {...tableProps} />
                    </Card>
                </Col>
                <Col span={5}>
                    <div className='milking-menu full-height'>
                        <div className='animal-id'>
                             <p>{this.state.AnimalData ? this.state.AnimalData.identification.visualID : 'Animals Profile'}</p>
                        </div>
                        <Row type="flex" justify="space-between">
                            <Col span={12}>
                                <div onClick={ () => this.BackToMilking()} className='menu-button'>
                                    <img src={DashboardIcon} alt="" />
                                    <p>Home</p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div onClick={ () => this.OpenLiveStockModel() } className='menu-button'>
                                    <img src={CowIcon} alt="" />
                                    <p>Animal Info</p>
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" justify="space-between">
                            <Col span={24}>
                                <div className='menu-button'>
                                    <p><Checkbox checked={this.state.AnimalProblemGroup.includes('Heat')} defaultValue={'Heat'} onChange={ this.UpdateMilkingGroup }> Heat</Checkbox></p>
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" justify="space-between">
                            <Col span={24}>
                                <div className='menu-button'>
                                    <p><Checkbox checked={this.state.AnimalProblemGroup.includes('Mastaloane 3 tube')} defaultValue={'Mastaloane 3 tube'} onChange={ this.UpdateMilkingGroup }>Mastaloane 3 tube</Checkbox></p>
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" justify="space-between">
                            <Col span={24}>
                                <div className='menu-button'>
                                    <p><Checkbox checked={this.state.AnimalProblemGroup.includes('Add to slow milkers')} defaultValue={'Add to slow milkers'} onChange={ this.UpdateMilkingGroup }>Add to slow milkers</Checkbox></p>
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" justify="space-between">
                            <Col span={24}>
                                <div className='menu-button'>
                                    <p><Checkbox checked={this.state.AnimalProblemGroup.includes('Lame')} defaultValue={'Lame'} onChange={ this.UpdateMilkingGroup }>Lame</Checkbox></p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col className='mt-5' span={24}>
                    <Card bordered={false}>
                        <Row type="flex">
                            
                            <Col span={3}>
                                <div className='text-center'>
                                    <span className='footer-title'>{this.state.milkingCount}</span> <br/>
                                    <span className='footer-description'>Cows Milked</span>
                                </div>
                            </Col>
                            <Col span={3}>
                                <div className='text-center'>
                                    <span className='footer-title'>1:07:58</span> <br/>
                                    <span className='footer-description'>Duration</span>
                                </div>
                            </Col>
                            <Col span={3}>
                                <div className='text-center'>
                                    <span className='time'><Clock format={'HH:mm:ss'} ticking={true} timezone={'US/Pacific'} /></span>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    }
}


const _AnimalMilking = Form.create()(AnimalMilking);

export default connect(
	(state) => {
		return {
			auth: state.auth
		}
	},
	actions
)(_AnimalMilking);
