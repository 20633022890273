import React, { Component } from 'react';
import { Layout, Menu, Button, Avatar } from 'antd';
import { withRouter } from 'react-router-dom';
import './sider.scss';
import * as actions from '../../actions/auth';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { getMenuItems } from './routes';

const { Sider } = Layout;

class AppSider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuItems: [],
            selectedKeys: [],
            collapsed: false,
            hide: false,
        }
    }

    /**
     * Add event listener
     */
    componentDidMount() {
        this.buildMenuItems();
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    /**
     * 
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.auth.authenticated) {
            if (prevProps.auth.user.role.name !== this.props.auth.user.role.name) {
                this.buildMenuItems();
            }
        }
    }

    logout = () => {
        this.props.authSignout();
    }

    edit = () => {
        this.props.history.push('/app/profile');
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }


    buildMenuItems = () => {
        const { user } = this.props.auth;
        const menuItems = getMenuItems(user.role, user.subscription);
        this.setState({ menuItems }, this.getSelectedKeys);
    }

    getSelectedKeys = () => {
        const { pathname } = this.props.history.location;
        let index = this.state.menuItems.findIndex((item, i) => {
            if (item.path === undefined) return false;
            return pathname.search(item.path) !== -1 ? true : false;
        });
        this.setState({ selectedKeys: [index.toString()] })
    }

    /**
     * Calculate & Update state of new dimensions
     */
    updateDimensions() {

        if (window.innerWidth < 576)
            this.setState({ hide: true });

        else if (window.innerWidth <= 1200)
            this.setState({ hide: false, collapsed: true })

        else if (window.innerWidth > 1200)
            this.setState({ hide: false, collapsed: false });

        if (window.innerWidth >= 768) {
            this.setState({ hide: false });
        }
        else if (window.innerWidth >= 576 && window.innerWidth < 768) {
            this.setState({ collapsed: true, hide: false });
        } else {
            this.setState({ collapsed: true, hide: true });
        }

    }

    onClick = ({ key }) => {

        this.setState({ selectedKeys: [key] });

        const { match: { path } } = this.props;
        const { path: pathRoute } = this.state.menuItems[key];
        if (pathRoute) {
            this.props.history.push(path + pathRoute);
        }

    }

    render() {

        const { collapsed, hide, selectedKeys, menuItems } = this.state;

        if (hide) return (<div></div>);

        const { user } = this.props.auth;
        let PackageDaysLeft;
        if(user.role !== 'Admin') {
            const packageExpire = moment(user.subscription.expire_date);
            const Today = moment();
            const RemainingDays = packageExpire.diff(Today, 'days');
           PackageDaysLeft = <h2><span className="span1">{RemainingDays} Days Left</span></h2>
        }


        return (
            <Sider width={collapsed ? 80 : 250} style={{ background: '#fff' }} className={collapsed ? 'siderCollapsed' : ''}>
                <div className="siderHeader">                   
                    <div className="text-center hideOnCollapsed">
                        <Avatar size={100} icon='user' src={user.picture} />
                        <br/>
                        <br/>
                        <h2><span className="span1">{user.firstName} {user.lastName}</span></h2>
                        {PackageDaysLeft}
                        <Button type="primary mr-4" size='small' shape="round" onClick={this.edit}>Edit Profile</Button>
                        <Button type="primary" size='small' shape="round" onClick={this.logout}>Logout</Button>
                    </div>
                </div>
                <Menu mode="inline" className="siderMenu" selectedKeys={selectedKeys} onClick={this.onClick}>
                    {
                        menuItems.map((item, key) => {
                            return (
                                <Menu.Item key={key + ''}>
                                    <div className="siderOption">
                                        <i className="siderOptionIcon material-icons-outlined">{item.icon}</i>
                                        <span className="siderOptionLabel hideOnCollapsed">{item.label}</span>
                                    </div>
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>

            </Sider>
        );

    }


}

export default connect(
    (state) => {
        return {
            auth: state.auth
        }
    },
    actions
)(withRouter(AppSider));
