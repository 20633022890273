import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Select, Input, notification, Spin } from 'antd';
import { connect } from 'react-redux';

// Farm Project Import Services
import categories from '../../services/categories';
import FarmService from '../../services/Farm-Service';
// Farm Project Import Services
const { Option } = Select;

class AnimalMoveModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            farmsListData: [],
            farmLocations: [],
            selectedAnimals: [],
            loading: false,
            saving: false,
            Model: false,
            isEdit: false,
            ModelTitle: null,
            Role: null,
        };
    }

    componentDidMount() {
        this.GetAllFarms();
    }

    GetAllFarms(){
        FarmService.GetAllFarms()
        .then(resp => this.setState({farmsListData: resp.data}));
    }

    OpenModel = (animals) => {
        this.setState({ModelTitle: 'Animal Movement', Model: true, selectedAnimals: animals});         
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    ResponseSuccessfull(message){
		notification.success({ message: 'Animal Movement', description: message});
        this.setState({ loading: false }, this.ClosedModel);
        this.props.form.resetFields();
        if (this.props.onSuccess) this.props.onSuccess();
	}

    onFarmChange = (Id) => {
        this.setState({loading: true});
        const Locations = this.state.farmsListData.filter(data => data._id === Id)[0]['locations'];
        this.setState({farmLocations: Locations, loading: false});
    }

    ErrorAlert(error) {
        notification.error({ message: 'Animal Movement', description: 'Service Unavailable'});
    }

    onSubmit = (e) => {
        if (e) e.preventDefault()
        const {validateFields} = this.props.form; 

        validateFields((err, values) => {
            if (err) return;

            if(this.state.selectedAnimals.length === 0) {
                return notification.error({message: 'Animal Movement', description: 'Animal or group selection is required'});
            }
            
            let body = {
                animals: this.state.selectedAnimals,
                farm: values.farm,
                location_id: values.location_id,
                movementType: values.movementType,
            };
            this.setState({ loading: true });
            FarmService.AnimalMovement(body)
            .then(resp => {
                if(resp.success) {
                   this.ResponseSuccessfull(resp.message);
                }
                else {
                    this.ErrorAlert();
                }
            })
            .catch( error => {
                this.ErrorAlert();
            });
            this.props.form.resetFields();

        });
    }

    render() {

        const { loading, Model } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '800px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>{this.state.ModelTitle}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Farm'>
                                {getFieldDecorator('farm', {
                                    rules: [{ required: true, message: 'Please select Farm' }]
                                })(
                                    <Select showSearch className='width-100' onChange={this.onFarmChange}>
                                        {this.state.farmsListData.map((data, key) => {
                                                return (<Option key={key} value={data._id}>{data.name}</Option>)
                                        })}
                                    </Select>
                                )}
                        </Form.Item> 
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Location'>
                                {getFieldDecorator('location_id', {
                                        rules: [{ required: true, message: 'Location is required' }]
                                    })(
                                        <Select disabled={this.state.farmLocations.length < 1} showSearch className='width-100'>
                                            {this.state.farmLocations.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.locationName}</Option>)
                                            })}
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Movement Type'>
                                    {getFieldDecorator('movementType', {
                                        rules: [{ required: true, message: 'Farm is required' }]
                                    })(
                                        <Select className='width-100'>
                                            <Option key={1} value="Arrival">Arrival</Option>
                                            <Option key={2} value="Pen">Pen</Option>
                                            <Option key={3} value="Dispatch">Dispatch</Option>
                                            <Option key={4} value="Hospital">Hospital</Option>
                                            <Option key={5} value="Dead">Dead</Option>
                                            <Option key={6} value="Off Farm Movement">Off Farm Movement</Option>
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>    
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Save</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _AnimalMoveModal = Form.create()(AnimalMoveModal);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_AnimalMoveModal);
