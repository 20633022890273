import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Select, Input, notification, Spin, DatePicker } from 'antd';
import { connect } from 'react-redux';

// Farm Project Import Services
import categories from '../../services/categories';
import liveStock from '../../services/liveStock';
import FarmService from '../../services/Farm-Service';
import GroupService from '../../services/Group-Service';
// Farm Project Import Services
const { Option } = Select;

class AddLiveStock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Model: false,
            parents: [],
            farmsListData: [],
            farmLocations: [],
            farmsPens: [],
            categories: [],
            Groups: [],
            selectedFarmId: null,
            animal_breed_category: []
        };
    }

    componentDidMount() {
        this.GetAnimalCategory();
        this.GetAllFarms();
        this.GetAllGroups();
    }

    GetAllGroups = async () => {
        let groupData = await GroupService.GetAllGroups();
        this.setState({Groups: groupData.data});
    }

    GetAllFarms(){
        FarmService.GetAllFarms()
        .then(resp => this.setState({farmsListData: resp.data}));
    }

    onFarmChange = (Id) => {
        this.setState({loading: true});
        const Locations = this.state.farmsListData.filter(data => data._id === Id)[0]['locations'];
        this.setState({farmLocations: Locations, loading: false, selectedFarmId: Id});
    }

    onLocationChange = (Id) => {
        this.setState({loading: true});
        const Pens = this.state.farmsListData.filter(data => data._id === this.state.selectedFarmId)[0]['pens'];
        this.setState({farmsPens: Pens, loading: false});
    }

    OpenModel = () => {
        this.setState({ Model: true });
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    GetAnimalCategory(){
        categories.animal_category()
        .then(resp => this.setState({categories: resp}));
    }

    onCategoryChange = (Id) => {
        const {setFieldsValue} = this.props.form;
        this.setState({loading: true});
        categories.GetAnimalBreedCategory({id: Id})
        .then(resp => {
            this.setState({animal_breed_category: resp});
            setFieldsValue({breedName: null});
            this.setState({loading: false});
        });
    }

    onReset = () => {
        this.props.form.resetFields();
    }

    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { validateFields, setFieldsValue, getFieldValue } = this.props.form;
        let Id = getFieldValue('categoryName');

        if(Id) { // if Id is available 
            let category = this.state.categories.filter(data => data._id === Id)[0];
            setFieldsValue({categoryName: category.animalCategoryName});
        }
        

        validateFields((err, values) => {
            if (err) return;
        
            this.setState({ loading: true });
            liveStock.AddLiveStock(values).then(resp => {
                notification.success({
                    message: 'Live Stock',
                    description: resp.message
                });
                
                this.setState({ loading: false }, this.ClosedModel);
                this.props.form.resetFields();
                if (this.props.onSuccess) this.props.onSuccess();

            }).catch((error) => {
                notification.error({
                    message: 'Live Stock',
                    description: 'Service Unavailable'
                });
                this.setState({ loading: false });
            });

        });
    }

    render() {

        const { loading, Model } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '800px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>Add New Live Stock</span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                    <Col span={12}>
                            <Form.Item label='Farm'>
                                    {getFieldDecorator('farm', {
                                        rules: [
                                            { required: true, message: 'Please select Farm' }
                                        ]
                                    })(
                                        <Select showSearch className='width-100' onChange={this.onFarmChange}>
                                        {this.state.farmsListData.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.name}</Option>)
                                        })}
                                        </Select>
                                        )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Location'>
                                    {getFieldDecorator('location_id', {
                                        rules: [{ required: true, message: 'Please select Location' }]
                                    })(
                                        <Select disabled={this.state.farmLocations.length < 1} onChange={this.onLocationChange} showSearch className='width-100'>
                                            {this.state.farmLocations.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.locationName}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Pen'>
                                    {getFieldDecorator('pen_id', {
                                        rules: [{ required: true, message: 'Please select Pen' }]
                                    })(
                                        <Select disabled={this.state.farmsPens.length < 1} showSearch className='width-100'>
                                            {this.state.farmsPens.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.penName}</Option>)
                                            })}
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Group'>
                                    {getFieldDecorator('group', {
                                        rules: [{ required: false, message: 'Please select Group' }]
                                    })(
                                        <Select disabled={this.state.Groups.length < 1} mode="multiple" showSearch className='width-100'>
                                            {this.state.Groups.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.name}</Option>)
                                            })}
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                    <Col span={12}>
                            <Form.Item label='Category'>
                                    {getFieldDecorator('categoryName', {
                                        rules: [
                                            { required: true, message: 'Please select Category' }
                                        ]
                                    })(
                                        <Select className='width-100' onChange={this.onCategoryChange}>
                                            {this.state.categories.map((opt, key) => {
                                                return (<Option key={key + ""} value={opt._id}>{opt.animalCategoryName}</Option>)
                                            })}
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Breed'>
                                    {getFieldDecorator('breedName', {
                                        rules: [{ required: true, message: 'Please select Breed' }]
                                    })(
                                        <Select disabled={this.state.animal_breed_category.length < 1} showSearch className='width-100'>
                                        {this.state.animal_breed_category.map((opt, key) => {
                                            return (<Option key={key + ""} value={opt.animalBreedName}>{opt.animalBreedName}</Option>)
                                        })}
                                        </Select>
                                    )}
                                </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                    <Col span={12}>
                                <Form.Item label='Date Of Birth'>
                                    {getFieldDecorator('dob', {
                                        rules: [{ required: true, message: 'Please select Date Of Birth' }]
                                    })(
                                        <DatePicker className="width-100"/>
                                    )}
                                </Form.Item>    
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Origin'>
                                {getFieldDecorator('origin', {
                                        rules: [{ required: true, message: 'Please select Origin' }]
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="Purchased">Purchased</Option>
                                            <Option key="2" value="On Farm Birth">On Farm Birth</Option>
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Visual ID'>
                                    {getFieldDecorator('identification.visualID', {
                                        rules: [{ required: true, message: 'Visual ID is required' }]
                                    })(
                                        <Input />
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Status'>
                                    {getFieldDecorator('status', {
                                        rules: [{ required: true, message: 'Please select status' }]
                                    })( 
                                    <Select showSearch className='width-100'>
                                        <Option key="1" value="Active">Active</Option>
                                        <Option key="2" value="Sold">Sold</Option>
                                        <Option key="3" value="Lost">Lost</Option>
                                        <Option key="4" value="Deceased">Deceased</Option>
                                        <Option key="5" value="Archive">Archive</Option>
                                    </Select>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Sex'>
                                    {getFieldDecorator('sex', {
                                        rules: [{ required: true, message: 'Sex is required' }]
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="Male">Male</Option>
                                            <Option key="2" value="Female">Female</Option>
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Date of weaning'>
                                    {getFieldDecorator('dateOfWeaning', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100'/>
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col  span={24} className='textRight mt-20'>
                            <Button type='default' onClick={this.onReset} disabled={loading}>Reset</Button>
                            &emsp;
                            <Button type="primary" onClick={this.onSubmit}>Save</Button> 
                        </Col>
                    </Row>
                </Spin>
            </Modal >
        );
    }

}

const _AddLiveStock = Form.create()(AddLiveStock);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_AddLiveStock);
