import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Select, Input, notification, Spin } from 'antd';
import { connect } from 'react-redux';

// Farm Project Import Services
import categories from '../../services/categories';
//import liveStock from '../../services/liveStock';
// Farm Project Import Services

class AnimalCategoryModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            saving: false,
            Model: false,
            isEdit: false,
            categoryId: '',
            ModelTitle: 'Add Animal Category',
        };
    }

    componentDidMount() {
        
    }

    OpenModel = (data) => {
        const {setFieldsValue} = this.props.form;
        setFieldsValue({animalCategoryName: data ? data.animalCategoryName : null});
        this.setState({
            ModelTitle: data ? 'Edit Animal Category' : 'Add Animal Category',
            categoryId: data ? data._id : null,
            isEdit: data ? true : false,
            Model: true
        });            
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    successAlert(resp) {
        if (this.props.onSuccess) this.props.onSuccess();
        notification.success({ message: 'Animal Category', description: resp.message});
    }

    ErrorAlert(error) {
        notification.error({ message: 'Live Stock', description: 'Service Unavailable'});
    }

    addCategory = (values) => {
        categories.AddAnimalCategory(values)
        .then(resp => this.successAlert(resp))
        .catch(error => this.ErrorAlert(error));
    }

    editCategory = (values) => {
        categories.UpdateAnimalCategory(values, this.state.categoryId)
        .then(resp => this.successAlert(resp))
        .catch(error => this.ErrorAlert(error));
    }


    onSubmit = (e) => {
        if (e) e.preventDefault()
        const {validateFields} = this.props.form;        

        validateFields((err, values) => {
            if (err) return;
            this.setState({ loading: true });
            
            if(this.state.isEdit) {
              this.editCategory(values);                  
            }
            else {
                this.addCategory(values);
            }
        
            this.setState({ loading: false, Model: false});
            this.props.form.resetFields();
            
        });
    }

    render() {

        const { loading, Model } = this.state;
        const { getFieldDecorator } = this.props.form;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '600px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>{this.state.ModelTitle}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label='Animal Category Name'>
                                    {getFieldDecorator('animalCategoryName', {
                                        rules: [{ required: true, message: 'Category name required' }]
                                    })(
                                        <Input />
                                    )}
                            </Form.Item>
                        </Col>

                    </Row>    
                    <div className="textRight">
                        <Button type="primary" onClick={this.onSubmit}>Save</Button>
                    </div>
                </Spin>
            </Modal >
        );
    }

}

const _AnimalCategoryModal = Form.create()(AnimalCategoryModal);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_AnimalCategoryModal);
