import api from '../lib/api';

export default {

    GetSessionsById: function(Id) {
        return new Promise((resolve, reject) => {
            api()
                .get(`sessions/edit/${Id}`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get Sessions failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetAllSessions: function() {
        return new Promise((resolve, reject) => {
            api()
                .get('sessions')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get Sessions failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    AddSessions: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .post('sessions', values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Create Sessions failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    UpdateSessions: (values, Id) => {
        return new Promise((resolve, reject) => {
            api()
                .put(`sessions/edit/${Id}`, values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Update Sessions failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetAnimalsBySessionId: function(Id) {
        return new Promise((resolve, reject) => {
            api()
                .get(`sessions/${Id}/details`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get Sessions animals failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },


    GetSessionActivities: function() {
        return new Promise((resolve, reject) => {
            api()
                .get(`sessions/activities`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get Sessions activities failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },


}