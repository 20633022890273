import React, { Component } from 'react';
import { Row, Col, Card, Button, Form, Input } from 'antd';
//import profileService from '../../services/profile';
import { Header } from '../../components';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';
//import { getBase64, getStates, applyViewAs } from '../../utils';


class ChangePassword extends Component {

    state = {

    };

    componentDidMount() {
    
    }


    render () {

        const { getFieldDecorator } = this.props.form;

        return (
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Header
                        icon="Lock"
                        title="Change Password"
                        right={(
                            <Button type='primary'>SAVE</Button>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <Card bordered={false}>
 
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label='New Password'>
                                    {getFieldDecorator('new_password', {
                                        rules: [
                                            { required: true, message: 'Please input the new password' }
                                        ]
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label='Confirm Password'>
                                    {getFieldDecorator('confirm_password', {
                                        rules: [
                                            { required: true, message: 'Confirm password should match' }
                                        ]
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
     
    }
}

const ChangePasswordForm = Form.create()(ChangePassword);

export default connect(
    (state) => {
        return {
            auth: state.auth,
            //viewingAs: applyViewAs(state.auth)
        }
    },
    actions
)(ChangePasswordForm);