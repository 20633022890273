import React, { Component } from 'react';
import { Form, Icon, Input, Button, notification, Card, Row, Col, List, Radio, Alert   } from 'antd';
import './index.css'
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';
import logo from '../../assets/images/logo.png';
import selectedIcon from '../../assets/images/icon/selected.png';
import moment from 'moment-timezone';
import { PackageModal } from '../../components/';

class Subscription extends Component {

	constructor(props){
		super(props);
	}

    state = {};

    componentDidMount() {}

	render() {
		const { auth } = this.props;
		const { user } = auth;


		const packageExpire = moment(user.subscription.expire_date);
		const Today = moment();
		const RemainingDays = packageExpire.diff(Today, 'days');

		const PackageList = [
			'Live Stock',
			'Farms',
			'Management Groups',
			'Animal Movement',
			'Sessions',
			'Milking',
		];
		
		let animals_50 = ['50 Number of Animals'];
		let animals_100 = ['100 Number of Animals'];
		let animals_200 = ['200 Number of Animals'];
		let custom_animal = ['Custom Number of Animals'];
		
		const BasicPlan = animals_50.concat(PackageList);
		const StandardPlan = animals_100.concat(PackageList);
		const PremiumPlan = animals_200.concat(PackageList);
		const CustomPlan = custom_animal.concat(PackageList);
		
		let message = null;

		if(RemainingDays > 0) {
			message = <h1>your current plan is <span className='plan-highlight'>{user.subscription.plan}</span> and will expire on <span className='plan-highlight'>{moment(user.subscription.expire_date).format('ll')} </span>
							and <span className='plan-highlight'>{RemainingDays}</span> days are left</h1>
		}
		else {
			message = <Alert message="your subscription is expired please upgrade your plan again" type="error" showIcon />
		}

		return (
			<div className="">
				<PackageModal wrappedComponentRef={c => this.packgeModal = c} onSuccess={this.onSuccess}/>
				<Card>
						<div className='text-center'>
							<img src={logo} alt="Farm logo" height={120}/>
							<h1 className='main-heading'>Choose the plan that's right for you.</h1>
						</div>
						<Row type="flex" justify="center" align="middle">
							<div className='alert-message'>
								{message}
							</div>
							<Col span={24}>
							<Row type="flex" gutter={12} justify="space-around" align="middle">
							<Col span={6}>
								{/* <Card.Grid>
									<div className='sub-card'>
										<div className='selected-img'>
											<img src={user.subscription.plan === 'Standard' ? selectedIcon : false}  height="80" />
										</div>
										<div className='package-header standard-package'>
										<span>Standard</span>
										</div>
										<div className='price-box'>
											<span className='price'>$100</span>
											<span>/month</span>
										</div>
										<div className='list-area'>
											<List size="small" bordered={false} dataSource={StandardPlan} renderItem={item => <List.Item>{item}</List.Item>} />
										</div>
										<div className='get-started-btn'>
											<Button onClick={ () => this.packgeModal.open({cost: 100, plan: 'Standard', limit: 100})} size='large' type="primary">Get Started</Button>
										</div>
									</div>
								</Card.Grid> */}
							</Col>
							<Col span={6}>
								<Card.Grid>
									<div className='sub-card'>
										<div className='selected-img'>
											<img src={user.subscription.plan === 'Basic' ? selectedIcon : false}  height="80" />
										</div>
										<div className='package-header basic-package'>
										<span>Basic</span>
										</div>
										<div className='price-box'>
											<span className='price'>Free Plan</span>
										</div>
										<div className='list-area'>
											<List size="small" bordered={false} dataSource={BasicPlan} renderItem={item => <List.Item>{item}</List.Item>} />
										</div>
										<div className='get-started-btn'>
											<Button onClick={ () => this.packgeModal.open({cost: 50, plan: 'Basic', limit: 50})} size='large' type="primary">Get Started</Button>
										</div>
									</div>

								</Card.Grid>								
							</Col>
							<Col span={6}>
								<Card.Grid>
									<div className='sub-card'>
										<div className='selected-img'>
											<img src={user.subscription.plan === 'Custom' ? selectedIcon : false}  height="80" />
										</div>
										<div className='package-header custom-package'>
										<span>Custom Plan</span>
										</div>
										<div className='price-box'>
											<span className='price'>Get A Quote</span>
										</div>
										<div className='list-area'>
											<List size="small" bordered={false} dataSource={CustomPlan} renderItem={item => <List.Item>{item}</List.Item>} />
										</div>
										<div className='get-started-btn'>
											<Button onClick={ () => this.packgeModal.open({cost: null, plan: 'Custom', limit: 0})} size='large' type="primary">Get Started</Button>
										</div>
									</div>
								</Card.Grid>
							</Col>
							<Col span={6}>
								{/* <Card.Grid>
									<div className='sub-card'>
										<div className='selected-img'>
											<img src={user.subscription.plan === 'Premium' ? selectedIcon : false}  height="80" />
										</div>
										<div className='package-header premium-package'>
										<span>Premium</span>
										</div>
										<div className='price-box'>
											<span className='price'>$150</span>
											<span>/month</span>
										</div>
										<div className='list-area'>
											<List size="small" bordered={false} dataSource={PremiumPlan} renderItem={item => <List.Item>{item}</List.Item>} />
										</div>
										<div className='get-started-btn'>
											<Button onClick={ () => this.packgeModal.open({cost: 150, plan: 'Premium', limit: 200})} size='large' type="primary">Get Started</Button>
										</div>
									</div>
								</Card.Grid> */}
							</Col>
						</Row>
							</Col>
						</Row>

				</Card>
			</div>
		);

	}

}


const FormSubscription = Form.create()(Subscription);

export default connect(
    (state) => {
        return {
            auth: state.auth,
        }
    },
    actions
)(FormSubscription);


