import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Select, Input, notification, Spin, DatePicker, Tabs, Avatar } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { getAnimalRank, getWeightScore } from '../../utils';


import icUser from '../../assets/images/ic-user.svg';
import { getBase64 } from '../../utils';

// Farm Project Import Services
import categories from '../../services/categories';
import liveStock from '../../services/liveStock';
import GroupService from '../../services/Group-Service';
import FarmService from '../../services/Farm-Service';
// Farm Project Import Services
const { Option } = Select;
const { TabPane } = Tabs;
const AnimalRank = getAnimalRank();
const WeightScore = getWeightScore();

class EditLiveStock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imageBase64: icUser,
            loading: false,
            Model: false,
            parents: [],
            defaultActiveTabId: '1',
            activeTabId: null,
            categories: [],
            animal_breed_category: [],
            Groups: [],
            selectedFarmId: null,
            farmsListData: [],
            farmLocations: [],
            farmsPens: [],
            farmsBunks: [],
        };

    }

    componentDidMount() {
        this.GetAllFarms();
    }

    OpenModel = (resp, tabId) => {
        this.GetAnimalCategory();
        this.onFarmChange(resp.farm._id);
        this.onLocationChange(resp.farm._id);
        this.onPenChange(resp.farm._id);
        const { setFieldsValue } = this.props.form;
        this.setState({ Model: true, activeTabId: tabId, selectedFarmId: resp.farm._id});
        setFieldsValue(resp);

        if (resp.picture !== null)
        this.setState({ imageBase64: resp.picture });

        let Weight = {
            initialWeightDate:  this.DateFormat(resp.weight.initialWeightDate),
            currentWeightDate:  this.DateFormat(resp.weight.currentWeightDate),
            conditionScoreDate: this.DateFormat(resp.weight.conditionScoreDate)
        }
        
        let production = {
            milkingDate:  this.DateFormat(resp.production.milkingDate),
        }
        let reproduction = {
            lastKidingOnDate: this.DateFormat(resp.reproduction.lastKidingOnDate),
            currentConceptionDate: this.DateFormat(resp.reproduction.currentConceptionDate),
            kiddingDate: this.DateFormat(resp.reproduction.kiddingDate),
            ageOfJoining: this.DateFormat(resp.reproduction.ageOfJoining),
        }
        let notes = {
            date: this.DateFormat(resp.notes.date)
        }
        let treatment = {
            treatmentDate:  this.DateFormat(resp.treatment.treatmentDate),
            dateSeperated:  this.DateFormat(resp.treatment.dateSeperated),
            returnVetDate:  this.DateFormat(resp.treatment.returnVetDate)
        }
        setFieldsValue({
            _id: resp._id,
            dob: this.DateFormat(resp.dob),
            dateOfWeaning: this.DateFormat(resp.dateOfWeaning),
            weight: Weight,
            production: production,
            reproduction: reproduction,
            notes: notes,
            treatment: treatment,
            farm: resp.farm['_id'],
            location_id: resp.location_id,
            pen_id: resp.pen_id,
        });
    }

    ClosedModel = () => {
        this.setState({
            loading: false,
            Model: false,
        });
    }

    DateFormat(date){
     if(typeof date !== undefined && date !== "" ){
         return moment(date);
     }
    }

    GetAllGroups = async () => {
        let groupData = await GroupService.GetAllGroups();
        this.setState({Groups: groupData.data});
    }

    GetAllFarms = async () => {
        await FarmService.GetAllFarms()
        .then(resp => {
            this.setState({farmsListData: resp.data});
        });
    }


    onFarmChange = (Id) => {
        this.setState({loading: true});
        const Locations = this.state.farmsListData.filter(data => data._id === Id)[0]['locations'];
        this.setState({farmLocations: Locations, loading: false});
    }

    onLocationChange = (Id) => {
        this.setState({loading: true});
        const Pens = this.state.farmsListData.filter(data => data._id === Id)[0]['pens'];
        this.setState({farmsPens: Pens, loading: false});
    }


    onPenChange = (Id) => {
        this.setState({loading: true});
        const Bunks = this.state.farmsListData.filter(data => data._id === Id)[0]['bunks'];
        this.setState({farmsBunks: Bunks, loading: false});
    }


    GetAnimalCategory = async () => {
        await categories.animal_category()
        .then(resp => this.setState({categories: resp}));
    }

    onCategoryChange = (Id) => {
        const {setFieldsValue} = this.props.form;
        this.setState({loading: true});
        categories.GetAnimalBreedCategory({id: Id})
        .then(resp => {
            this.setState({animal_breed_category: resp});
            setFieldsValue({breedName: null});
            this.setState({loading: false});
        });
    }

    userFileChange = (input) => {
        if (input.files && input.files[0]) {
            const { setFieldsValue } = this.props.form;
            setFieldsValue({ picture: input.files[0] });
            getBase64(input.files[0]).then(imageBase64 => {
                this.setState({ imageBase64 });
            }).catch(() => { });
        }
    }

    onSubmit = (e) => {
        if (e) e.preventDefault();

        const { validateFields, getFieldValue } = this.props.form;     
        
        validateFields((err, values) => {
            if (err) return true;
            this.setState({ loading: false });
            let Id = getFieldValue('_id');

        
            const formData = new FormData();
            formData.append("_id", values._id);
            formData.append("farm", values.farm);
            formData.append("location_id", values.location_id);
            formData.append("pen_id", values.pen_id);
            formData.append("bunk_id", values.bunk_id);
            formData.append("categoryName", values.categoryName);
            formData.append("breedName", values.breedName);
            formData.append("dob", values.dob);
            formData.append("sex", values.sex);
            formData.append("dateOfWeaning", values.dateOfWeaning);
            formData.append("currentLocation", values.currentLocation);
            formData.append("origin", values.origin);
            formData.append("status", values.status);
            formData.append("color", values.color);
            formData.append("breedingStock", values.breedingStock);
            formData.append("lenght", values.lenght);
            formData.append("temprament", values.temprament);
            formData.append("bloodLine", values.bloodLine);
            formData.append("fertilityStatus", values.fertilityStatus);
            formData.append("weight", JSON.stringify(values.weight));
            formData.append("identification", JSON.stringify(values.identification));
            formData.append("production", JSON.stringify(values.production));
            formData.append("reproduction", JSON.stringify(values.reproduction));
            formData.append("notes", JSON.stringify(values.notes));
            formData.append("treatment", JSON.stringify(values.treatment));
            formData.append("picture", values.picture);

            liveStock.UpdateLiveStockById(formData,Id)
                .then(resp => {
                    notification.success({
                        message: 'Live Stock',
                        description: resp.message
                    });

                this.setState({ loading: false, Model: false });    
                if (this.props.onSuccess) this.props.onSuccess();

                })
                .catch(error => {
                    notification.error({
                        message: 'Live Stock',
                        description: 'Request fail please contact your admin'
                    });
                })
                .finally(() => {
                    this.setState({ loading: false });
                });

        });
    }

    onChangeTabs = (key) => {
        this.setState({activeTabId: key});
    }

    ProductionFormField = () => {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const SEX = getFieldValue('sex');
        if(SEX === 'Female') {
            return (
            <>
            <Col span={8}>
                <Form.Item label='Milk Solids'>
                    {getFieldDecorator('production.milkSolids', {
                        rules: []
                    })(
                    <Input />
                    )}
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item label='Total Milk'>
                    {getFieldDecorator('production.totalMilk', {
                        rules: []
                        })(<Input />)}
                </Form.Item>
            </Col>                
            <Col span={8}>
                <Form.Item label='Milking Date'>
                    {getFieldDecorator('production.milkingDate', {
                        rules: []
                    })(
                    <DatePicker className='width-100' />
                    )}
                </Form.Item>
            </Col> 
                <Col span={8}>
                    <Form.Item label='Milking PM'>
                        {getFieldDecorator('production.milkPM', {
                        rules: []
                       })(
                        <Input />
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label='Milking AM'>
                        {getFieldDecorator('production.milkAM', {
                        rules: []
                        })(
                        <Input />
                        )}
                    </Form.Item>
                </Col>
            </>
            )
        }
    }

    ReproductionFormFields = () => {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const SEX = getFieldValue('sex');
        if(SEX === 'Male') {
            return (
                <>
                <Col span={8}>
                    <Form.Item label='Bred to'>
                        {getFieldDecorator('reproduction.breto', {
                            rules: []
                        })(
                            <Input />
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label='Mating ability'>
                        {getFieldDecorator('reproduction.matingAbility', {
                            rules: []
                        })(
                            <Input />
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label='Fertility'>
                        {getFieldDecorator('reproduction.fertility', {
                            rules: []
                        })(
                            <Input />
                        )}
                    </Form.Item>
                </Col>
                </>
                );
        }
        else {
            return (<>
            <Col span={8}>
                <Form.Item label='Pregnancy Number'>
                    {getFieldDecorator('reproduction.pregnancyNumber', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            {AnimalRank.map((data, key) => {
                                                return (<Option key={key} value={data.name}>{data.name}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Last Kiding Date'>
                                    {getFieldDecorator('reproduction.lastKidingOnDate', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100'/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Current Conception Date'>
                                    {getFieldDecorator('reproduction.currentConceptionDate', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100'/>
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Kidding Date'>
                                    {getFieldDecorator('reproduction.kiddingDate', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100' />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Age of Joining'>
                                    {getFieldDecorator('reproduction.ageOfJoining', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100' />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Production record'>
                                    {getFieldDecorator('reproduction.productionRecord', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Total kids Scanned'>
                                    {getFieldDecorator('reproduction.totalKidsScanned', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Scan dry'>
                                    {getFieldDecorator('reproduction.scanDry', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="Yes">Yes</Option>
                                            <Option key="2" value="No">No</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Last still birth/abortion'>
                                    {getFieldDecorator('reproduction.lastStillBirth', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Number of kids Born Alive'>
                                    {getFieldDecorator('reproduction.numberOfKidsBornAlive', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="Singles">Singles</Option>
                                            <Option key="2" value="Twins">Twins</Option>
                                            <Option key="3" value="Triplets">Triplets</Option>
                                            <Option key="4" value="Quad">Quad</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Number of Kids weaned'>
                                    {getFieldDecorator('reproduction.numberOfKidsWeaned', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Fostering kid(s)'>
                                    {getFieldDecorator('reproduction.fosteringKid', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Maternal Ability'>
                                    {getFieldDecorator('reproduction.maternalAbility', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Breeding'>
                                    {getFieldDecorator('reproduction.breeding', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="Servicing Naturally">Servicing Naturally</Option>
                                            <Option key="2" value="Assisted Breeding">Assisted Breeding</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
            </>);
        }

    }

    render() {

        const { loading, Model, activeTabId, imageBase64 } = this.state;
        
        const { getFieldDecorator } = this.props.form;
        const { viewingAs } = this.props;
        
        getFieldDecorator('_id', { initialValue: null });
        getFieldDecorator('picture', { initialValue: null });

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '900px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>Edit Live Stock</span>
                            </div>
                        </Col>
                    </Row>
                    <Tabs activeKey={activeTabId} type="card"  onChange={ (key) => this.onChangeTabs(key)}>
                        <TabPane tab="Life Data" key="1">
                        <Row>
                        <Col span={24}>
                                <div style={{ position: 'relative', width: '122px' }}>
                                    <input
                                        type='file'
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        ref={c => this.userFile = c}
                                        onChange={() => this.userFileChange(this.userFile)}
                                    />
                                    <Avatar size={122} src={imageBase64} icon='user' />
                                    {!viewingAs && <Button size='default' shape='circle' icon='camera' className='btnAvatar' onClick={() => this.userFile.click()} />}
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label='Farm'>
                                    {getFieldDecorator('farm', {
                                    })(
                                        <Select showSearch className='width-100' onChange={this.onFarmChange}>
                                            {this.state.farmsListData.map((opt, key) => {
                                                return (<Option key={key} value={opt._id}>{opt.name}</Option>)
                                            })}
                                        </Select>
                                    )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='Location'>
                                    {getFieldDecorator('location_id', {
                                        rules: [{ required: true, message: 'Please select Location' }]
                                    })(
                                        <Select disabled={this.state.farmLocations.length < 1} onChange={this.onLocationChange} showSearch className='width-100'>
                                            {this.state.farmLocations.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.locationName}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='Pen'>
                                    {getFieldDecorator('pen_id', {
                                        rules: [{ required: true, message: 'Please select Pen' }]
                                    })(
                                        <Select disabled={this.state.farmsPens.length < 1} showSearch className='width-100'>
                                            {this.state.farmsPens.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.penName}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='Bunk'>
                                    {getFieldDecorator('bunk_id', {
                                        rules: [{ required: false, message: 'Please select Bbunk' }]
                                    })(
                                        <Select disabled={this.state.farmsBunks.length < 1} showSearch className='width-100'>
                                            {this.state.farmsBunks.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.bunkName}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item label='Group'>
                                    {getFieldDecorator('bunk_id', {
                                        rules: [{ required: false, message: 'Please select Group' }]
                                    })(
                                        <Select disabled={this.state.farmsPens.length < 1} showSearch className='width-100'>
                                            {this.state.farmsPens.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.penName}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                        </Col>
                            <Col span={8}>
                                <Form.Item label='Category'>
                                    {getFieldDecorator('categoryName', {
                                    })(
                                        <Select disabled={true} showSearch className='width-100' onChange={this.onCategoryChange}>
                                            {this.state.categories.map((opt, key) => {
                                                return (<Option key={key + ""} value={opt._id}>{opt.animalCategoryName}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Breed'>
                                    {getFieldDecorator('breedName', {
                                        rules: [
                                            { required: true, message: 'Please select breed' }
                                        ]
                                    })(
                                        <Select disabled={true} showSearch className='width-100'>
                                        {this.state.animal_breed_category.map((opt, key) => {
                                            return (<Option key={key + ""} value={opt.animalBreedName}>{opt.animalBreedName}</Option>)
                                        })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Date Of Birth'>
                                    {getFieldDecorator('dob', {
                                        rules: [],
                                    })(
                                        <DatePicker disabled={true}  className="width-100"/>
                                    )}
                                </Form.Item>    
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Sex'>
                                    {getFieldDecorator('sex', {
                                        rules: []
                                    })(
                                        <Select disabled={true} showSearch className='width-100'>
                                            <Option key="1" value="Male">Male</Option>
                                            <Option key="2" value="Female">Female</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Date of weaning'>
                                    {getFieldDecorator('dateOfWeaning', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100'/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Current Location'>
                                    {getFieldDecorator('currentLocation', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Origin'>
                                    {getFieldDecorator('origin', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="Purchased">Purchased</Option>
                                            <Option key="2" value="On Farm Birth">On Farm Birth</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Status'>
                                    {getFieldDecorator('status', {
                                        rules: []
                                    })( 
                                    <Select showSearch className='width-100'>
                                        <Option key="1" value="Active">Active</Option>
                                        <Option key="2" value="Sold">Sold</Option>
                                        <Option key="3" value="Lost">Lost</Option>
                                        <Option key="4" value="Deceased">Deceased</Option>
                                        <Option key="5" value="Archive">Archive</Option>
                                    </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Color'>
                                    {getFieldDecorator('color', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Breeding Stock'>
                                    {getFieldDecorator('breedingStock', {
                                        rules: []
                                    })(
                                    <Select showSearch className='width-100'>
                                        <Option key="1" value="Yes">Yes</Option>
                                        <Option key="2" value="No">No</Option>
                                    </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Length'>
                                    {getFieldDecorator('lenght', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Height'>
                                    {getFieldDecorator('height', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Temprament'>
                                    {getFieldDecorator('temprament', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Bloodline/breeder'>
                                    {getFieldDecorator('bloodLine', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Fertility Status'>
                                    {getFieldDecorator('fertilityStatus', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="Casterated">Casterated</Option>
                                            <Option key="2" value="Fertile">Fertile</Option>
                                            <Option key="3" value="Infertile">Infertile</Option>
                                            <Option key="4" value="Cryptorchid">Cryptorchid</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        </TabPane>
                        <TabPane tab="Weight" key="2">
                        <Row gutter={24}>
                        <Col span={8}>
                                <Form.Item label='Initial Weightkg'>
                                    {getFieldDecorator('weight.initialWeightkg', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Initial Weight Date'>
                                    {getFieldDecorator('weight.initialWeightDate', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100'/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Current Weight Kg'>
                                    {getFieldDecorator('weight.currentWeightkg', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Current Weight Date'>
                                    {getFieldDecorator('weight.currentWeightDate', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100'/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Condition Score'>
                                    {getFieldDecorator('weight.conditionScore', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Condition Score Date'>
                                    {getFieldDecorator('weight.conditionScoreDate', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100'/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Body Score'>
                                    {getFieldDecorator('weight.bodyScore', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            {WeightScore.map((data, key) => {
                                                return (<Option key={key} value={data}>{data}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            </Row>               
                        </TabPane>
                        <TabPane tab="Identification" key="3">
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label='Reference ID'>
                                    {getFieldDecorator('identification.rfID', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Sire ID'>
                                    {getFieldDecorator('identification.sireID', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Dam ID'>
                                    {getFieldDecorator('identification.damID', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Name'>
                                    {getFieldDecorator('identification.name', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='E ID'>
                                    {getFieldDecorator('identification.eID', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Old ID'>
                                    {getFieldDecorator('identification.oldID', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Visual ID'>
                                    {getFieldDecorator('identification.visualID', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>                
                        </TabPane>
                        <TabPane tab="Production" key="4">
                            <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label='lactation Number'>
                                    {getFieldDecorator('production.lactationNumber', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            {AnimalRank.map((data, key) => {
                                                return (<Option key={key} value={data.name}>{data.name}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>    
                            <Col span={8}>
                                <Form.Item label='Current Lactation Stage'>
                                    {getFieldDecorator('production.currentLactationStage', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="Early Lactation">Early Lactation</Option>
                                            <Option key="2" value="Mid Lactation">Mid Lactation</Option>
                                            <Option key="3" value="Late Lactation">Late Lactation</Option>
                                            <Option key="4" value="Dry">Dry</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Animal Lactation cycle'>
                                    {getFieldDecorator('production.lactationCycle', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="Milking pregnant">Milking pregnant</Option>
                                            <Option key="2" value="Milking non-pregnant">Milking non-pregnant</Option>
                                            <Option key="3" value="Dry pregnant">Dry pregnant</Option>
                                            <Option key="4" value="Dry non Pregnant">Dry non Pregnant</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                             
                            <Col span={8}>
                                <Form.Item label='FAT %'>
                                    {getFieldDecorator('production.fat', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Protien %'>
                                    {getFieldDecorator('production.protien', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Carcass weight'>
                                    {getFieldDecorator('production.carcassWeight', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Udder/teat Score'>
                                    {getFieldDecorator('production.teatScore', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col> 
                            {this.ProductionFormField()}
                            </Row>
                        </TabPane>
                        <TabPane tab="Reproduction" key="5">
                        <Row gutter={24}>
                            {this.ReproductionFormFields()}              
                        </Row>
                        </TabPane>
                        <TabPane tab="Notes" key="6">
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label='Date'>
                                    {getFieldDecorator('notes.date', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100'/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Details'>
                                    {getFieldDecorator('notes.details', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col> 
                            </Row>          
                        </TabPane>
                        <TabPane tab="Treatment" key="7">
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label='Treatment Date'>
                                    {getFieldDecorator('treatment.treatmentDate', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100'/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Treatment Status'>
                                    {getFieldDecorator('treatment.treatmentStatus', {
                                        rules: []
                                    })(
                                        <Select showSearch className='width-100'>
                                            <Option key="1" value="In Progress">In Progress</Option>
                                            <Option key="2" value="Completed">Completed</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Condition'>
                                    {getFieldDecorator('treatment.condition', {
                                        rules: []
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Affecting Area'>
                                    {getFieldDecorator('treatment.affectingArea', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Vaccine Treatment'>
                                    {getFieldDecorator('treatment.VaccineTreatment', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Does Given'>
                                    {getFieldDecorator('treatment.doesgiven', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Treatment Days'>
                                    {getFieldDecorator('treatment.treatmentDays', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col> 
                            <Col span={8}>
                                <Form.Item label='Milk With Holding'>
                                    {getFieldDecorator('treatment.milkWithholding', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Meat With Holding'>
                                    {getFieldDecorator('treatment.meatWithHolding', {
                                        rules: []
                                    })(
                                        <Input disabled={viewingAs} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Date Seperated'>
                                    {getFieldDecorator('treatment.dateSeperated', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100' />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='Return Vet Date'>
                                    {getFieldDecorator('treatment.returnVetDate', {
                                        rules: []
                                    })(
                                        <DatePicker className='width-100' />
                                    )}
                                </Form.Item>
                            </Col> 
                            </Row>
                        </TabPane>
                    </Tabs>
                    <Row>
                        <Col span={24} className='textRight mt-20'>
                            <Button type="primary" onClick={this.onSubmit}>Save</Button> 
                        </Col>
                    </Row>
                </Spin>
            </Modal >
        );
    }

}

const _EditLiveStock = Form.create()(EditLiveStock);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_EditLiveStock);
