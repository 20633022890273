import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Form, Icon, Input, Button, notification, Row, Col } from 'antd';
import './index.css';

import { attemptLogin } from '../../services/auth';
import * as actions from '../../actions/auth';
import logo from '../../assets/images/logo.png';

class Auth extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fetching: false,
		};
	}

	componentDidMount() {

	}

	handleSubmit = (e) => {

		const { authSignin } = this.props;
		
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {

				this.setState({ fetching: true });

				attemptLogin(values)
					.then(response => {
						if (response.data.status === false) {
							notification.warning({
								message: 'Sign In',
								description: response.data.message
							});
						}

						if(response.data.status === true) {
							authSignin(
								response.data.jwtToken,
								response.data,
								true
							);
						}

						

					})
					.catch(error => {

						notification.error({
							message: 'Service Error',
							description: 'Service Unavailable - please try later'
						});


					})
					.finally(() => {
						this.setState({ fetching: false });
					});

			}
		});

	}

	render() {

		const { fetching } = this.state;
		const { history } = this.props;

		const { getFieldDecorator } = this.props.form;

	

		const { auth } = this.props;
		if (auth.authenticated)
			return (<Redirect to='app/dashboard' />);

		return (
			<div className="loginContainer">
				<div className="y">

					<Form onSubmit={this.handleSubmit} className="login-form z">

						<div className='text-center'>
							<img src={logo} alt="Farm logo" height={150}/>
						</div>

						<div className="intro text-center">
							<h1>Log In to Farm</h1>
						</div>

						<Form.Item>
							{getFieldDecorator('email', {
								rules: [{ required: true, message: 'Please input your email address!' }],
							})(
								<Input
									prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
									placeholder="Email Address"
								/>,
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('password', {
								rules: [{ required: true, message: 'Please input your Password!' }],
							})(
								<Input.Password
									prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
									type="password"
									placeholder="Password"
								/>,
							)}
						</Form.Item>
						<Form.Item style={{ marginBottom: 0 }}>
							<Button loading={fetching} type="primary" htmlType="submit" className="login-form-button">
								LOG IN
          					</Button>
						</Form.Item>
						<Row gutter={24}>
							<Col span={12}>
								<a onClick={() => { history.push(`/password/reset`)}} className='link'>Forgot password?</a>
							</Col>
							<Col span={12}>
							<a onClick={() => { history.push(`/signup`)}} className='link text-right'>Create a new account</a>
							</Col>
						</Row>
					</Form>

				</div>
				<div style={{
					position: "absolute",
					left: "0",
					right: "0",
					zIndex: "1",
					textAlign: "right",
					padding: "0px 24px",
					fontWeight: "bold",
					color: "#e6f7ff",
				}}>
				</div>
			</div>
		);

	}

}

const _Auth = Form.create()(Auth);

export default connect(
	(state) => {
		return {
			auth: state.auth
		}
	},
	actions
)(_Auth);
