import React, { Component } from 'react';
import { Modal, Row, Col, Button, Form, Select, Input, notification, Spin, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { getCountries } from '../../utils';
import moment from 'moment-timezone';

// Farm Project Import Services
import FarmService from '../../services/Farm-Service';
// Farm Project Import Services

const { Option } = Select;
const COUNTRIES = getCountries();


class FarmModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Model: false,
            parents: [],
            tabType: null,
            farmsListData: [],
            selectedFarmId: null,
            farmLocations: [],
            farmPens: [],
            modalTitle: null,
            animal_breed_category: [],
            formData: null,
            formType: null,
            FarmDetail: null,
        };
    }

    componentDidMount() {
       
    }

    DateFormat(date){
        if(typeof date !== undefined && date !== "" ){
            return moment(date);
        }
       }
    

    OpenModel = (type, data, formType) => {
        this.GetAllFarms();
        switch (type) {
            case 'farms':
                this.setState({modalTitle: formType + ' Farm'})
                break;
            case 'location':
                this.setState({modalTitle: formType + ' Location'})
                break;
            case 'pen':
                this.setState({modalTitle: formType + ' Pen/Lot'})
                break; 
            case 'bunks':
                this.setState({modalTitle: formType + ' Bunks/Feeding Pen'})
                break;
            default:
                break;
        }
        this.setState({ Model: true, tabType: type, formData: data, formType: formType});
        this.setFieldValues(type, data, formType);
    }

    setFieldValues(type, formData, formType) {
        const { getFieldDecorator } = this.props.form;
        if(formData !== null && formType === 'Edit' && type === 'farms') {
            getFieldDecorator('name', {initialValue: formData.name});
            getFieldDecorator('country', {initialValue: formData.country});
            getFieldDecorator('town', {initialValue: formData.town});
            getFieldDecorator('address', {initialValue: formData.address});
            getFieldDecorator('postalCode', {initialValue: formData.postalCode});
            getFieldDecorator('dateOpened', {initialValue: this.DateFormat(formData.dateOpened)});
            getFieldDecorator('phone', {initialValue: formData.phone});
        }
        else if(formData !== null && formType === 'Edit' && type === 'location') {
            getFieldDecorator('farm_id', {initialValue: formData.farm_id});
            getFieldDecorator('locationName', {initialValue: formData.locationName});
            getFieldDecorator('startDate', {initialValue: this.DateFormat(formData.startDate)});
        }
        else if (formData !== null && formType === 'Edit' && type === 'pen') {
            this.onFarmChange(formData.farm_id);
            getFieldDecorator('farm_id', {initialValue: formData.farm_id});
            getFieldDecorator('location_id', {initialValue: formData.location_id});
            getFieldDecorator('penName', {initialValue: formData.penName});
            getFieldDecorator('penType', {initialValue: formData.penType});
            getFieldDecorator('startDate', {initialValue: this.DateFormat(formData.startDate)});
            getFieldDecorator('endDate', {initialValue: this.DateFormat(formData.endDate)});
        }
        else if (formData !== null && formType === 'Edit' && type === 'bunks') {
            this.onFarmChange(formData.farm_id);
            getFieldDecorator('farm_id', {initialValue: formData.farm_id});
            getFieldDecorator('location_id', {initialValue: formData.location_id});
            getFieldDecorator('pen_id', {initialValue: formData.pen_id});
            getFieldDecorator('bunkName', {initialValue: formData.bunkName});
            getFieldDecorator('bunkType', {initialValue: formData.bunkType});
        }
        else {
            this.props.form.resetFields();
        }

    }

    onFarmChange = (Id) => {
        const Locations = this.state.farmsListData.filter(data => data._id === Id)[0]['locations'];
        const Pens = this.state.farmsListData.filter(data => data._id === Id)[0]['pens'];
        this.setState({farmLocations: Locations, selectedFarmId: Id, farmPens: Pens});
    }

    ModelFormFields = () => {
        const tab = this.state.tabType;
        const { getFieldDecorator } = this.props.form;
        if(tab === 'farms') {
            return (
                <>
                <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label='Farm Name'>
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Farm Name is required' }]
                            })(
                                <Input />
                            )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
            <Col span={12}>
                    <Form.Item label='Country'>
                        {getFieldDecorator('country', {
                                rules: [{ required: true, message: 'Please select country' }]
                            })(
                                <Select showSearch className='width-100'>
                                {COUNTRIES.map((country, key) => {
                                    return (<Option key={key} value={country}>{country}</Option>)
                                })}
                                </Select>
                            )}
                    </Form.Item>   
                </Col>
                <Col span={12}>
                    <Form.Item label='Town City'>
                        {getFieldDecorator('town', {
                                rules: [{ required: true, message: 'Town City is required' }]
                            })(
                            <Input />
                            )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
            <Col span={12}>
                        <Form.Item label='Address'>
                            {getFieldDecorator('address', {
                                rules: [{ required: true, message: 'Address is required' }]
                            })(
                                <Input />
                            )}
                        </Form.Item>    
                </Col>
                <Col span={12}>
                    <Form.Item label='Postal Code'>
                        {getFieldDecorator('postalCode', {
                                rules: [{ required: true, message: 'Postal Code is required' }]
                            })(
                                <Input />
                            )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
            <Col span={12}>
                        <Form.Item label='Date opened'>
                            {getFieldDecorator('dateOpened', {
                                rules: [{ required: true, message: 'Please select Date opened' }]
                            })(
                                <DatePicker className="width-100"/>
                            )}
                        </Form.Item>    
                </Col>
                <Col span={12}>
                    <Form.Item label='Telephone'>
                        {getFieldDecorator('phone', {
                                rules: [{ required: true, message: 'Telephone is required' }]
                            })(
                                <Input />
                            )}
                    </Form.Item>
                </Col>
            </Row>
            </>
            );
        }
        else if(tab === 'location') {
            return (
            <>
            <Row gutter={24}>
                <Col span={12}>
                        <Form.Item label='Farm'>
                            {getFieldDecorator('farm_id', {
                                rules: [{ required: true, message: 'Please select Farm' }]
                            })(
                                <Select showSearch className='width-100'>
                                    {this.state.farmsListData.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.name}</Option>)
                                    })}
                                </Select>
                            )}
                        </Form.Item>    
                </Col>
                <Col span={12}>
                    <Form.Item label='Location'>
                        {getFieldDecorator('locationName', {
                                rules: [{ required: true, message: 'Location is required' }]
                            })(
                                <Input />
                            )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                        <Form.Item label='Start Date'>
                            {getFieldDecorator('startDate', {
                                rules: [{ required: true, message: 'Start Date is required' }]
                            })(
                                <DatePicker className="width-100"/>
                            )}
                        </Form.Item>    
                </Col>
            </Row>
            </>
            );
        }
        else if(tab === 'pen') {
            return (
            <>
            <Row gutter={24}>
                <Col span={12}>
                        <Form.Item label='Farm'>
                            {getFieldDecorator('farm_id', {
                                rules: [{ required: true, message: 'Please select Farm' }]
                            })(
                                <Select showSearch className='width-100' onChange={this.onFarmChange}>
                                    {this.state.farmsListData.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.name}</Option>)
                                    })}
                                </Select>
                            )}
                        </Form.Item>    
                </Col>
                <Col span={12}>
                    <Form.Item label='Location'>
                        {getFieldDecorator('location_id', {
                                rules: [{ required: true, message: 'Location is required' }]
                            })(
                                <Select showSearch className='width-100'>
                                    {this.state.farmLocations.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.locationName}</Option>)
                                    })}
                                </Select>
                            )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label='Pen Name'>
                        {getFieldDecorator('penName', {
                                rules: [{ required: true, message: 'Pen Name is required' }]
                        })(
                            <Input />
                        )}
                    </Form.Item>    
                </Col>
                <Col span={12}>
                    <Form.Item label='Pen Type'>
                        {getFieldDecorator('penType', {
                                rules: [{ required: true, message: 'Pen Type is required' }]
                            })(
                                <Select showSearch className='width-100'>
                                    <Option key={1} value={'Home'}>Home</Option>
                                    <Option key={2} value={'Hospital Pen'}>Hospital Pen</Option>
                                    <Option key={3} value={'Cull Pen'}>Cull Pen</Option>
                                    <Option key={3} value={'Holding Pen'}>Holding Pen</Option>
                                    <Option key={3} value={'Special Pen'}>Special Pen</Option>
                                </Select>
                            )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label='Start Date'>
                        {getFieldDecorator('startDate', {
                                rules: [{ required: false, message: 'Start Date is required' }]
                            })(
                                <DatePicker className="width-100"/>
                            )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label='End Date'>
                        {getFieldDecorator('endDate', {
                                rules: [{ required: false, message: 'End Date is required' }]
                            })(
                                <DatePicker className="width-100"/>
                            )}
                    </Form.Item>
                </Col>
            </Row>
            </>
            );
        }

        else if(tab === 'bunks') {
            return (
            <>
            <Row gutter={24}>
                <Col span={12}>
                        <Form.Item label='Farm'>
                            {getFieldDecorator('farm_id', {
                                rules: [{ required: true, message: 'Please select Farm' }]
                            })(
                                <Select showSearch className='width-100' onChange={this.onFarmChange}>
                                    {this.state.farmsListData.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.name}</Option>)
                                    })}
                                </Select>
                            )}
                        </Form.Item>    
                </Col>
                <Col span={12}>
                    <Form.Item label='Location'>
                        {getFieldDecorator('location_id', {
                                rules: [{ required: true, message: 'Location is required' }]
                            })(
                                <Select className='width-100'>
                                    {this.state.farmLocations.map((data, key) => {
                                            return (<Option key={key} value={data._id}>{data.locationName}</Option>)
                                    })}
                                </Select>
                            )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label='Pen/Lot'>
                        {getFieldDecorator('pen_id', {
                                rules: [{ required: true, message: 'Please select Pen' }]
                        })(
                            <Select showSearch className='width-100'>
                                {this.state.farmPens.map((data, key) => {
                                    return (<Option key={key} value={data._id}>{data.penName}</Option>)
                                })}
                            </Select>
                        )}
                    </Form.Item>    
                </Col>
                <Col span={12}>
                        <Form.Item label='Bunk Name'>
                            {getFieldDecorator('bunkName', {
                                rules: [{ required: true, message: 'Please select Farm' }]
                            })(
                                <Input />
                            )}
                        </Form.Item>    
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label='Bunk Type'>
                        {getFieldDecorator('bunkType', {
                                rules: [{ required: true, message: 'Bunk Type is required' }]
                            })(
                                <Select showSearch className='width-100'>
                                    <Option key={1} value={'Feed'}>Feed</Option>
                                    <Option key={2} value={'Water'}>Water</Option>
                                </Select>
                            )}
                    </Form.Item>
                </Col>
            </Row>
            </>
            );
        }
    }

    ClosedModel = () => {
        this.setState({ Model: false });
    }

    GetAllFarms(){
        FarmService.GetAllFarms()
        .then(resp => this.setState({farmsListData: resp.data}));
    }

    GetFarmById(Id){
        FarmService.GetFarmById(Id)
        .then(resp => this.setState({FarmDetail: resp}));
    }

    onReset = () => {
        this.props.form.resetFields();
    }

    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { validateFields } = this.props.form;
        const { formType, tabType, formData } = this.state;

        validateFields((err, values) => {
            if (err) return;

            if(tabType !== 'farms') {
                this.ManageFormResponse(formType, tabType, formData, values);
            }
            else if(formType === 'Edit') { 
                this.UpdateFarm(values, formData._id)
            }
            else {
                this.AddNewFarm(values);
            }

        });
    }

    ManageFormResponse(formType, tabType, formData, values){ 
        const Data = this.state.farmsListData.filter( data => data._id === values.farm_id)[0];
        if(tabType === 'location') { // Location Reponse
            if(formType === 'Add'){ // Add New Location          
                Data.locations.push(values);
                this.UpdateFarm(Data, Data._id);
            }
            else { // Edit Location
                let Index = Data.locations.findIndex(data => data._id === formData.id);
                Data.locations[Index]['locationName'] = values.locationName;
                Data.locations[Index]['startDate'] = values.startDate;
                this.UpdateFarm(Data, Data._id);
            }
            
        }

        if(tabType === 'pen') {
            if(formType === 'Add'){ // Add New Location          
                Data.pens.push({
                    location_id: values.location_id,
                    penName: values.penName,
                    penType: values.penType,
                    startDate: values.startDate,
                    endDate: values.endDate,
                });
                this.UpdateFarm(Data, Data._id);
            }
            else { // Edit Pen
                let Index = Data.pens.findIndex(data => data._id === formData.id);
                Data.pens[Index]['location_id'] = values.location_id;
                Data.pens[Index]['penName'] = values.penName;
                Data.pens[Index]['penType'] = values.penType;
                Data.pens[Index]['startDate'] = values.startDate;
                Data.pens[Index]['endDate'] = values.endDate;
                this.UpdateFarm(Data, Data._id);
            }   
        }

        if(tabType === 'bunks') {
            if(formType === 'Add') { // Add New Bunks
                Data.bunks.push({
                    pen_id: values.pen_id,
                    location_id: values.location_id,
                    bunkName: values.bunkName,
                    bunkType: values.bunkType,
                });
               this.UpdateFarm(Data, Data._id);
            }
            else { // Edit Bunks
                let Index = Data.bunks.findIndex(data => data._id === formData.id);
                Data.bunks[Index]['pen_id'] = values.pen_id;
                Data.bunks[Index]['location_id'] = values.location_id;
                Data.bunks[Index]['bunkName'] = values.bunkName;
                Data.bunks[Index]['bunkType'] = values.bunkType;
                this.UpdateFarm(Data, Data._id);
            }
        }
    }


    ResponseSuccessfull(message){
		notification.success({ message: 'Farm', description: message});
        this.setState({ loading: false }, this.ClosedModel);
        this.props.form.resetFields();
        if (this.props.onSuccess) this.props.onSuccess();
	}

	ResponseFail(message){
		notification.error({ message: 'Farm', description: message});
        this.setState({ loading: false });
	}

    AddNewFarm(values){
        FarmService.AddFarm(values).then(resp => {
            this.ResponseSuccessfull(resp.message);
        }).catch((error) => {
            this.ResponseFail('Service Unavailable');
        });
    }

    UpdateFarm(values, Id){
        this.setState({loading: true});
        FarmService.UpdateFarm(values, Id).then(resp => {
            this.ResponseSuccessfull(resp.message);
        }).catch((error) => {
            this.ResponseFail('Service Unavailable');
        });
    }

    render() {

        const { loading, Model } = this.state;

        const PropsCredentials = {
            visible: Model,
            title: null,
            footer: null,
            onCancel: this.ClosedModel,
            width: '600px'
        };

        return (
            <Modal {...PropsCredentials}>
                <Spin spinning={loading}>
                    <Row style={{ marginTop: '25px', marginBottom: '24px' }}>
                        <Col span={24}>
                            <div className='textCenter'>
                                <span className='h1 x2 colorPrimary'>{ this.state.modalTitle }</span>
                            </div>
                        </Col>
                    </Row>

                    {this.ModelFormFields()}

                    <Row>
                        <Col  span={24} className='textRight mt-20'>
                            <Button type='default' onClick={this.onReset} disabled={this.state.formType === 'Edit'}>Reset</Button>
                            &emsp;
                            <Button type="primary" onClick={this.onSubmit}  disabled={loading}>Save</Button> 
                        </Col>
                    </Row>
                </Spin>
            </Modal >
        );
    }

}

const _FarmModal = Form.create()(FarmModal);

export default connect(
    () => {
        return {}
    },
    null,
    null,
    { forwardRef: true }
)(_FarmModal);
