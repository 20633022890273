import api from '../lib/api';

export default {

    GetFarmById: function (Id) {
        return new Promise((resolve, reject) => {
            api()
                .get(`farms/edit/${Id}`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get farms failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },    

    GetAllFarms: function () {
        return new Promise((resolve, reject) => {
            api()
                .get('farms')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get farms failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    GetAllFarmUsers: function () {
        return new Promise((resolve, reject) => {
            api()
                .get('accounts/get-farm-staffs')
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Get farms staff failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    AddFarm: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .post('farms', values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Add new Farm failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    UpdateFarm: (values, Id) => {
        return new Promise((resolve, reject) => {
            api()
                .post(`farms/edit/${Id}`, values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Update Farm failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    DeleteFarm: (Id) => {
        return new Promise((resolve, reject) => {
            api()
                .delete(`farms/${Id}`)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data.data);
                    throw new Error(`Delete request failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

    AnimalMovement: (values) => {
        return new Promise((resolve, reject) => {
            api()
                .put(`farms/animal-movements`, values)
                .then(({ status, data }) => {
                    if (status === 200) resolve(data);
                    throw new Error(`Animal Movement failed with status: ${status}`);
                })
                .catch(error => reject(error));
        });
    },

}