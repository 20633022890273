import React, { Component } from 'react';
import { Layout, Dropdown, Avatar, Icon, Card } from 'antd';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import './header.scss';

import logo from '../../assets/images/logo.png';
import icUser from '../../assets/images/ic-user.svg';

import * as actions from '../../actions/auth';

import { getMenuItems } from './routes';

const { Header } = Layout;

const jwt = require("jsonwebtoken");

class AppHeader extends Component {
    


    render() {

        const { user } = this.props.auth;
        const { menuItems, menuItem, showMobileMenu, showMobileHeader } = this.state;

        const menuHeaderDropdown = (
            <div style={{ width: '223px' }}>
                <Card
                    style={{ boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08)' }}
                    bordered={false}
                    bodyStyle={{ padding: 0 }}
                >
                    <div className="pt-24">
                        <div style={{ width: '68px', margin: '0 auto' }}>
                            <Avatar size={68} shape='circle' src={user.picture ? user.picture : icUser} />
                        </div>
                    </div>
                    <div className="textCenter pv-10">
                        <span className="h3">{user.firstName} {user.lastName}</span>
                    </div>
                    <div className="divider" />
                    <div className="pv-12">
                        <div className="base fw500 textCenter falseHover" onClick={this.edit}>Edit my profile</div>
                        <div className="base fw500 textCenter falseHover pt-10" onClick={this.logout}>Logout</div>
                    </div>
                </Card>
            </div>
        );
        

        const token = localStorage.getItem('FARM_TOKEN');
        const TOKEN = jwt.decode(token);
        if (TOKEN.exp * 1000 < Date.now()) {
            this.props.authSignout();
            localStorage.clear();
            return (<Redirect to='/' />);
        }
        return (
            <div style={{ backgroundColor: '#fff' }}>
                {
                    showMobileHeader ? <Header className="header">
                    <div className="headerLeft">
                        <div className="menu pointer">
                            <i className="material-icons" onClick={() => this.showMobileMenu(true)}>menu</i>
                        </div>
                        <figure className="logo">
                            <img src={logo} alt='Maveshi365' />
                        </figure>
                        <div className="title">
                            <h1>Maveshi365</h1>
                        </div>
                    </div>
                    <div className="headerRight">
                        {/* <span className="action">
                        <Icon type="bell" style={{ fontSize: 19 }} />
                        <span className="badge">3</span>
                    </span> */}
                        <Dropdown overlay={menuHeaderDropdown}>
                            <span className="action">
                                <Avatar className="accountAvatar" size={32} src={user.picture ? user.picture : icUser} />
                            </span>
                        </Dropdown>
                    </div>
                </Header> : ''
                }
                <div className={`mobileMenu ${showMobileMenu ? 'show' : ''}`}>
                    <div className="text-right">
                        <i className="material-icons" style={{ cursor: 'pointer', fontSize: '35px' }} onClick={() => this.showMobileMenu(false)}>close</i>
                    </div>
                    <div className="mobileMenuItems">
                        {
                            menuItems.map((item, key) => {
                                return (
                                    <div className={[
                                        key === menuItem ? 'active' : '',
                                        item.disabled ? 'disabled' : '',
                                    ].join(' ')} key={key + ''} onClick={() => this.onClickMenuItem(key)}>
                                        <i className="material-icons-outlined">{item.icon}</i>
                                        <span>{item.label}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }



    constructor(props) {
        super(props);
        this.state = {
            menuItems: [],
            menuItem: undefined,
            showMobileMenu: false,
            showMobileHeader: false
        }
    }

    componentDidMount() {
        this.buildMenuItems();
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.auth.authenticated) {
            if (prevProps.auth.user.role.name !== this.props.auth.user.role.name) {
                this.buildMenuItems();
            }
        }
    }

    buildMenuItems = () => {
        const { user } = this.props.auth;
        const menuItems = getMenuItems(user.role, user.subscription);
        this.setState({ menuItems }, this.getSelectedKeys);
    }

    getSelectedKeys = () => {
        const { pathname } = this.props.history.location;
        let index = this.state.menuItems.findIndex((item, i) => {
            if (item.path === undefined) return false;
            return pathname.search(item.path) !== -1 ? true : false;
        });
        this.setState({ menuItem: index })
    }

    showMobileMenu = (flag = false) => {
        this.setState({ showMobileMenu: flag });
    }

    onClickMenuItem = (key) => {
        this.setState({ menuItem: key });
        const { match: { path } } = this.props;
        const { path: pathRoute } = this.state.menuItems[key];
        if (pathRoute) {
            this.props.history.push(path + pathRoute);
        }
    }

    updateDimensions() {
        if (window.innerWidth > 575) {
            this.setState({ showMobileMenu: false });
        }
        else if(window.innerWidth < 575) {
            this.setState({ showMobileHeader: true });
        }
    }

    changePassword = () => {
        this.props.history.push('/app/change-password');
    }

    authReturn = () => {
        this.props.authReturn(this.props.auth.root.token);
        this.props.history.push('/app/dashboard');
    }

    logout = () => {
        this.props.authSignout();
    }

    edit = () => {
        this.props.history.push('/app/profile');
    }
}

export default connect(
    (state) => {
        return {
            auth: state.auth
        }
    },
    actions,
)(withRouter(AppHeader));
